////////////////////////////////////////////////////////////
//  Program: PropertyService.js                           //
//  Application: Property                                 //
//  Option: Used for manage Properties                    //
//  Developer: Kiran singh 						                    //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class PropertyService {
  getPropertyList(queryString) {
    let endPoint = "property" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getProperty(slug) {
    let endPoint = "property/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

   createProperty(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateProperty(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "property/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }
  deleteProperty(slug, delete_status) {
    let endPoint = "delete-property/" + slug +"/"+ delete_status;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  getPropertyByCustomer(slug, queryString) {
    let endPoint = "property/customer" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new PropertyService();
