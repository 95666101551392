////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Inspection Number card                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

export class InspectionNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    inspection: this.props.inspection ? this.props.inspection :0,
      
    };

  }

  render() {
    return (
      <>
      <div className="col-md-12 col-12 mg-t-20">
                          <div className="card card-dashboard-ratio">
                            <div className="card-header">
                              <h6 className="card-title tx-14 mg-b-0 dash_head">
                                Inspections{" "}
                                <Link to="/inspections" className="btn_view">
                                  View More
                                </Link>
                              </h6>
                            </div>
                            <div className="card-body justify-content-between">
                              <div className="card-icon">
                                <i className="typcn typcn-chart-line-outline"></i>
                              </div>

                              <h2>
                                {this.state.inspection !=0
                                  ? this.state.inspection
                                  : 0}
                              </h2>
                              {/* <img src={icon3} alt="" className="dash_icons" /> */}
                            </div>
                          </div>
                        </div>
      </>
    );
  }
}
export default InspectionNumber;
