
import ReactDatatable from "@ashvin27/react-datatable";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import MroService from "../../services/MroService";
import PropertyService from "../../services/PropertyService";
import Moment from "moment";
// import customerLogo from "./../../assets/images/default.png";
import back from "./../../assets/images/back.svg";
export class MroTracker extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      year: new Date(),
      currentYear: new Date().getFullYear(),
      property_slug: "",
      selectedPropertyList: [],
      propertySlug: "",
      propertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      records: [],
      trackerTypeText: "",
      trackerType: "",
      propertyLable: "",
      isFilterTrue: false,
      config: {
        sort: {
          column: "customer_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: false,
        show_info: false,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "mro_number",
          className: "pl-2 hand-cursor",
          text: "MRO NUMBER",
          sortable: false,
          cell: (record) => {
            return record.mro_number
              ? record.mro_number
              : "";
          },
        },
        {
          key: "updated_at",
          className: "pl-2 hand-cursor",
          text: "DATE WORK COMPLETED",
          sortable: false,
          cell: (record) => {
            return record.due_date
              ? Moment(record.due_date).format("DD MMM, YY - hh:mm A")
              : "";
          },
        },
        {
          key: "note",
          className: "pl-10 hand-cursor",
          text: "DESCRIPTION OF WORK PERFORMED",
          sortable: false,
          cell: (record) => {
            // return record.note;
            const noteString = record.title ? record.title :"";
            const maxString = 100;
            return noteString && noteString.length > maxString
              ? noteString.substr(0, maxString - 1) + "..."
              : noteString;
          },
        },
        {
          key: "full_name",
          className: "pl-2 hand-cursor",
          text: "PERFORMED BY",
          sortable: false,
          cell: (record) => {
            return record.user && record.user.full_name ? record.user.full_name : "";
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetData = this.resetData.bind(this);
  }

  componentDidMount() {
    this.globalDataSet();
    this.getData();
    this.getPropertyList();
    this.setState({ zoneTypeList: global.zoneTypeList });
  }
  globalDataSet = () => {
    var singleUser = localStorage.getItem("activePropertyCount") !="" && localStorage.getItem("activePropertyCount") != undefined ?  parseInt(localStorage.getItem("activePropertyCount")) :"";
    if (singleUser === 1) {
      var singlePropertyName = localStorage.getItem("singlePropertyName");
      var singlePorpertySlug = localStorage.getItem("singlePorpertySlug");
      const selectedYear = new Date().getFullYear();
      var filterValue =
      '{"year" :"' +
      selectedYear +
      '", "property_slug" :"' +
      singlePorpertySlug +
      '" , "property_name" :"' +
      singlePropertyName +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    }
  }
  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    if (filterData) { 
      queryString = "year=" + filterData.year + "&property_slug=" + filterData.property_slug;
      if (filterData.property_name && filterData.property_slug) { 
        var Year = Moment(filterData.year).toDate("YYYY");
        var selectedPropertyList = {
          label: filterData.property_name,
          value: filterData.property_slug,
        };
        this.setState({
          propertySlug: filterData.property_slug,
          propertyLable: filterData.property_name,
          year: Year,
          selectedPropertyList: selectedPropertyList,
          selectYear: Moment(filterData.year).format("YYYY"),
        });
      }
    }

    let res = await MroService.getMroTracker(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
    });
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocations(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList = res.data ? res.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
      });
    });
    this.setState({ locationList: locationListData });
  }

  // Get Property List
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    this.setState({ propertyList: propertyListData });
  }

  customFilter() {
    const selectedYear = this.state.year.getFullYear();
    let queryString =
      "year=" +
      selectedYear +
      "&property_slug=" +
      this.state.propertySlug +
      "&zone_type=" +
      this.state.zone_type +
      "&location_slug=" +
      this.state.location_slug +
      "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    var filterValue =
      '{"year" :"' +
      selectedYear +
      '", "property_slug" :"' +
      this.state.propertySlug +
      '" , "property_name" :"' +
      this.state.propertyLable +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    this.setState({ globalTrackerState: filterValue });
    this.setState({ isFilterTrue: true });
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({
      locationList: [],
      selecteLocationList: [],
      selectedZoneTypeList: "",
      propertyList: "",
      selectedPropertyList: "",
      trackerTypeText: "",
      trackerType: "",
      records: [],
      property_slug: "",
      zone_type: "",
      location_slug: "",
      selectedPropertyName: "",
      singleScreenProperty: "",
      singleScreen: false,
      propertySlug: "",
      propertyLable: "",
      selectYear: "",
      year:new Date(),
    });
    
    localStorage.removeItem("globleTracker");
    var GlobalApply = localStorage.getItem("outSidePorpertySlug") !="" && localStorage.getItem("outSidePorpertySlug") != undefined ?  parseInt(localStorage.getItem("outSidePorpertySlug")) :"";
    if (GlobalApply != undefined && GlobalApply != "") {
      const selectedYear = new Date().getFullYear();
      var filterValue =
      '{"year" :"' +
      selectedYear +
      '", "property_slug" :"' +
      localStorage.getItem("outSidePorpertySlug") +
      '" , "property_name" :"' +
      localStorage.getItem("selectedPropertyName") +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    }
    if (localStorage.getItem("activePropertyCount") != "" && localStorage.getItem("activePropertyCount") != undefined) {
      this.globalDataSet();
    }
    this.getData("resetCall");
    this.getPropertyList();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // Zone Type Change Handler
  handleChangeZoneType(value) {
    let propertySlug = "";
    let zoneType = "";
    propertySlug = this.state.property_slug
      ? this.state.property_slug
      : this.state.propertySlug;
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: value,
      zone_type: value.value,
      trackerType: "",
    });
    setTimeout(() => {
      zoneType = this.state.zone_type;
      if (zoneType === "1") {
        // Get Room List
        this.setState({ trackerTypeText: "Room No. :" });
        this.getLocation("property_slug=" + propertySlug + "&zone_type=1");
      } else if (zoneType === "2") {
        // Get Commercial Area List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=2");
        this.setState({ trackerTypeText: "Commercial Area :" });
      } else if (zoneType === "3") {
        // Get Equipment Zone List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=3");
        this.setState({ trackerTypeText: "Equipment No. :" });
      }
    }, 100);

    this.setState({ confirm_back: 1 });
  }

  // Location Change Handler
  handleChangeLocation(value) {
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      trackerType: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  // Property Change Handler
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: [],
      locationList : [],
      trackerTypeText: "",
      trackerType: "",
      selectedPropertyList: value,
      property_slug: value.value,
      propertyLable: value.label,
      propertySlug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
      
    });
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          } else if (key === "page_size") {
            newKey = "per_page";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };
  rowClickedHandler = (event, data, rowIndex) => {
    window.location.href = "mros/update/" + data.slug;
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main className="p-0">
          {this.state.showSpinner ? global.spinnerLoader : ""}
            <div className="container-fluid p-0">
              <div className="row row-xs">
                <div className="col-md-12 p-0">
                  <div className="brandSection">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center tracker_btn_new">
                      <h3 className="invisible"> </h3>
                      <div className="btnNavigate d-flex justify-content-end ">
                        <button
                          onClick={this.filterToggle}
                          className="gf-primary-btn-small-one"
                        >
                          {this.state.showFilter === 1 ? " Filter" : " Filter"}
                        </button>
                      </div>
                    </div>
                    {this.state.showFilter ? (
                      <Row className="align-items-start fliter_back ">
                        <div className="order_form">
                          <div className="d-flex justify-content-between align-items-center w-100 ">
                            <h3
                              className="hand-cursor back-size"
                              onClick={this.filterToggle}
                            >
                              <img src={back} alt="" />
                              Back
                            </h3>
                          </div>
                          <h3>Filter</h3>
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0 pb-0"
                            >
                              <label className="form-label text-left mg-b-5 mt-0">
                                Year
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="year"
                                showYearPicker
                                dateFormat="yyyy"
                                selected={this.state.year}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "year",
                                      value: data,
                                    },
                                  })
                                }
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0 pb-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Property
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedPropertyList}
                                options={this.state.propertyList}
                                onChange={(value) =>
                                  this.handleChangeProperty(value)
                                }
                                defaultValue={this.state.selectedPropertyList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0 pb-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Zone
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedZoneTypeList}
                                options={this.state.zoneTypeList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeZoneType(value)
                                }
                                defaultValue={this.state.selectedZoneTypeList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>

                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0 pb-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Location
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selecteLocationList}
                                options={this.state.locationList}
                                onChange={(value) =>
                                  this.handleChangeLocation(value)
                                }
                                defaultValue={this.state.selecteLocationList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>

                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0 pb-4 pr-0"
                            >
                              <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                                <button
                                  onClick={this.resetData}
                                  className="btn-success-outline"
                                >
                                  Reset
                                </button>
                                <button
                                  onClick={this.customFilter}
                                  className="btn-success mg-l-0 mg-r-20"
                                >
                                  {this.state.isFilterTrue
                                    ? global.loader
                                    : "Search"}
                                </button>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid p-0">
              <div className="mgb-neg-20 brandList dashboardRightcard month_width ">
              <div className="row">
              <div className="col-md-12">
              {this.state.selectYear != undefined && this.state.selectYear != ""  ? (<>
                    <div className="checklist_table_heading"><span className="lspan">Year : </span><span className="rspan">{this.state.selectYear ? this.state.selectYear:""}</span></div>
                  </>) :""}
                  {this.state.propertyLable !== undefined && this.state.propertyLable !== ""? (<>
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On : </span><span className="rspan">{this.state.propertyLable ? this.state.propertyLable : ""}</span></div>
                    </>) :""}
                </div>
              </div>
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 customerTable table-bordered table_mro"
                  config={this.state.config}
                  records={this.state.records}
                  columns={this.state.columns}
                  extraButtons={this.state.extraButtons}
                  dynamic={true}
                  loading={this.state.loading}
                  total_record={this.state.total_record}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandler}
                />
              </div>
            </div>
        </main>
      </div>
    );
  }
}

export default MroTracker;
