/*------------------------------------------------------------

Program: PrivacyNotice.jsx
Application: Detailed Note of Privacy Notice 
Option: For Privacy Notice 
Developer: Chandra Shekhar
Date: 21-04-2023

---------------------------------------------------------------*/

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
export class PrivacyNotice extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {};
  }

  async componentDidMount() {

   }

  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  render() {
    return (
      <>
        <div className="privacy_sec">
        <div className="logo_privacy">
        <img
              src={require("../../assets/images/GFBOT-Horizontal.png").default}
              alt=""
            ></img>
        </div>
          <h1>END USER LICENSE AGREEMENT</h1>
          <h4>
            This End User License Agreement (“EULA”) is a binding contract
            between the End User identified below and G.F. Management, LLC
            d/b/a GF Hotels and Resorts (“Licensor”) and governs your
            access to and use of the Software identified below.{" "}
          </h4>
          <p>
            PLEASE READ THIS EULA CAREFULLY. BY CLICKING ON THE “ACCEPT”
            BUTTON OR BY OTHERWISE DOWNLOADING, ACCESSING OR USING THE
            SOFTWARE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND
            AGREE TO BE BOUND TO ALL OF THE TERMS AND CONDITIONS IN THIS
            EULA. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS
            OF THIS EULA, YOU MUST IMMEDIATELY DISCONTINUE YOUR DOWNLOAD
            AND USE OF THE SOFTWARE. YOUR USE OF THE SOFTWARE IS DEEMED AN
            AFFIRMATIVE ASSENT TO THE TERMS AND CONDITIONS IN THIS EULA.
          </p>
          <p>
            LICENSOR MAY MODIFY OR AMEND THIS EULA FROM TIME TO TIME, AND
            YOU WILL BE REQUIRED TO ACCEPT THE NEW EULA UPON YOUR NEXT
            LOGIN IN ORDER TO CONTINUE USING THE SOFTWARE.
          </p>

          <ol>
            <li>
             <p> <b>Definitions</b> The following capitalized terms shall
              have the meanings set forth below:</p>
              <ul>
                <li>
                  <b>"Affiliates"</b> means those legal entities that own or
                  control Licensor, are owned or controlled by Licensor,
                  or are under common control with Licensor.
                </li>
                <li>
                  <b>"Documentation"</b> means the descriptions of the Software
                  and user information and instructions made available to
                  you from time to time by Licensor.
                </li>
                <li>
                  <b>"End User"</b> or <b>"You"</b> or <b>"Your"</b> means collectively, the
                  individual who is downloading and using the Software,
                  and the Licensor Affiliate for whom the End User is
                  acting as an employee, agent or contractor.
                </li>
                <li>
                  <b>"Software"</b> means the GF BOT, a proprietary software
                  application developed specifically for Licensor's use
                  and benefit, which benefits include the right to grant
                  licenses to End Users pursuant to the license granted
                  herein, and all new versions, modifications, error
                  fixes, updates, upgrades, and releases (collectively,
                  "Updates") made available to Licensor and passed through
                  by Licensor to End Users from time to time.
                </li>
              </ul>
            </li>
            <li>
             <p><b> License Grant, Permitted Uses and Restrictions.</b></p>
              <ul>
                <li>
                  <b>Grant of License; Permitted Uses.</b> Subject to the terms
                  and conditions of this EULA, Licensor grants End User
                  during the Term (as defined herein) a limited,
                  nonexclusive, nontransferable, non-assignable,
                  non-sublicensable, revocable license to use the Software
                  solely for the following internal business purposes of
                  Licensor and its Affiliates (“Permitted Uses”): (i)
                  increase productivity and communication on Licensor and
                  Affiliate property by consolidating resources and
                  increasing efficiencies, (ii) collect information and
                  data uploaded by End Users for purposes of preventative
                  maintenance, tracking, checklists, logs, pictures of
                  work completion, and property success plan audits, and
                  (iii) such other purposes that benefit Licensor and its
                  Affiliates and their businesses.
                </li>
                <li>
                  <b>Restrictions.</b> End User may not (and may not permit any
                  third party to): (i) modify, incorporate or use in any
                  other works, translate, reverse engineer, decompile,
                  disassemble, otherwise attempt to derive source code
                  from or create derivative works based on the Software;
                  (ii) make unauthorized copies of the Software; (iii)
                  provide access to any individual or entity that is not
                  the named End User; (iv) disclose, distribute, transfer
                  or market the Software to third parties; (v) remove or
                  modify any proprietary notices, labels or marks on or in
                  any copy of the Software; (vi) distribute, sell,
                  sublicense, rent, lease or use the Software (or any
                  portion thereof) for time sharing, hosting, service
                  provider or other computer services to third parties or
                  otherwise make the functionality of the Software
                  available to third parties; (vii) disseminate (whether
                  publicly or privately) any information obtained through
                  the Software; (viii) disseminate any performance
                  information or analysis (including, without limitation,
                  benchmarks and performance tests) from any source
                  relating to the Software; (xi) access any third party
                  databases that may be used to provide the Software to
                  you or which are embodied in the Software; or (ix) use
                  the Software other than for the Permitted Uses stated
                  herein.
                </li>
              </ul>
            </li>
            <li>
              <b>Support and Maintenance.</b> Licensor will update the Software
              and Documentation within a reasonable period of time after
              receiving Updates from the original licensor. Licensor will
              use commercially reasonable efforts to assist End User with
              support questions and general trouble shooting to enable
              access and use the Software.{" "}
            </li>
            <li>
             <p>
              <b> Technical Requirements; End User Account Registration and
              Access.</b>
             </p>
              <ul>
                <li>
                  <b>Technical Requirements.</b> You are solely responsible for
                  acquiring and maintaining all of the equipment,
                  software, firmware, and services necessary for you (and
                  the entity for whom you work) to access and use the
                  Software, including without limitation paying all
                  charges, taxes, and other costs and fees related to
                  internet access and your mobile device (as applicable).
                  You acknowledge that the Software is designed to run on
                  specific devices, applications, and firmware, as set
                  forth in the Documentation. You agree that you are
                  responsible for reviewing the technical requirements in
                  the Documentation and for meeting such technical
                  requirements.{" "}
                </li>
                <li>
                 <b>Registration.</b> Only registered End Users may access the
                  Software. You may register as an End User when you
                  download the Software and accept this EULA through your
                  mobile phone using an Apple Media Services app, Google
                  Play app, or Licensor's website access link. You are
                  responsible for complying with all applicable third
                  party provider terms (e.g., Apple Media Services Terms
                  and Condition, Google Play Terms of Service, etc.). As
                  part of the acceptance and registration process, each
                  individual registering as an End User shall provide
                  truthful, accurate, and complete information. After the
                  initial registration process and prior to commencing a
                  new user session, individual users may be asked to
                  confirm their user login and password to access the
                  Software. You are solely responsible for maintaining the
                  confidentiality of your user name, passwords and End
                  User account information. You must immediately notify
                  Licensor upon discovery of any unauthorized use of your
                  user name, passwords, or End User account information.
                  In the event confidentiality of the foregoing is
                  breached, Licensor reserves the right to suspend your
                  access to and use of the Software until the situation is
                  resolved to Licensor's reasonable satisfaction.{" "}
                </li>
                <li>
                  <b>Disclaimer.</b> Licensor disclaims all liability to you and
                  any third party for any unauthorized access to or use
                  your user name, passwords or End User account
                  information, and for any errors, malfunctions or
                  performance issues caused by any third party equipment,
                  software, hardware, servers or telecom networks or by
                  your failure to comply with the technical requirements.
                </li>
                <li>
                  <b>Collection of Data.</b> The Software may collect certain End
                  User personal information upon registration of your End
                  User account and during your use of the Software
                  including, your name, address, phone number, IP address,
                  other device information, location, unique employee or
                  user ID and password. Please read Licensor's Privacy
                  Notice at:{" "}
                  <a target='_blank'  rel="noreferrer" href="https://www.gfhotels.com/gfbotprivacynotice.html">
                    https://www.gfhotels.com/gfbotprivacynotice.html
                  </a>{" "}
                  to learn about your rights with respect to your personal
                  information.
                </li>
              </ul>
            </li>
            <li>
              <b>Confidential Information.</b> You agree to hold in confidence at
              all times any and all confidential and proprietary
              information of Licensor to which End User may be provided
              access, whether intentionally or unintentionally, and in any
              format (written, verbal, or electronic) during your use of
              the Software (the <b>"Licensor Confidential Information"</b>). You
              agree not to use the Licensor Confidential Information
              except as necessary to use the Software for the Permitted
              Uses.
            </li>
            <li>
              <b>Proprietary Rights.</b> Licensor has all necessary rights and
              licenses to grant the licenses to End User as set forth in
              this EULA. Licensor is the owner of the services it provides
              to you in connection with the Software, all data obtained or
              uploaded through your use of the Software, and the Licensor
              Confidential Information. Other than as expressly set forth
              in this EULA, no license or other rights in or to the
              Software or related intellectual property rights are granted
              to End User, and all such licenses and rights are hereby
              expressly reserved for Licensor.
            </li>
            <li>
              <b>Warranty Disclaimer.</b> THE SOFTWARE IS PROVIDED “AS IS” AND
              LICENSOR MAKES NO WARRANTY OF ANY KIND, WHETHER EXPRESS,
              IMPLIED, STATUTORY, OR OTHERWISE THAT USE OF THE SOFTWARE
              WILL BE UNINTERRUPTED, ERROR-FREE OR DEFECT-FREE, SECURE, OR
              AVAILABLE AT ALL TIMES. THE SOFTWARE IS MADE AVAILABLE
              THROUGH THE INTERNET AND THIRD PARTY PROVIDER SERVICES AND
              DEVICES OVER WHICH LICENSOR HAS NO CONTROL; ACCORDINGLY,
              LICENSOR MAKES NO WARRANTIES OR GUARANTEES REGARDING
              AVAILABILITY OF OR ACCESS TO THE SOFTWARE. LICENSOR HEREBY
              SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY
              WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
              PURPOSE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.{" "}
            </li>
            <li>
             <b> Limitations of Liability.</b> TO THE EXTENT PERMITTED BY LAW, IN
              NO EVENT WILL LICENSOR BE LIABLE TO END USER OR ANY
              SUCCESSOR, BENEFICIARY OR RELATED PARTY TO END USER FOR
              DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, LOST
              PROFITS OR REVENUES, LOSS OF DATA OR USE, INTERRUPTION OF
              THE SERVICES, COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR
              SERVICES, OR FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
              PUNITIVE, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED AND,
              WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
              LIABILITY, WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGE.{" "}
            </li>
            <li>
              <p>
                <b>Term and Termination.</b>
              </p>
              <ul>
                <li>
                  <b>Term.</b> This EULA and the license rights granted hereunder
                  will commence on the later of the date that you have
                  completed registering your End User account and accepted
                  this EULA, and will continue until terminated for the
                  duration of the license period you have chosen upon
                  registration unless earlier terminated (the “Term”). End
                  User may install the Software on one or more single
                  computers, provided that each installation shall be
                  performed by an individual having the authority to act
                  on behalf of End User and to bind End User to the terms
                  of this EULA.
                </li>
                <li>
                  <b>Licensor's Suspension and Termination Rights.</b> Licensor
                  may, if it deems it necessary in its sole and absolute
                  discretion, deny any End User access to the Software,
                  temporarily suspend any End User, or terminate any End
                  User's license and this EULA with respect to that End
                  User if Licensor has reasonable cause to believe that
                  the (i) End User's registration information is not
                  accurate or true, (ii) End User has violated the terms
                  of this EULA, (iii) End User's use of the Software is a
                  threat or could cause harm to Licensor, Licensor's
                  business, the businesses of any of Licensor's
                  Affiliates, or other End Users, or (iv) End User's use
                  of the Software could impact the safety,
                  confidentiality, or privacy of any other End User or the
                  ability of another End User to use the Software. End
                  User agrees that if, at Licensor determines in its sole
                  discretion that End User is using the Software in a
                  manner that violates laws, rules or regulations or
                  creates an excessive burden or potential adverse impact
                  on Licensor or other End Users, Licensor may suspend or
                  terminate End User's access to the Software without
                  notice to or liability to End User.
                </li>
                <li>
                  <li>End User's Termination Rights.</li> You may terminate this
                  EULA for convenience at any time by notifying Licensor
                  in writing, and within a reasonable time thereafter,
                  Licensor will deactivate your End User account. License
                  fees are nonrefundable.
                </li>
                <li>
                 <b> Effects of Termination.</b> Upon termination of this EULA,
                  Licensor will deactivate your End User account and
                  terminate your access to the Software. To the extent you
                  are in possession of any Licensor Confidential
                  Information, you must immediately destroy all copies of
                  such Licensor Confidential Information in all formats.
                  Licensor may retain copies of certain End User
                  information for archival and internal business purposes
                  and if required by law. Please see the Privacy Notice
                  for more details.
                </li>
                <li>
                  <b>Survival.</b> Sections 4(c), 5, 6, 7, 8, 9(d), and 11 of
                  this EULA will survive its termination.
                </li>
              </ul>
            </li>
            <li>
              <b>Force Majeure.</b> Licensor will not be liable to End
              User for any delay or failure to perform any obligation
              under this EULA if the delay or failure is due to unforeseen
              events, which occur after the signing of this EULA and which
              are beyond Licensor's reasonable control.
            </li>
            <li>
              <b>Miscellaneous Provisions.</b> Nothing in this EULA
              authorizes a party to act as an agent of the other or bind
              the other to any transaction or agreement. This EULA will
              bind and inure to the benefit of each party's permitted
              successors and assigns. Licensor may assign or transfer this
              EULA in whole or in part by operation of law or otherwise,
              without End User's prior written consent. End User's license
              and rights under this EULA are not assignable without
              Licensor's prior written consent, and any attempt to
              transfer or assign this EULA without Licensor's written
              consent will be null and void. In the event any provision of
              this EULA shall be determined to be invalid or unenforceable
              under law, all other provisions of this EULA shall continue
              in full force and effect. This EULA contains the entire
              agreement of the parties with respect to the subject matter
              of this EULA and supersedes all previous communications,
              representations, understandings and agreements, either oral
              or written between the parties with respect to said subject
              matter. A waiver of any breach under this EULA shall not
              constitute a waiver or any other breach or future breaches.
              All notices, requests, demands and other communications
              hereunder shall be in writing and shall be deemed to have
              been duly given if delivered via display on the Software
              login page or by email to your End User account. This EULA
              will be interpreted and construed in accordance with the
              laws of the State of Pennsylvania without regard to conflict
              of law principles.
            </li>
          </ol>
        </div>
      </>
    );
  }
}

export default PrivacyNotice;
