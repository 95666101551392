////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Recent daily log                            //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";

export class RecentDailyLog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    dailyLogList: this.props.dailyLogList ? this.props.dailyLogList :[],
      
    };

  }

  render() {
    return (
      <>
      
      <div className="card card-dashboard-sixteen">
        <div className="card-header">
          <h6 className="card-title tx-14 mg-b-0">
            Recent Daily Log{" "}
            <Link to="/daily-logs" className="btn_view">
              View More
            </Link>
          </h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table mg-b-0">
              <tbody>
                {this.state.dailyLogList.length != 0 || this.state.dailyLogList.length > 0? (
                  this.state.dailyLogList.map((el, index) => (
                    <tr key={"daily_" + index}>
                      <td>
                        <h6 className="mg-b-0 ">
                          {el.title ? el.title : ""}
                        </h6>
                        <small className="tx-11">
                          {el.property
                            ? el.property.property_name
                            : ""}
                        </small>
                      </td>
                      <td>
                        <h6 className="mg-b-0 ">
                          {" "}
                          {el.created_at
                            ? Moment(el.created_at).format(
                              "DD MMM, YY - hh:mm A"
                            )
                            : ""}
                        </h6>
                        <small className="tx-11">
                          {el.user ? el.user.full_name : ""}
                        </small>
                      </td>
                    </tr>
                  ))
                ) : (<><tr key="1">No record found</tr></>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      </>
    );
  }
}
export default RecentDailyLog;
