////////////////////////////////////////////////////////////
//     							                          //
//  Program: OnKeyPressEvent.jsx                          //
//  Application: Common Components                        //
//  Option: For to prevent specific key value             //
//  Developer: CV  						                  //
//  Date: 2021-04-13                                      //
////////////////////////////////////////////////////////////

import { Component } from 'react'

class OnKeyPressEvent extends Component {

    phoneValidation = (event) => { // 0-9, space, +, -, ()
        let charCodeArray = [32, 40, 41, 43, 45];
        if ((event.charCode >= 48 && event.charCode <= 57) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    mobileValidation = (event) => {  // 0-9, +
        let charCodeArray = [43];
        if ((event.charCode >= 48 && event.charCode <= 57) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    floatValidation = (event) => { // 0-9, dot
        let charCodeArray = [46];
        if ((event.charCode >= 48 && event.charCode <= 57) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    numberValidation = (event) => { // 0-9
        let charCodeArray = [];
        if ((event.charCode >= 48 && event.charCode <= 57) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    numSpaceValidation = (event) => { // 0-9, space
        let charCodeArray = [32];
        if ((event.charCode >= 48 && event.charCode <= 57) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    numSpaceDashValidation = (event) => { // 0-9, space, dash
        let charCodeArray = [32, 45];
        if ((event.charCode >= 48 && event.charCode <= 57) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    alphaValidation = (event) => { // A-Z
        let charCodeArray = [];
        if ((event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 65 && event.charCode <= 90) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    alphaSpaceValidation = (event) => { // A-Z, space
        let charCodeArray = [32];
        if ((event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 65 && event.charCode <= 90) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    alphaNumValidation = (event) => { // 0-9, A-Z
        let charCodeArray = [];
        if ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 65 && event.charCode <= 90) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    alphaNumSpaceValidation = (event) => { // 0-9, A-Z, space
        let charCodeArray = [32];
        if ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 65 && event.charCode <= 90) || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    excludeSpaceValidation = (event) => { // exclude space
        let charCodeArray = [32];
        if (charCodeArray.indexOf(event.charCode) > -1) {
            event.preventDefault();
        } else {
            return true;
        }
    }

    timeValidation = (event) => {
        let charCodeArray = [];
        if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 58 || charCodeArray.indexOf(event.charCode) > -1) {
            return true;
        } else {
            event.preventDefault();
        }
    }

    //for thousand seprattor
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    //for thousand seprattor having decimal more than 2 (i.e. 4220.1200)
    numberWithCommasForDecimal(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    // For rounded point value
    customeToFixed(num, precision = 2) {
        if (num === '' || num === null || num === 'NaN') {
            num = 0;
        }
        return parseFloat(+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    }

    // For without rounded point value
    customeToFixedDown(num, precision = 2) {
        if (num === '' || num === null || num === 'NaN') {
            num = 0;
        }
        return Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    // To check users access permission
    checkPermission(route) {
        let permissions = (localStorage.getItem('permissions')) ? localStorage.getItem('permissions') : [];
        if(permissions.length > 0){
            let permissions1 = permissions.split(',');
            //for matching permission slug (if not exist,it will return -1)
            if (permissions1.indexOf(route) >= 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    dispNumber(n, d, prefix, suffix) {
        // convert n to a display number with d decimals
        var s = Number(n);
        if (s >= 0){
            s = this.inpNumber(n, d) + "&nbsp;"
        } else {
            n = Math.abs(n);
            s = "<span class=\"negnum\">(" + this.inpNumber(n, d) + ")</span>";
        }
        return (prefix ? prefix : "") + s + (suffix ? suffix : "");
    }

    inpNumber(n, d) {
        if (Math.floor(n) != n) {
            n = Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
        }
        var s = n.toString();
        // insert commas
        var p = s.indexOf('.');
        if (p < 0) {
            p = s.length;
            if (d > 0) {
                s = s + ".";
                for (var i = 0; i < d; i++)
                    s = s + "0";
            }
        } else if ((s.length - p - 1) < d) {
            for (i = (s.length - p - 1); i < d; i++)
                s = s + "0";
        }
        for (i = p - 3; i > 0; i -= 3)
                s = s.substr(0, i) + "," + s.substr(i);
        return s;
    }

}

export default OnKeyPressEvent
