import React, { Component } from "react";
// import adminLogo from "./../../assets/images/logo-header.jpg";
import defaultImage from "./../../assets/images/default.png";
import { Navbar, Container, Dropdown } from "react-bootstrap";
import siteLogo from "./../../assets/images/GFBOT-Horizontal.png";
//import back from "./../../assets/images/back.svg";

class HeaderTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerToken: localStorage.getItem("headerToken"),
      profileImage:
        localStorage.getItem("userData") !== null &&
        localStorage.getItem("userData") !== undefined &&
        JSON.parse(localStorage.getItem("userData")).profile_thumb_url !== ""
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage,
      first_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
      url_slug: window.location.pathname,
    };
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    // Get user profile and set into localstorage and fresh the page
    if (localStorage.getItem("isLogin") === "1") {
      localStorage.removeItem("isLogin");
      window.location.reload(false);
    }
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
  }

  closeMenu(e) {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.querySelector("body").classList.remove("az-header-menu-show");
    }
  }

  render() {
    return (
      <div className="adminHeadertop">
        <img src={siteLogo} alt="" />
        <Navbar expand="lg">
          <Container className="leftNavbar">
            <div className="d-flex az-header-top justify-content-between">
              <div className="az-header-left">
                <a
                  href="#/"
                  id="azMenuShow"
                  onClick={(event) => this.toggleHeaderMenu(event)}
                  className="az-header-menu-icon"
                >
                  <span></span>
                </a>
                 {/* <div className="d-flex justify-content-between align-items-center w-100 ">
                        <h3 className="d-flex hand-cursor back-size" onClick={this.filterToggle}>
                          <img src={back} />
                          Back
                        </h3>
                      </div> */}
                
              </div>
            </div>
          </Container>
          <div className="mobView col-4 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
          <div className="rightNavbar col-4 col-sm-1 col-md-1 col-lg-1 col-xl-2">
            <Dropdown className="d-flex justify-content-end">
              <Dropdown.Toggle variant="secondary-" className="pd-0">
                <div className="navMob d-flex align-items-center">
                  <figure>
                    <img
                      src={
                        this.props.image
                          ? this.props.image
                          : this.state.profileImage
                      }
                      alt="img"
                    />
                  </figure>
                  <span className="profileContent d-flex flex-column text-left mg-l-5">
                    <b>{this.state.first_name}</b>
                    <i className="tx-12">{global.roleTypeName}</i>
                  </span>
                  <span className="ml-3">
                    <i className="fas fa-chevron-down"></i>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="tx-13">
                <Dropdown.Item
                  href="#/"
                  className="navMob d-flex align-items-center flex-row"
                >
                  <figure>
                    <img
                      src={
                        this.props.image
                          ? this.props.image
                          : this.state.profileImage
                      }
                      alt="img"
                    />
                  </figure>
                  <span className="profileContent d-flex flex-column text-left mg-l-5">
                    <b>{this.state.first_name}</b>
                    <i className="tx-12">{global.roleTypeName}</i>
                  </span>
                </Dropdown.Item>
                <Dropdown.Item href="/update-profile">
                  Update Profile
                </Dropdown.Item>
                <Dropdown.Item href="/change-password">
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item href="/logout">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default HeaderTop;
