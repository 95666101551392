////////////////////////////////////////////////////////////
//     							                                      //
//  Program: recent inspection                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, {PureComponent } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";

export class RecentInspections extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    inspectionList: this.props.inspectionList ? this.props.inspectionList :[],  
    };
  }
  render() {
    return (
      <>
      
                      <div className="card card-dashboard-sixteen">
                        <div className="card-header">
                          <h6 className="card-title tx-14 mg-b-0">
                            Recent Inspections{" "}
                            <Link to="/inspections" className="btn_view">
                              View More
                            </Link>
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table mg-b-0">
                              <tbody>
                                {this.state.inspectionList.length != 0 || this.state.inspectionList.length > 0 ? (
                                  this.state.inspectionList.map((el, index) => (
                                    <tr key={"ins" + index}>
                                      <td>
                                        <h6 className="mg-b-0 ">
                                          #
                                          {el.inspection_no
                                            ? el.inspection_no
                                            : ""}
                                        </h6>
                                        <small className="tx-11">
                                          {el.property.property_name
                                            ? el.property.property_name
                                            : ""}
                                        </small>
                                      </td>
                                      <td>
                                        <h6 className="mg-b-0 ">
                                          {" "}
                                          {el.completed_on
                                            ? Moment(el.completed_on).format(
                                              "DD MMM, YY - hh:mm A"
                                            )
                                            : ""}
                                        </h6>
                                        <small className="tx-11">
                                          {el.inspector
                                            ? el.inspector.full_name
                                            : ""}
                                        </small>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <>
                                    <tr>No record found</tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  
      </>
    );
  }
}
export default RecentInspections;
