////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Recent audit                            //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";

export class RecentAudits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    auditlist: this.props.auditlist ? this.props.auditlist :[],
      
    };

  }

  render() {
    return (
      <>
     
                      <div className="card card-dashboard-sixteen">
                        <div className="card-header">
                          <h6 className="card-title tx-14 mg-b-0">
                            Recent Audits{" "}
                            <Link to="/audits" className="btn_view">
                              View More
                            </Link>
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table mg-b-0">
                              <tbody>
                                {this.state.auditlist.length != 0 || this.state.auditlist.length > 0 ? (
                                  this.state.auditlist.map((el, index) => (
                                    <tr key={"audit" + index}>
                                      <td>
                                        <h6 className="mg-b-0 ">
                                          #{el.audit_no ? el.audit_no : ""}
                                        </h6>
                                        <small className="tx-11">
                                          {el.property_name
                                            ? el.property_name
                                            : ""}
                                        </small>
                                      </td>
                                      <td>
                                        <h6 className="mg-b-0 ">
                                          {" "}
                                          {el.completed_on
                                            ? Moment(el.completed_on).format(
                                              "DD MMM, YY - hh:mm A"
                                            )
                                            : ""}
                                        </h6>
                                        <small className="tx-11">
                                          {el.auditor ? el.auditor.full_name : ""}
                                        </small>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <>
                                    <tr>No record found</tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  
      </>
    );
  }
}
export default RecentAudits;
