/*
* Program: BrandView.jsx
* Application: Customer
* Option: to View Customer Information
* Developer: Chandra Shekhar
* Date: 2022-03-10
* Status: Not in Use
*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import CustomerService from "../../services/CustomerService";
import customerLogo from "./../../assets/images/default.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";

export class BrandView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      showSpinner: false,
      loading: true,
      records: [],
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.opendeleteCustomerModal = this.opendeleteCustomerModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await CustomerService.getCustomer(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.country = res.data ? res.data.country_name : "";
        resData.county = res.data ? res.data.county_name : "";
        resData.state = res.data ? res.data.state_name : "";
        resData.city = res.data ? res.data.city_name : "";
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false });
  }

  opendeleteCustomerModal(slug) {
    this.setState({ deleteCustomerModal: true });
  }

  closedeleteCustomerModal() {
    this.setState({ deleteCustomerModal: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }
  async deleteCustomer(slug) {
    this.setState({ showSpinner: true });
    let res = await CustomerService.deleteCustomer(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        records: this.state.records.filter((list) => list.slug !== slug),
      });
      setTimeout(
        () => this.props.history.push("/customer"),
        global.redirect_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
                <div className="backpage">
                  <Link to="/customer">Customer </Link>
                  <span> / </span>
                  <span>VIEW</span>
                </div>
              </div>

              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>

              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>{this.state.customer_name ? this.state.customer_name : ""}</h3>
                  <div className="d-flex flex-wrap- bothBtn">
                    <button
                      onClick={() => {
                        this.opendeleteCustomerModal(this.state.slug);
                      }}
                      className="btn-danger-outline mg-r-20"
                    >
                      <img src={deleteicon} alt="" />
                      Delete
                    </button>
                    <Link
                      to={"/customer/update/" + this.state.slug}
                      className="mg-l-2"
                    >
                      <button className="btn-success-outline">
                        <img src={editIcon} alt="" />
                        Edit
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs mg-t-30 pd-t-2">
              <div className="col-md-4 col-lg-3 mg-b-30">
                <div className="dashboardLeftcard">
                  <div className="az-profile-overview">
                    <div className="az-img-user mg-l-auto mg-r-auto align-items-center mb-2">
                      <img
                        src={
                          this.state.customer_logo_url
                            ? this.state.customer_logo_url
                            : customerLogo
                        }
                        alt=""
                      />
                    </div>
                    {/* az-img-user */}
                    <div className="d-flex align-items-center justify-content-center text-center">
                      <b
                        className={
                          this.state.status_id === 1
                            ? "status-active"
                            : "status-inactive"
                        }
                      >
                        {this.state.status_id === 1 ? "Active" : "Inactive"}
                      </b>
                    </div>
                    <div className="d-flex justify-content-start mg-b-20 mg-t-20">
                      <div>
                        <p className="az-profile-name-text">Customer Name</p>
                        <p className="media-body">
                          {this.state.customer_name ? this.state.customer_name : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* az-profile-overview */}
                </div>
              </div>

              <div className="col-md-8 col-lg-9 mg-b-30">
                <div className="dashboardRightcard">
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal
          show={this.state.deleteCustomerModal}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-between mt-3 row">
                <button
                  type="button"
                  className="mx-auto m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteCustomerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="mx-auto m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCustomer(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default BrandView;
