////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Signin.jsx                                   //
//  Application: authentication                           //
//  Option: For sign in into project                      //
//  Developer: 	PS              					                //
//  Date: 2022-04-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect } from "react-router-dom";
import Alerts from "../common/Alerts";

class Signin extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      isSubmit: false,
      showAlertModal: true,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      email: "",
      password: "",
      headerToken: localStorage.getItem("headerToken"),
      passwordType: 'password',
      showPasswordIcon: 'fa fa-eye-slash',
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.signin = this.signin.bind(this);
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  componentDidMount() {
    if (this.state.headerToken !== null || this.state.headerToken !== "") {
      // localStorage.clear();
      localStorage.removeItem("sessionExpiry");
      localStorage.removeItem("headerToken");
      localStorage.removeItem("customSpinnerSession");
      localStorage.removeItem("properties");
      localStorage.removeItem("activePropertyCount");
      localStorage.removeItem("userData");
      localStorage.removeItem("permissions");
      sessionStorage.clear();
    }
  }

  signin = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        email: this.state.email,
        password: this.state.password,
      };
      let res = await AuthenticationService.signin(input);
      if (global.successStatus.includes(res.status)) {
        localStorage.setItem(
          "headerToken",
          res.data.headerToken ? res.data.headerToken : ""
        );
        global.headerToken = res.data.headerToken ? res.data.headerToken : "";
        localStorage.setItem("isLogin", 1);
        localStorage.setItem(
          "userData",
          res.data.user_data ? JSON.stringify(res.data.user_data) : {}
        );
        localStorage.setItem(
          "permissions",
          res.data.permissions ? res.data.permissions : []
        );
        localStorage.setItem(
          "properties",
          res.data.properties ? JSON.stringify(res.data.properties) : {}
        );
        
        localStorage.setItem(
          "activePropertyCount",
          res.data.properties ? res.data.properties.length : 0
        );

        if (res.data.properties.length === 1) {
          localStorage.setItem(
            "singlePorpertySlug",
            res.data.properties ? res.data.properties[0].slug : ""
          );
          localStorage.setItem(
            "singlePropertyName",
            res.data.properties ? res.data.properties[0].property_name : ""
          );
        }
        this.props.history.push("/dashboard");
      } else if (res.status === 401) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else if (res.status === 422) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else if (res.status === 400) {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      } else {
        if (
          res.data.result.errorDetail !== null &&
          res.data.result.errorDetail !== undefined
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error",
          alertModalMessage: res.data.message ? res.data.message : "Error",
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
      this.setState({ isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // Validate
    this.validator.showMessageFor(event.target.name);
  };

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }
  handleClickShowPassword = (e) => {
    this.setState({
      passwordType: this.state.passwordType === "password" ? "text" : "password",
    });
    this.state.passwordType === "password" ? this.setState({ showPasswordIcon: 'fa fa-eye' }) : this.setState({ showPasswordIcon: 'fa fa-eye-slash ' });
  };

  render() {
    // Redirect to dashboard if logged in
    // if (this.state.headerToken !== null) {
    //   return <Redirect to="/signin" />;
    // }
    if (this.state.headerToken !== null) {
      return <Redirect to='/dashboard' />;
    }
    return (
      <div className="authentication d-flex flex-wrap">
        <div className="az-signin-wrapper"></div>
        <div className="az-card-signin">
          <div className="az-logo d-flex flex-column">
            <img
              src={require("../../assets/images/GFBOT-Horizontal.png").default}
              alt=""
            ></img>
            {/* <h2>GF-Hotels & Resorts</h2> */}
          </div>
          <div className="az-signin-header">
            <h3 className="text-left">Login</h3>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            <form action="#/">
              <div className="form-group">
                <label>Email</label>
                <input
                  name="email"
                  className="form-control"
                  value={this.state.email}
                  onChange={this.changeHandler}
                  maxLength="50"
                />

                {this.validator.message(
                  "email",
                  this.state.email,
                  "email|required",
                  { className: "valid_info" }
                )}
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="showPassword">
                  <input
                    type={this.state.passwordType}
                    name="password"
                    className="form-control"
                    value={this.state.password}
                    onChange={this.changeHandler}
                    maxLength="25"
                  />
                  <span>
                    <i
                      className={this.state.showPasswordIcon}
                      onClick={this.handleClickShowPassword}
                    ></i>
                  </span>
                </div>
                {this.validator.message(
                  "password",
                  this.state.password,
                  "required",
                  { className: "valid_info" }
                )}
              </div>
              <button
                className="btn btn-info btn-block button-green"
                onClick={this.signin}
              >
                {this.state.isSubmit ? global.loader : "Login"}
              </button>

              <div className="az-signin-footer text-left mt-3 login-footer_cust">
                <p>
                  <Link to="/forgot-password" className="tx-16">
                    Forgot password?
                  </Link>
                </p>
                <p className="divider-login">|</p>
                <p>
                  <Link to="/privacy-notice" className="tx-16">
                    Privacy notice
                  </Link>
                </p>
                <p className="divider-login">|</p>
                <p>
                  <Link to="/signin"
                    className="tx-16"
                    onClick={() => window.location = 'mailto:gfbotsupport@gfhotels.com'}>
                     Contact us
                  </Link>
                </p>
                <p className="divider-login">|</p>
                <p>
                  <Link to="/app-download" className="tx-16">
                    App
                  </Link>
                </p>

              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
