/*
 * Program: User.jsx
 * Application: User
 * Option: to List out of User Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-31
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import UserService from "../../services/UserService";
export class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      loading: false,
      showSpinner: false,
      records: [],
      config: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by role name",
        },
      },
      extraButtons: [],
      columns: [
        {
          key: "role_name",
          text: "Name",
          className: "role_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.role_name ? record.role_name : "";
          },
        },

        {
          key: "status",
          text: "Status",
          className: "email hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? (
                <b className="status-active">
                  Active
                </b>
              ) : (
                <b className="status-inactive">
                 Inactive
                </b>
              );
          },
        },


      ],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    let list = [];
    let res = await UserService.getRoleList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
    });
  }


  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.fistLoad === true) {
        queryString = "page_number=" + data.page_number;
      }
      this.getData(queryString + this.state.lastQueryString);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/role-permission/" + data.slug);
  };
  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                                <div className="dashbaord-pageHeadertitle">
                                <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Roles</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                         
                        </div>
                      </div>
                      <span>All Roles</span>
                    </h3>
                </div>
                                
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="mg-tneg-4 brandList dashboardRightcard">
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Roles;
