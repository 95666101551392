////////////////////////////////////////////////////////////
//  Program: Template List.jsx                            //
//  Application:  Template  List                          //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-03-31                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Row, Col } from "react-bootstrap";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import TrackeService from "../../services/TrackeService";
import back from "./../../assets/images/back.svg";
import ReactDatatable from "@ashvin27/react-datatable";

export class CommercialsTracker extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      TrackerList: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      monthList: [],
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      year: new Date(),
      currentYear: new Date().getFullYear(),
      property_slug: "",
      selectedPropertyList: [],
      propertySlug: "",
      propertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      inspectionMonth: [],
      records: [],
      trackerTypeText: "",
      trackerType: "",
      propertyLable: "",
      isFilterTrue: false,
      config: {
        key_column: "unique_slug",
        show_info: false,
        per_page: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: "advance",
        language: {
          no_data_text: "No record found",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "zone",
          text: "Commercials Zone",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.name ? record.name : "";
          },
        },
        {
          key: "Jan",
          text: "Jan",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.jan ? record.jan : "";
          },
        },
        {
          key: "Feb",
          text: "Feb",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.feb ? record.feb : "";
          },
        },
        {
          key: "Mar",
          text: "Mar",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.march ? record.march : "";
          },
        },
        {
          key: "Apr",
          text: "Apr",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.april ? record.april : "";
          },
        },
        {
          key: "May",
          text: "May",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.may ? record.may : "";
          },
        },
        {
          key: "Jun",
          text: "Jun",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.june ? record.june : "";
          },
        },
        {
          key: "Jul",
          text: "Jul",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.july ? record.july : "";
          },
        },
        {
          key: "Aug",
          text: "Aug",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.aug ? record.aug : "";
          },
        },
        {
          key: "Sep",
          text: "Sep",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.sep ? record.sep : "";
          },
        },
        {
          key: "Oct",
          text: "Oct",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.oct ? record.oct : "";
          },
        },
        {
          key: "Nov",
          text: "Nov",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.nov ? record.nov : "";
          },
        },
        {
          key: "Dec",
          text: "Dec",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.dec ? record.dec : "";
          },
        },
      ],
    };
    this.filterToggle = this.filterToggle.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetData = this.resetData.bind(this);
  }
  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  componentDidMount() {
    this.globalDataSet();
    this.getTrackerData();
    this.getPropertyList();
    this.setState({ zoneTypeList: global.zoneTypeList });
    this.setState({ monthList: global.selectMonth });
  }
  globalDataSet = () => {
    var singleUser = localStorage.getItem("activePropertyCount") !="" && localStorage.getItem("activePropertyCount") != undefined ?  parseInt(localStorage.getItem("activePropertyCount")) :"";
    if (singleUser === 1) {
      var singlePropertyName = localStorage.getItem("singlePropertyName");
      var singlePorpertySlug = localStorage.getItem("singlePorpertySlug");
      const selectedYear = new Date().getFullYear();
      var filterValue =
      '{"year" :"' +
      selectedYear +
      '", "property_slug" :"' +
      singlePorpertySlug +
      '" , "property_name" :"' +
      singlePropertyName +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    }
  }
  customValidate() {
    let isValid = true;
    return isValid;
  }
  async getTrackerData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    if (filterData) { 
      queryString = "year=" + filterData.year + "&property_slug=" + filterData.property_slug;
      if (filterData.property_name && filterData.property_slug) { 
        var Year = Moment(filterData.year).toDate("YYYY");
        var selectedPropertyList = {
          label: filterData.property_name,
          value: filterData.property_slug,
        };
        this.setState({
          propertySlug: filterData.property_slug,
          propertyLable: filterData.property_name,
          year: Year,
          selectedPropertyList: selectedPropertyList,
          selectYear: Moment(filterData.year).format("YYYY"),
        });
      }
    }
    let res = await TrackeService.getCommerTrackerData(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
    });
  }
  // Property Change Handler
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: "",
      trackerTypeText: "",
      trackerType: "",
      selectedPropertyList: value,
      property_slug: value.value,
      propertySlug: value.value,
      propertyLable: value.label,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
    });
  }

  resetData() {
    this.setState({
      locationList: "",
      selecteLocationList: "",
      selectedZoneTypeList: "",
      propertyList: "",
      selectedPropertyList: "",
      trackerTypeText: "",
      trackerType: "",
      propertySlug: "",
      selectedPropertyName: "",
      singleScreenProperty: "",
      singleScreen: false,
      propertyLable: "",
      year:new Date(),
      selectYear:"",
      
    });
    localStorage.removeItem("globleTracker");
    var GlobalApply = localStorage.getItem("outSidePorpertySlug") !="" && localStorage.getItem("outSidePorpertySlug") != undefined ?  parseInt(localStorage.getItem("outSidePorpertySlug")) :"";
    if (GlobalApply != undefined && GlobalApply != "") {
      const selectedYear = new Date().getFullYear();
      var filterValue =
      '{"year" :"' +
      selectedYear +
      '", "property_slug" :"' +
      localStorage.getItem("outSidePorpertySlug") +
      '" , "property_name" :"' +
      localStorage.getItem("selectedPropertyName") +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    }
    if (localStorage.getItem("activePropertyCount") != "" && localStorage.getItem("activePropertyCount") != undefined) {
      this.globalDataSet();
    }
    this.getTrackerData("resetCall");
    this.getPropertyList();
  }

  customFilter() {
    const selectedYear = this.state.year.getFullYear();
    let queryString =
      "year=" + selectedYear + "&property_slug=" + this.state.propertySlug;
    this.setState({ tableQueryString: "", customQueryString: queryString });
    var filterValue =
      '{"year" :"' +
      selectedYear +
      '", "property_slug" :"' +
      this.state.propertySlug +
      '" , "property_name" :"' +
      this.state.propertyLable +
      '"}';
    localStorage.setItem("globleTracker", filterValue);
    this.setState({ isFilterTrue: true });
    this.getTrackerData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }
  // Get Property List
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    this.setState({ propertyList: propertyListData });
  }

  rowClickedHandler = (event, data, rowIndex) => {
    if (data.slug !== undefined) {
      window.location.href = "inspection/update/" + data.slug;
    }
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main className="p-0">
          <div className="container-fluid p-0">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle tracker_btn_new">
                    <div className="btnNavigate d-flex justify-content-end">
                      <button
                        onClick={this.filterToggle}
                        className="gf-primary-btn-small-one"
                      >
                        {this.state.showFilter === 1 ? "Filter" : "Filter"}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    {this.state.showFilter ? (
                      <Row className="align-items-start fliter_back">
                        <div className="order_form">
                          <div className="d-flex justify-content-between align-items-center w-100 ">
                            <h3
                              className="hand-cursor back-size"
                              onClick={this.filterToggle}
                            >
                              <img src={back} alt="" />
                              Back
                            </h3>
                          </div>

                          <h3>Filter</h3>
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            <label className="form-label text-left mg-b-5 mt-0">
                              Year
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <DatePicker
                                className="form-control max_width_100"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="year"
                                showYearPicker
                                dateFormat="yyyy"
                                selected={this.state.year}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "year",
                                      value: data,
                                    },
                                  })
                                }
                              />
                            </Col>
                            <label className="form-label text-left mg-b-5">
                              Property
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedPropertyList}
                                options={this.state.propertyList}
                                onChange={(value) =>
                                  this.handleChangeProperty(value)
                                }
                                defaultValue={this.state.selectedPropertyList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid p-0">
              <div className="mgb-neg-20 brandList dashboardRightcard  month_width">
              <div className="row">
              <div className="col-md-12">
              {this.state.selectYear != undefined && this.state.selectYear != ""  ? (<>
                    <div className="checklist_table_heading"><span className="lspan">Year : </span><span className="rspan">{this.state.selectYear ? this.state.selectYear:""}</span></div>
                  </>) :""}
                  {this.state.propertyLable !== undefined && this.state.propertyLable !== ""? (<>
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On : </span><span className="rspan">{this.state.propertyLable ? this.state.propertyLable : ""}</span></div>
                    </>) :""}
                </div>
              </div>
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 table-bordered"
                  config={this.state.config}
                  records={this.state.records}
                  columns={this.state.columns}
                  extraButtons={this.state.extraButtons}
                  dynamic={true}
                  loading={this.state.loading}
                  total_record={this.state.total_record}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandler}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CommercialsTracker;
