////////////////////////////////////////////////////////////
//  Program: ComercialAreaService.js                      //
//  Application: ComercialAreaService                     //
//  Option: Used for manage Properties                    //
//  Developer: Kiran singh 						                    //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class ComercialAreaService {
  getComercialArea(queryString) {
    let endPoint = "comercialArea" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createComercialArea(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "comercialArea";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getComercialAreaBySlug(slug) {
    let endPoint = "comercialArea/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateComercialArea(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "comercialArea/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteComercialArea(slug) {
    let endPoint = "comercialArea/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  getSortOrder(tableName){
    let endPoint = "comercialArea/" + tableName;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new ComercialAreaService();
