/*
 * Option: to Show Checklist Room Tab 
 */
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import brownArrow from "./../../assets/images/brown-arrow.svg";
import ChecklistService from "../../services/ChecklistService";
export class CheckListCommercialTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
          is_inactive: false,
          tableQueryString: '',
          customQueryString: '',
          showFilter: global.showFilter,
          input: {},
          showAlertModal: false,
          alertModalType: "",
          alertModalTitle: "",
          alertModalMessage: "",
          filter_value: '',
          loading: false,
          property_slug: this.props.property_slug,

        };
      }

      componentDidMount() { 
        this.checklistZoneWise(); 
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    var property_slug = nextProps.property_slug;
    this.setState({property_slug:property_slug});
    this.checklistZoneWise(); 
  }
      async checklistZoneWise() {
        var commercialsZoneChecklist = [];
        var queryString ="is_dropdown=1&filter_value=&property_slug=" + this.state.property_slug + "&zone_type=2";
        let res = await ChecklistService.getChecklistZoneWise(queryString);
        if (global.successStatus.includes(res.status)) {
          commercialsZoneChecklist = res.data ? res.data : [];
        }
        this.setState({ commercialsZoneChecklist: commercialsZoneChecklist });
      }
  render() {
    return (
      <div className="mg-tneg-4">
      <Table  className="mg-b-0 checklist_inspect_table">
        <thead>
        </thead>
      <tbody>
        {this.state.commercialsZoneChecklist != undefined && this.state.commercialsZoneChecklist.length > 0 ? this.state.commercialsZoneChecklist.map((el, indexes) => ( <>
          <tr >
          <th
            scope="row"
            className="az-inspection-black border-right-0"
          >
            
            <Link
              to={`/start-inspection-list/${el.slug}`}
              className="az-inspection-brown"
            >
            {el.checklist_name ?? ""}
            </Link>
          </th>
          <td className="border-left-0 pl-5">
            {/* <i className="mdi mdi-eye-outline text-primary"></i> */}
            <Link
              to={`/start-inspection-list/${el.slug}`}
              className=""
            >
              <img src={brownArrow} alt="" height={10} />
            </Link>
          </td>
        </tr>  
        </>)) : <label className="form-label text-left mg-b-5"> No Checklists Available </label>}
                  
        </tbody>
      </Table>
              
    </div>
    );
  }
}

export default CheckListCommercialTab;
