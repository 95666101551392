////////////////////////////////////////////////////////////
//  Program: StateService.js                              //
//  Application: StateService                             //
//  Option: Used for country list                         //
//  Developer: Kiran singh 						          //
//  Date: 2022-03-31                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class StateService {
  getStates(queryString) {
    let endPoint = 'state'+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
            'endPoint': endPoint,
            'addditionalHeaderData': addditionalHeaderData,
            'postData': postData
        }
    let response = ApiService.getCall(inputData);
    return response;
}

createStates(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'state';
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.postCall(inputData);
    return response;
}

getStatesBySlug(slug) {
    let endPoint = 'state/' + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.getCall(inputData);
    return response;
}

updateStates(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = 'state/' + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.putCall(inputData);
    return response;
}

deleteStates(slug) {
    let endPoint = 'state/' + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
        'endPoint': endPoint,
        'addditionalHeaderData': addditionalHeaderData,
        'postData': postData
    }
    let response = ApiService.deleteCall(inputData);
    return response;
}
}

export default new StateService();
