/////////////////////////////////////////////////////
//     							                                //
//  Program: Basic.jsx                             //
//  Application: Property                          //
//  Option: List all Property Basic info           //
//  Developer: Kiran singh						             //
//  Date: 2022-03-22                               //
//                                                 //
/////////////////////////////////////////////////////
import React, { Component } from "react";
import PropertyService from "../../services/PropertyService";
import CustomerService from "../../services/CustomerService";
import CountryService from "../../services/CountryService";
import StateService from "../../services/StateService";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import Select from "react-select";
// import modaldeleteicon from "./../../assets/images/delete.svg";

export class Basic extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      propertySlug: this.props.property_slug,
      showSpinner: false,
      loading: false,
      isSubmit: false,
      isDelete: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      viewMode: 0,
      propertyList: [],
      customerList: [],
      countyList: [],
      stateList: [],
      pathname: window.location.origin,
      pathnamel: window.location.pathname,
      status_id: true,
      delete_status_true: 1,
      delete_status_false: 0,
    };
    this.initialState = this.state;
    this.defaultData = {};
    this.changeView = this.changeView.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.opendeleteUserModal = this.opendeleteUserModal.bind(this);
    this.opendeletePropertyModal = this.opendeletePropertyModal.bind(this);
  }

  async componentDidMount() {
    //change tab logic
    if (this.state.pathnamel.includes("basic/equipment")) {
      document.getElementById("left-tabs-example-tab-equipment").click();
    }
    this.setState({ showSpinner: true, loading: true });
    this.getData();
    this.getCustomerData();
    this.getCountyData();
    this.getTimezone();
  }
  /* SINGLE PROPERTY DATA */
  async getData() {
    this.setState({ showSpinner: true, loading: true });
    if (this.props.property_slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      let res = await PropertyService.getProperty(this.props.property_slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.country = resDatalist.country
          ? resDatalist.country.country_name
          : "";
        resData.country_slug = resDatalist.country
          ? resDatalist.country.slug
          : "";
        resData.timezone_slug = resDatalist.timezone
          ? resDatalist.timezone.slug
          : "";
        resData.customer = resDatalist.customer
          ? resDatalist.customer.customer_name
          : "";
        resData.customer_slug = resDatalist.customer
          ? resDatalist.customer.slug
          : "";
        resData.statename = resDatalist.state
          ? resDatalist.state.state_name
          : "";
        resData.state_slug = resDatalist.state ? resDatalist.state.slug : "";
        resData.street_1 = resDatalist.street_1 ? resDatalist.street_1 : "";
        resData.street_2 = resDatalist.street_2 ? resDatalist.street_2 : "";
        resData.city = resDatalist.city ? resDatalist.city : "";
        resData.zip_code = resDatalist.zip_code ? resDatalist.zip_code : "";
        resData.property_name = resDatalist.property_name
          ? resDatalist.property_name
          : "";
        resData.property_uuid = resDatalist.property_uuid
          ? resDatalist.property_uuid
          : "";
        resData.status_id = resDatalist.status_id ? resDatalist.status_id : "";
        localStorage.setItem(
          "property_name",
          res.data.property_name ? res.data.property_name : ""
        );

        // CUSTOMER Select
        var selectedCustomer = [];
        selectedCustomer = res.data.data.customer
          ? [
              {
                value: res.data.data.customer.slug,
                label: res.data.data.customer.customer_name,
              },
            ]
          : "";

        // COUNTRY Select
        var selectedCountry = [];
        selectedCountry = res.data.data.country
          ? [
              {
                value: res.data.data.country.slug,
                label: res.data.data.country.country_name,
              },
            ]
          : "";

        // STATE Select
        var selectedState = [];
        selectedState = res.data.data.state
          ? [
              {
                value: res.data.data.state.slug,
                label: res.data.data.state.state_name,
              },
            ]
          : "";
        var selectedTimezone = [];
        selectedTimezone = res.data.data.timezone
          ? [
              {
                value: res.data.data.timezone.slug,
                label: res.data.data.timezone.timezone_name,
              },
            ]
          : "";
        this.getStateData(res.data.data.country.slug);
      }
      this.setState(resData);
      this.setState({ selectedCustomer: selectedCustomer });
      this.setState({ selectedCountry: selectedCountry });
      this.setState({ selectedState: selectedState });
      this.setState({ selectedTimezone: selectedTimezone });
      this.setState({ showSpinner: false, loading: false });
    }
  }

  /* ALL CUSTMER DATA */
  async getCustomerData(queryString = "") {
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    if (customerList.length > 0) {
      customerList.forEach((item) => {
        customerListData.push({
          label: item.customer_name,
          value: item.slug,
        });
      });
    }
    this.setState({ customerList: customerListData });
  }
  /* GET COUNTRY DATA FOR FILTER */
  async getCountyData(queryString = "") {
    var countryList = [];
    var countryListData = [];
    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    if (countryList.length > 0) {
      countryList.forEach((item) => {
        countryListData.push({
          label: item.country_name,
          value: item.slug,
        });
      });
    }

    this.setState({ countryList: countryListData });
  }
  /* GET COUNTRY DATA FOR FILTER */
  async getTimezone(queryString = "") {
    var timezoneList = [];
    var timezoneListData = [];
    let res = await CountryService.getTimezone(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      timezoneList = res.data ? res.data : [];
    }
    if (timezoneList.length > 0) {
      timezoneList.forEach((item) => {
        timezoneListData.push({
          label: item.timezone_name,
          value: item.slug,
        });
      });
    }
    this.setState({ timezoneList: timezoneListData });
  }
  /* GET STATE DATA FOR FILTER */
  async getStateData(countyValue, queryString = "") {
    var stateList = [];
    var stateListData = [];
    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countyValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    if (stateList.length > 0) {
      stateList.forEach((item) => {
        stateListData.push({
          label: item.state_name,
          value: item.slug,
        });
      });
    }
    this.setState({ stateList: stateListData });
  }
  // CUSTOMER Change Handler
  handleChangeCustomer(value) {
    let customer_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        customer_slug.push(item.value);
      });
    }
    this.setState({
      selectedCustomer: value,
      customer_slug: value.value,
    });
  }
  // Country Change Handler
  handleChangeCountry(value) {
    this.setState({ selectedState: "" });
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    this.setState({
      selectedCountry: value,
      country_slug: value.value,
      selectedState: [],
      state_slug: "",
      stateList: this.getStateData(value.value),
    });
  }

  // state Change Handler
  handleChangeState(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    this.setState({
      selectedState: value,
      state_slug: value.value,
    });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  handleChangeTimeZone(value) {
    let timezone_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        timezone_slug.push(item.value);
      });
    }
    this.setState({
      selectedTimezone: value,
      timezone_slug: value.value,
    });
  }
  async deleteProperties(slug, delete_status) {
    this.setState({ loading: true, isDelete: true });
    let res = await PropertyService.deleteProperty(slug, delete_status);
    if (global.successStatus.includes(res.status)) {
      this.closeDeleteModel();
      this.opendeletePropertyModal();
      this.closeDeletePropertyModel();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
      setTimeout(
        () => (window.location.href = this.state.pathname + "/properties"),
        global.redirect_time
      );
      // window.location.href = this.state.pathname + "/properties";
    } else {
      this.closeDeleteModel();
      this.opendeleteUserModal();
      this.closeDeletePropertyModel();
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  saveOrUpdateProperty = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        tab: 1,
        customer_slug: this.state.customer_slug,
        property_name: this.state.property_name,
        property_uuid: this.state.property_uuid,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        country_slug: this.state.country_slug,
        state_slug: this.state.state_slug,
        city: this.state.city,
        zip_code: this.state.zip_code,
        status_id: this.state.status_id === true ? 1 : 2,
        timezone_slug: this.state.timezone_slug,
      };

      if (this.state.propertySlug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        let res = await PropertyService.updateProperty(
          this.state.propertySlug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => (window.location.href = this.state.pathname + "/properties"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
    } else {
      this.setState({ viewMode: 1 });
    }
  }
  closeDeleteModel() {
    this.setState({ deleteUserModal: false });
  }

  opendeleteUserModal(slug) {
    this.setState({ deleteUserModal: true });
  }
  opendeletePropertyModal(slug) {
    this.setState({ deletePropertyModal: true });
  }
  closeDeletePropertyModel() {
    this.setState({ deletePropertyModal: false });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main className="p-0">
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
          <div className="dashboardRightcard m-0">
            {this.state.viewMode === 0 ? (
              <div className="mg-t-0 mg-b-0">
                <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">Brand</label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedCustomer ?? []}
                      options={this.state.customerList}
                      isSearchable={true}
                      onChange={(value) => this.handleChangeCustomer(value)}
                      defaultValue={this.state.selectedCustomer}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                    {this.state.errors.customer_slug ? (
                      <div className="text-danger">
                        {this.state.errors.customer_slug}
                      </div>
                    ) : (
                      this.validator.message(
                        "brand",
                        this.state.customer_slug,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Property
                    </label>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="property_name"
                      value={this.state.property_name ?? ""}
                      maxLength="191"
                    />
                    {this.state.errors.property_name ? (
                      <div className="text-danger">
                        {this.state.errors.property_name}
                      </div>
                    ) : (
                      this.validator.message(
                        "property_name",
                        this.state.property_name,
                        "required|max:191",
                        { className: "text-danger" }
                      )
                    )}
                  </div>

                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Property ID
                    </label>
                    <Form.Control
                      type="text"
                      tabIndex="2"
                      autoComplete="off"
                      onChange={this.changeHandler}
                      name="property_uuid"
                      value={this.state.property_uuid ?? ""}
                      maxLength="191"
                    />
                    {this.state.errors.property_uuid ? (
                      <div className="text-danger">
                        {this.state.errors.property_uuid}
                      </div>
                    ) : (
                      this.validator.message(
                        "property id",
                        this.state.property_uuid,
                        "required|max:191",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </div>

                <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Street Address 1
                    </label>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="street_1"
                      value={this.state.street_1 ?? ""}
                      maxLength="50"
                    />
                    {this.state.errors.street_1 ? (
                      <div className="text-danger">
                        {this.state.errors.street_1}
                      </div>
                    ) : (
                      this.validator.message(
                        "Street Address 1",
                        this.state.street_1,
                        "required|max:50",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Street Address 2 (Optional)
                    </label>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="street_2"
                      value={this.state.street_2 ?? ""}
                      maxLength="50"
                    />
                  </div>
                </div>
                <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Country
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedCountry ?? []}
                      options={this.state.countryList}
                      isSearchable={true}
                      onChange={(value) => this.handleChangeCountry(value)}
                      defaultValue={this.state.selectedCountry}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                    {this.state.errors.country_slug ? (
                      <div className="text-danger">
                        {this.state.errors.country_slug}
                      </div>
                    ) : (
                      this.validator.message(
                        "country",
                        this.state.country_slug,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">State</label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedState ?? []}
                      options={this.state.stateList}
                      isSearchable={true}
                      onChange={(value) => this.handleChangeState(value)}
                      defaultValue={this.state.selectedState}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                    {this.state.errors.state_slug ? (
                      <div className="text-danger">
                        {this.state.errors.state_slug}
                      </div>
                    ) : (
                      this.validator.message(
                        "state",
                        this.state.state_slug,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </div>
                <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">City</label>
                    <Form.Control
                      onKeyPress={global.onKeyPressEvent.alphaSpaceValidation}
                      type="text"
                      onChange={this.changeHandler}
                      name="city"
                      value={this.state.city ?? ""}
                      maxLength="50"
                    />
                    {this.state.errors.city ? (
                      <div className="text-danger">
                        {this.state.errors.city}
                      </div>
                    ) : (
                      this.validator.message(
                        "city",
                        this.state.city,
                        "required|max:50",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Zip code
                    </label>
                    <Form.Control
                      type="text"
                      onChange={this.changeHandler}
                      name="zip_code"
                      value={this.state.zip_code ?? ""}
                      maxLength="10"
                    />
                    {this.state.errors.zip_code ? (
                      <div className="text-danger">
                        {this.state.errors.zip_code}
                      </div>
                    ) : (
                      this.validator.message(
                        "zip_code",
                        this.state.zip_code,
                        "required|max:50",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                </div>

                <div className="row align-items-center flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                    <label className="form-label text-left mg-b-5">
                      Timezone
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedTimezone ?? []}
                      options={this.state.timezoneList}
                      isSearchable={true}
                      onChange={(value) => this.handleChangeTimeZone(value)}
                      defaultValue={this.state.selectedTimezone}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                    {this.state.errors.timezone_slug ? (
                      <div className="text-danger">
                        {this.state.errors.timezone_slug}
                      </div>
                    ) : (
                      this.validator.message(
                        "timezone",
                        this.state.timezone_slug,
                        "required",
                        { className: "text-danger" }
                      )
                    )}
                  </div>
                  {global.userPermissions.checkPermission(
                    "property-update"
                  ) && (
                    <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70 d-flex align-items-center justify-content-start">
                      <label className="form-label mg-b-0">
                        {this.state.status_id == 1 ? "Active" : "Inactive"}{" "}
                      </label>
                      <div
                        className={
                          this.state.status_id == 1
                            ? "az-toggle on hand-cursor"
                            : "az-toggle hand-cursor"
                        }
                        onClick={() =>
                          this.setState({
                            status_id: !this.state.status_id,
                          })
                        }
                      >
                        <span></span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                  <div className="col-md-12 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                    {global.userPermissions.checkPermission(
                      "property-update"
                    ) && (
                      <div className="d-flex align-items-center">
                        <Link to="/properties">
                          <button
                            className="gf-btn-lg-outline mg-r-7"
                            type="button"
                          >
                            Cancel
                          </button>
                        </Link>
                        <button
                          className="gf-btn-lg-stroke mg-l-2"
                          type="button"
                          onClick={this.saveOrUpdateProperty}
                        >
                          {this.state.isSubmit
                            ? global.loader
                            : this.state.slug !== undefined
                            ? "Update"
                            : "Update"}
                        </button>
                      </div>
                    )}
                    &emsp;
                    {global.userPermissions.checkPermission(
                      "property-delete"
                    ) &&
                      (this.state.propertySlug !== undefined ? (
                        <button
                          type="button"
                          onClick={() => {
                            this.opendeletePropertyModal(
                              this.state.propertySlug
                            );
                          }}
                          className="detlet-text mg-r-20"
                        >
                          Delete Property
                        </button>
                      ) : (
                        ""
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </main>
        <Modal show={this.state.deleteUserModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Property?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this property? because property
                related inspection and audit also be deleted.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeleteModel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteProperties(
                      this.state.propertySlug,
                      this.state.delete_status_true
                    )
                  }
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deletePropertyModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Property?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this property? This process cannot
                be undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeDeletePropertyModel()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() =>
                    this.deleteProperties(
                      this.state.propertySlug,
                      this.state.delete_status_false
                    )
                  }
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Basic;
