////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Action item card                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";

export class ActionItemStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userData: global.userData ? global.userData : {},
      actionItemPieData: props.actionItemPieData || {
        datasets: [{ data: [0, 0, 0] }],
      },
      doughnutPieOptionsForAction: this.props.doughnutPieOptionsForAction
        ? this.props.doughnutPieOptionsForAction
        : [],
    };
  }

  render() {
    const { actionItemPieData } = this.state;
    const hasData =
      actionItemPieData.datasets && actionItemPieData.datasets.length > 0;

    const pending = hasData ? actionItemPieData.datasets[0].data[0] : 0;
    const overdue = hasData ? actionItemPieData.datasets[0].data[1] : 0;
    const completed = hasData ? actionItemPieData.datasets[0].data[2] : 0;
    return (
      <>
      <div className="card h-chart_1 ">
        <div className="card-header">
        <h6 className="card-title tx-14 mg-b-5 dash_head">
          {" "}
          Action Items Status{" "}
          <Link to="/action-item" className="btn_view">
          View More
          </Link>
        </h6>
        </div>
        <div className="card-body row pd-25">
        <div className="col-sm-12 col-md-12 donut_mro_box">
          <div className="chartjs-size-monitor">
          <div className="chartjs-size-monitor-expand">
            <div className=""></div>
          </div>
          <div className="chartjs-size-monitor-shrink">
            <div className=""></div>
          </div>
          </div>
          <Doughnut
          data={this.state.actionItemPieData}
          options={this.state.doughnutPieOptionsForAction}
          height={150}
          />
        </div>
          <div className="col-sm-12 col-md-12 mg-t-30 mg-sm-t-0">
          <ul className="list-unstyled">
            {pending > 0 && (
            <li className="d-flex align-items-center mg-t-5">
              <span className="d-inline-block wd-10 ht-10 bg-gray-500 mg-r-10"></span>{" "}
              Pending: {pending}
            </li>
            )}
            {overdue > 0 && (
            <li className="d-flex align-items-center mg-t-5">
              <span className="d-inline-block wd-10 ht-10 bg-overdue mg-r-10"></span>{" "}
              Overdue: {overdue}
            </li>
            )}
            {completed > 0 && (
            <li className="d-flex align-items-center mg-t-5">
              <span className="d-inline-block wd-10 ht-10  bg-complete-cust  mg-r-10"></span>{" "}
              Completed: {completed}
            </li>
            )}
          </ul>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default ActionItemStatus;
