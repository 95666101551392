/*
 * Program: MroCategoryService.js
 * Application: MroCategory Service
 * Option: Used for MroCategory Service Information
 * Developer: Chandra Shekhar
 * Date: 2022-04-01
 */
import ApiService from "./ApiService";

class MroCategoryService {
  getMroCategoryList(queryString) {
    let endPoint = "mroCategory" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getMroCategory(slug) {
    let endPoint = "mroCategory/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createMroCategory(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "mroCategory";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateMroCategory(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "mroCategory/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteMroCategory(slug) {
    let endPoint = "mroCategory/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  uploadCustomerLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "customer-update-logo";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new MroCategoryService();
