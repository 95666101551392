////////////////////////////////////////////////////////////
//     							                          //
//  Program: AuthenticationService.js                     //
//  Application: Services                                 //
//  Option: Used for authentication components            //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from './ApiService'

class AuthenticationService {

    signin(data) {
        let endPoint = 'login';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    forgotPass(data){
        let endPoint = 'forgot-password';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    resetPassword(data){
        let endPoint = 'reset-forgot-password';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    changePassword(data){
        let endPoint = 'change-password';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getProfile() {
        let endPoint = 'get-profile';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    updateProfile(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'update-profile';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.putCall(inputData);
        return response;
    }

    uploadProfileImage(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = 'upload-profile-image';
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    getSetting() {
        let endPoint = 'setting';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.getCall(inputData);
        return response;
    }

    logout() {
        let endPoint = 'logout';
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
                'endPoint': endPoint,
                'addditionalHeaderData': addditionalHeaderData,
                'postData': postData
            }
        let response = ApiService.postCall(inputData);
        return response;
    }

    //for role permission
    // getRoles(queryString) {
    //     let endPoint = 'role' + (queryString ? '?' + queryString : '');
    //     let addditionalHeaderData = {};
    //     let postData = {};

    //     let inputData = {
    //             'endPoint': endPoint,
    //             'addditionalHeaderData': addditionalHeaderData,
    //             'postData': postData
    //         }
    //     let response = ApiService.getCall(inputData);
    //     return response;
    // }

    // getPermissionList(queryString) {
    //     let endPoint = 'get-module-permission' + (queryString ? '?' + queryString : '');
    //     let addditionalHeaderData = {};
    //     let postData = {};

    //     let inputData = {
    //             'endPoint': endPoint,
    //             'addditionalHeaderData': addditionalHeaderData,
    //             'postData': postData
    //         }
    //     let response = ApiService.getCall(inputData);
    //     return response;
    // }

    // getRolePermissionList(slug,queryString) {
    //     let endPoint = 'role/' + slug + (queryString ? '?' + queryString : '');
    //     let addditionalHeaderData = {};
    //     let postData = {};

    //     let inputData = {
    //             'endPoint': endPoint,
    //             'addditionalHeaderData': addditionalHeaderData,
    //             'postData': postData
    //         }
    //     let response = ApiService.getCall(inputData);
    //     return response;
    // }

    // addRolePermission(data) {
    //     // Delete data which we don't want to send
    //     delete data.errors;
    //     delete data.input;

    //     let endPoint = 'add-role-with-permission';
    //     let addditionalHeaderData = {};
    //     let postData = data;

    //     let inputData = {
    //             'endPoint': endPoint,
    //             'addditionalHeaderData': addditionalHeaderData,
    //             'postData': postData
    //         }
    //     let response = ApiService.postCall(inputData);
    //     return response;
    // }

    // updateRolePermission(slug,data) {
    //     // Delete data which we don't want to send
    //     delete data.errors;
    //     delete data.input;

    //     let endPoint = 'update-role-with-permission/'+slug;
    //     let addditionalHeaderData = {};
    //     let postData = data;

    //     let inputData = {
    //             'endPoint': endPoint,
    //             'addditionalHeaderData': addditionalHeaderData,
    //             'postData': postData
    //         }
    //     let response = ApiService.putCall(inputData);
    //     return response;
    // }

    // deleteRoles(slug) {
    //     let endPoint = "delete-role-with-permission/" + slug;
    //     let addditionalHeaderData = {};
    //     let postData = {};
    
    //     let inputData = {
    //       endPoint: endPoint,
    //       addditionalHeaderData: addditionalHeaderData,
    //       postData: postData,
    //     };
    //     let response = ApiService.deleteCall(inputData);
    //     return response;
    //   }
    
}

export default new AuthenticationService()