//     							                          //            
//  Program: Alert.jsx                                    //
//  Application: Notification                             //
//  Option: common component for Toast message            //
//  Developer: NP  						                  //
//  Date: 2021-01-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from 'react'
import renderHTML from 'react-render-html'
import {Alert} from 'react-bootstrap'

class Alerts extends Component {
    constructor(props) {
        super(props);
}

render() {
    return (
        <>
         {this.props.type === 'success' ? 
        <Alert show={this.props.show} variant="success">
            <strong className="mr-1"><i className="far fa-check-circle"></i></strong> { this.props.message ? renderHTML(this.props.message) : '' }
        </Alert>
        : ''}
        {this.props.type === 'error' ? 
        <Alert show={this.props.show} variant="danger">
            <strong  className="mr-1"><i className="far fa-times-circle"></i></strong> { this.props.message ? renderHTML(this.props.message) : '' }
        </Alert>
        : ''}
        </>
       
    )
  }
}
export default Alerts