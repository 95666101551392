import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import QuickLinkService from "../../services/QuickLinkService";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

export class QuickLinkManage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            is_inactive: false,
            tableQueryString: "",
            customQueryString: "",
            showFilter: global.showFilter,
            input: {},
            showAlertModal: false,
            alertModalType: "",
            alertModalTitle: "",
            alertModalMessage: "",
            filter_value: "",
            loading: false,
            showSpinner: false,
            total_record: 0,
            records: [],
            config: {
                sort: {
                    column: "title",
                    order: "asc",
                },
                key_column: "slug",
                page_size: global.page_size,
                length_menu: global.page_length_menu,
                show_length_menu: true,
                show_filter: global.show_filter,
                show_pagination: global.show_pagination,
                pagination: "advance",
                button: {
                    excel: global.excel_button,
                    print: global.print_button,
                    extra: false,
                },
                language: {
                    no_data_text: "No record found",
                    filter: "Search by title",
                },
            },

            extraButtons: [],
            columns: [
                {
                    key: "title",
                    text: "Title",
                    className: "customer_logo hand-cursor",
                    sortable: true,
                    cell: (record) => {
                        return record && record.title ? record.title : "";
                    },
                },
                {
                    key: "description",
                    text: "Description",
                    className: "customer_logo hand-cursor",
                    sortable: false,
                    cell: (record) => {
                        return record && record.description ? record.description : "";
                    },
                },
                {
                    key: "url",
                    text: "URL",
                    className: "customer_name hand-cursor",
                    sortable: false,
                    cell: (record) => {
                        return record && record.url ? record.url : '';
                        // if(global.userData.role_id === 1 || global.userData.role_id === 4 || global.userData.role_id === 6){
                        //     return (<a  rel="noreferrer" href="javascript:void(0);">{url}</a>);
                        // }else{
                        // return url;
                        // }
                    },
                },
                {
                    key: "sort_order",
                    text: "Sort Order",
                    className: "customer_name hand-cursor",
                    sortable: true,
                    cell: (record) => {
                        return record && record.sort_order ? record.sort_order : '';
                    },
                },
                {
                    key: "status_id",
                    text: "Status",
                    className: "status_id hand-cursor",
                    sortable: true,
                    cell: (record) => {
                        return record && record.status_id === 1 ? (
                            <b className="status-active">Active</b>
                        ) : (
                            <b className="status-inactive">Inactive</b>
                        );
                    },
                },
            ],
            isFirstLoad: true,
            lastQueryString:"",
        };
        this.closeModal = this.closeModal.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.filterToggle = this.filterToggle.bind(this);
        this.resetDate = this.resetDate.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData(queryString = "") {
        if (this.state.isFirstLoad) {
            this.setState({ loading: true, isFirstLoad: false });
        }
        var list = [];
        var totalRecords = 0;
        let res = await QuickLinkService.getQuickLinkList(queryString);
        if (global.successStatus.includes(res.status)) {
            list = res.data.data ? res.data.data : [];
            totalRecords = res.data.total ? res.data.total : 0;
        }
        this.setState({
            loading: false,
            total_record: totalRecords,
            records: list,
        });
    }

    customFilter() {
        let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
        this.setState({ lastQueryString: queryString });
        this.setState({ tableQueryString: "", customQueryString: queryString });
        this.getData(queryString);
    }

    resetDate() {
        this.setState({ start_date: "", lastQueryString:"" });
        this.setState({ end_date: "" });
        this.getData();
    }

    filterToggle = () => {
        if (this.state.showFilter === 1) {
            this.setState({ showFilter: 0 });
        } else {
            this.setState({ showFilter: 1 });
            document.body.classList.add('bdy_over');
        }
    };

    closeModal() {
        this.setState({ showAlertModal: false });
    }

    changeHandler = (event) => {
        let input = this.state.input;
        let value = event.target.value;
        input[event.target.name] = value;
        this.setState({ [event.target.name]: value, input: input });

        // Validate
        this.validator.showMessageFor(event.target.name);
        this.customValidate();
    };

    tableChangeHandler = (data) => {
      
        // Function to create query parameters
        const createQueryParams = (data) => {
          const params = new URLSearchParams();
          Object.keys(data).forEach((key) => {
            if (key === "sort_order" && data[key]) {
              params.append("sort_order", data[key].order);
              params.append("sort_column", data[key].column);
            } else {
              let newKey = key;
              if (key === "page_number") {
                newKey = "page";
              } else if (key === "sort_order") {
                  data[key] = "asc";
                } else if (key === "page_size") {
                  newKey = "per_page";
                }
              params.append(newKey, data[key]);
            }
          });
          
          return params.toString();
        };
        
        const pageSize = Number(data.page_size);
        const validPageSizes = [10,20, 50, 100];
    
        let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);
    
        if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
          data.page_number = 1; 
        }
        let queryString = createQueryParams(data);
      
        if (data.filter_value === "") {
          queryString += `&${this.state.customQueryString}`;
        }
      
        this.setState({ tableQueryString: queryString });
      
        if (data.filter_value !== this.state.filter_value) {  
          clearTimeout(this.state.filter_time);
          this.setState({
            filter_time: setTimeout(
              () => this.getData(queryString),
              global.filter_time
            ),
            filter_value: data.filter_value,
          });
        } else {
          if (this.state.firstLoad === true) {
            queryString = new URLSearchParams({
              page: data.page_number,
              page_size: data.page_size,
              sort_order: data.sort_order.order,
              sort_column: data.sort_order.column,
            }).toString();
          }
      
          this.getData(`${queryString}${this.state.lastQueryString}`);
        }
      };

    rowClickedHandler = (event, data, rowIndex) => {
        this.props.history.push("/quick-links/update/" + data.slug);
        // let URL =  data && data.url ? data.url : '';
        // window.open(URL, '_blank');
    };

    customValidate() {
        let isValid = true;
        return isValid;
    }

    render() {
        return (
            <div>
                <main>
                    <div className="container-fluid">
                        <div className="row row-xs">
                            <div className="col-md-12">
                                <div className="brandSection">
                                    <div className="dashbaord-pageHeadertitle ">
                                        <h3 className="flex-column">
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <h3>Quick Links</h3>
                                                        <div className="btnNavigate d-flex justify-content-end">
                                                            {global.userPermissions.checkPermission("manage-link-add") && (
                                                            <Link to='/quick-links/add'>
                                                                <button className="gf-primary-btn-small-one">
                                                                    Add Quick Link
                                                                </button>
                                                            </Link> )}
                       
                                                        </div>
                                
                                            </div>
                                            <span>All Quick Links</span>
                                        </h3>
                                    </div>

                                    {this.state.showFilter ? (
                                        <Row className="align-items-center order_form">
                                            <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                                                <input
                                                    name="filter_value"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Filter Value"
                                                    value={this.state.filter_value}
                                                    onChange={this.changeHandler}
                                                    maxLength="30"
                                                />{" "}
                                            </Col>
                                            <Col md={5} lg={5} xl={5}>
                                                <Row>
                                                    <button
                                                        onClick={this.customFilter}
                                                        className="btn-success mg-l-0 mg-r-20"
                                                    >
                                                        Search
                                                    </button>
                                                    <button
                                                        onClick={this.resetDate}
                                                        className="btn-success-outline"
                                                    >
                                                        Reset
                                                    </button>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="mg-tneg-4 brandList dashboardRightcard">
                            <ReactDatatable
                                className="table no-wrap mg-b-30 pd-b-2 customerTable"
                                config={this.state.config}
                                records={this.state.records}
                                columns={this.state.columns}
                                extraButtons={this.state.extraButtons}
                                dynamic={true}
                                loading={this.state.loading}
                                total_record={this.state.total_record}
                                onChange={this.tableChangeHandler}
                                onRowClicked={this.rowClickedHandler}
                            />
                        </div>
                    </div>
                </main>
            </div>
        );
    }

}
export default QuickLinkManage;