import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import defaultImage from "./../../assets/images/default.png";
import siteLogo from "./../../assets/images/GFBOT-Horizontal.png";
//import { Dropdown } from 'react-bootstrap';
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // headerToken: localStorage.getItem("headerToken"),
      headerToken: global.headerToken ? global.headerToken : "",
      userData: global.userData ? global.userData : {},

      profileImage:
        localStorage.getItem("userData") !== null &&
        localStorage.getItem("userData") !== undefined
          ? JSON.parse(localStorage.getItem("userData")).profile_thumb_url
          : defaultImage,

      showSpinner: true,
      customSpinner: parseInt(localStorage.getItem("customSpinnerSession"))
        ? false
        : true,
      loading: false,
      first_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
    };
  }

  async componentDidMount() {
    this.setState({ showSpinner: true, loading: true });
    // Get user profile and set into localstorage
    // if (localStorage.getItem("isLogin") === "1") {
    //   localStorage.removeItem("isLogin");
    //   window.location.reload(false);
    // }
    this.setState({ showSpinner: false, loading: false, customSpinner: false });
    localStorage.setItem("customSpinnerSession", 1);
  }

  getTitle() {
    return process.env.REACT_APP_PROJECT_TITLE
      ? process.env.REACT_APP_PROJECT_TITLE
      : "";
  }
  render() {
    // Set session expiry logic
    let sessionExpireTime = localStorage.getItem("sessionExpireTime")
      ? localStorage.getItem("sessionExpireTime")
      : 60;
    let lastSessionExpiry = localStorage.getItem("sessionExpiry")
      ? localStorage.getItem("sessionExpiry")
      : "";
    let currentTimestamp = new Date().getTime();
    if (!lastSessionExpiry && this.state.headerToken) {
      let sessionExpiry = new Date(
        currentTimestamp + sessionExpireTime * 60000
      ).getTime();
      localStorage.setItem("sessionExpiry", sessionExpiry);
    } else if (this.state.headerToken) {
      if (!isNaN(lastSessionExpiry) && lastSessionExpiry > currentTimestamp) {
        // If session expiry > current time then extend session time
        let sessionExpiry = new Date(
          currentTimestamp + sessionExpireTime * 60000
        ).getTime();
        localStorage.setItem("sessionExpiry", sessionExpiry);
      } else {
        // If session expiry < current time then logout
        return <Redirect to="/logout" />;
      }
    }

    return (
      <div className="az-header">
        <div>
          {this.state.customSpinner ? (
            <div className="customLoad">
              <div className="div1">
                <div>
                  <img
                    className="mg-b-0 customLoadimg"
                    src={require("../../assets/images/default.png").default}
                    alt=""
                  ></img>
                </div>
                <div id="load">
                  <center>
                    <div id="preload1"></div>
                  </center>
                </div>
              </div>
            </div>
          ) : this.state.showSpinner ? (
            global.spinnerLoader
          ) : (
            ""
          )}
          <div className="az-header-menu-header">
            {window.location.pathname === "/dashboard" ? (
              <span className="az-logo">
                <img className="mg-b-0" src={siteLogo} alt="Site Logo" />
              </span>
            ) : (
              <Link to={"/dashboard"} className="az-logo">
                <img className="mg-b-0" src={siteLogo} alt="Site Logo" />
              </Link>
            )}
            <a
              href="#/"
              onClick={(event) => this.toggleHeaderMenu(event)}
              className="close"
            >
              &times;
            </a>
          </div>
          <div className="az-header-menu">
            <ul className="nav">
              <h2 className="menu_heading">Dashboard</h2>
              {/* <div className="menu_line">&nbsp;</div> */}
             
                <li
                  className={
                    this.isPathActive("/dashboard")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/dashboard"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Dashboard</span>
                  </Link>
                </li>
              {global.userPermissions.checkPermission("checklist-list") && (
                <h2 className="menu_heading">inspections</h2>
              )}
              {/* <div className="menu_line">&nbsp;</div> */}
              {global.userPermissions.checkPermission("checklist-list") && (
                <li
                  className={
                    this.isPathActive("/checklists") ||
                    this.isPathActive("/checklist/import") ||
                    this.isPathActive("/checklist/edit") ||
                    this.isPathActive("/checklist/add") ||
                    this.isPathActive("/start-inspection-list")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/checklists"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Checklists</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("inspection-list") && (
                <li
                  className={
                    this.isPathActive("/inspections") ||
                    this.isPathActive("/inspection/update") ||
                    this.isPathActive("/inspection-perform")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/inspections"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Inspections</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("mros-list") && (
                <li
                  className={
                    this.isPathActive("/mros") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link to="/mros" className="nav-link nav-link-custom d-flex">
                    <span>MROs</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("daily_logs-list") && (
                <li
                  className={
                    this.isPathActive("/daily-logs") ||
                    this.isPathActive("/daily-log/add") ||
                    this.isPathActive("/daily-log/")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/daily-logs"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Daily Logs</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("tracker-list") && (
                <li
                  className={
                    this.isPathActive("/tracker")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/tracker"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Trackers</span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("audits-list") && (
                <h2 className="menu_heading">AUDITS</h2>
              )}
              {global.userPermissions.checkPermission("template-list") && (
                <li
                  className={
                    this.isPathActive("/audit-templates") ||
                    this.isPathActive("/audit-template/update") ||
                    this.isPathActive("/audit-template/add") ||
                    this.isPathActive("/audit-perform") ||
                    this.isPathActive("/start-audit-list")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/audit-templates"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Audit Templates</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("audits-list") && (
                <li
                  className={
                    this.isPathActive("/audits") ||
                    this.isPathActive("/audit-resume")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/audits"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>AUDITS </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("action_items-list") && (
                <li
                  className={
                    this.isPathActive("/action-item")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/action-item"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Action items</span>
                  </Link>
                </li>
              )}

              {global.userPermissions.checkPermission("quick-link-list") && (
                <>
                  <h2 className="menu_heading">Setting</h2>
                  <li
                    className={
                      this.isPathActive("/all-quick-links")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <Link
                      to="/all-quick-links"
                      className="nav-link nav-link-custom d-flex"
                    >
                      <span>Quick Links </span>
                    </Link>
                  </li>
                </>
              )}

              <h2 className="menu_heading">MASTER DATA</h2>
              {global.userPermissions.checkPermission("brand-list") && (
                <li
                  className={
                    this.isPathActive("/brands")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/brands"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Brands </span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("property-list") && (
                <li
                  className={
                    this.isPathActive("/properties")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/properties"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Properties</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission(
                "checklist-templates-list"
              ) && (
                <li
                  className={
                    this.isPathActive("/templates")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/templates"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>INSPECTION TEMPLATES</span>
                  </Link>
                </li>
              )}
              
              {global.userPermissions.checkPermission("manage-link-list") && (
                <li
                  className={
                    this.isPathActive("/quick-links")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link
                    to="/quick-links"
                    className="nav-link nav-link-custom d-flex"
                  >
                    <span>Manage Links</span>
                  </Link>
                </li>
              )}
              {global.userPermissions.checkPermission("user-list") && (
                <li
                  className={
                    this.isPathActive("/user") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link to="/user" className="nav-link nav-link-custom d-flex">
                    <span>Users</span>
                  </Link>
                </li>
              )}

              {global.roleTypeId === 1 ? (
                <li
                  className={
                    this.isPathActive("/roles") ||
                    this.isPathActive("/role-permission/")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/roles" className="nav-link nav-link-custom d-flex">
                    <span>Roles</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li
                className={
                  this.isPathActive("/update-profile")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  to="/update-profile"
                  className="nav-link nav-link-custom d-flex"
                >
                  <span>My Account</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(Header);
