import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <div>
        {/* <div className="az-footer ht-40">
          <div className="container ht-100p pd-t-0-f">
            <span>&copy; { current_year } Harper Bernays Limited</span>
          </div>
        </div> */}
      </div>
    )
  }
}

export default Footer
