////////////////////////////////////////////////////////////
//     							                                      //
//  Program: UpdateProfile.jsx                            //
//  Application: Update Profile                           //
//  Option: For update profile                            //
//  Developer: NP 						                            //
//  Date: 2021-12-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import Moment from "moment";
// import defaultImage from "./../../assets/images/default.png";
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";
import { Link } from "react-router-dom";
export class UpdateProfile extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      profile_thumb: "",
      imageUrl: "",
      isSubmit: false,
      role_type_name: "",
      job_title: "",
      propertyName: "",
      customerName:"",
      showMore: false,
      showMore1: false,
      propertyList: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  async componentDidMount() {
    var resData = {};
    let res = await AuthenticationService.getProfile();
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      if (res.data.property_access !== undefined) {
        let customerName = [];
        let propertyName = [];
        res.data.property_access.forEach((access, i) => {
          customerName.push(access.customer_name);
          access.property.forEach((property, j) => {
            propertyName.push(property.name);
          });
        });
        resData.customerName = customerName.toString() ? customerName.toString() : [];
        resData.propertyName = propertyName.toString() ? propertyName.toString() : [];
      }
    }
    
    this.setState(resData);
  }

  updateProfile = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone: this.state.phone,
        profile_thumb: this.state.profile_thumb,
        role_type_name: this.state.role_type_name,
        job_title: this.state.job_title,
      };
      let res = await AuthenticationService.updateProfile(inputData);
      if (global.successStatus.includes(res.status)) {
        let resdata = await AuthenticationService.getProfile();
        if (global.successStatus.includes(resdata.status)) {
          var resData = resdata.data ? resdata.data : {};
          localStorage.setItem("userData", JSON.stringify(resData));
          // this.props.imageUpload(
          //   resData.profile_thumb_url ? resData.profile_thumb_url : defaultImage
          // );
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
      } else {
        let alertMessage = "";
        if (
          res.data.errorCode === "validationFailed" &&
          res.data.errorDetail !== null
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.errorMessage,
        });
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    if (event.target.type === "date" && event.target.value !== null) {
      event.target.value = Moment(event.target.value, "DD-MM-YYYY").toDate();
    }
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    if (event.target.name === "phone") {
      let number = event.target.value;
      if (!Number(number) && number) {
        return;
      }
      this.setState({
        [event.target.name]: number,
      });
    }
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };



  // Custom validation rules
  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }
  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };
  toggleShowMore1 = () => {
    this.setState({ showMore1: !this.state.showMore1 });
  };
  render() {
  const { propertyName, customerName, showMore, showMore1 } = this.state;
  const propertyListArray = propertyName.split(",");
  const firstPropertyName = propertyListArray[0];
  const remainingProperties = propertyListArray.slice(1);
  const remains = remainingProperties.length;

  const customerListArray = customerName.split(",");
  const firstCustomerName = customerListArray[0];
  const remainingProperties1 = customerListArray.slice(1);
  const remains1 = remainingProperties1.length;

    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="container-fluid p-0">
              <div className="col-md-12 p-0">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>
                          {localStorage.getItem("userData")
                            ? JSON.parse(localStorage.getItem("userData"))
                                .full_name
                            : ""}
                        </h3>
                        <Link to="/dashboard">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                      <span> {global.roleTypeName}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>

              <div className="col-md-12 p-5 dashboardRightcard lg-mt-50">
                <div className="tabNavigation">
                  <form id="register_from">
                    <div className="m-0">
                      <div className="">
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          {/* first name */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              First Name
                            </label>
                            <input
                              name="first_name"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.first_name}
                              onChange={this.changeHandler}
                              maxLength="50"
                              onKeyPress={
                                global.onKeyPressEvent.alphaSpaceValidation
                              }
                            />

                            {this.state.errors.first_name ? (
                              <div className="valid_info">
                                {this.state.errors.first_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "first_name",
                                this.state.first_name,
                                "required|max:50",
                                { className: "valid_info" }
                              )
                            )}
                          </div>
                          {/* Last name */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Last Name
                            </label>
                            <input
                              name="last_name"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.last_name}
                              onChange={this.changeHandler}
                              maxLength="50"
                              onKeyPress={
                                global.onKeyPressEvent.alphaSpaceValidation
                              }
                            />

                            {this.state.errors.last_name ? (
                              <div className="valid_info">
                                {this.state.errors.last_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "last_name",
                                this.state.last_name,
                                "required|max:30",
                                { className: "valid_info" }
                              )
                            )}
                          </div>
                          {/* Email */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Email
                            </label>
                            <input
                              name="email"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.email}
                              onChange={this.changeHandler}
                              maxLength="100"
                              readOnly
                            />

                            {this.state.errors.email ? (
                              <div className="valid_info">
                                {this.state.errors.email}
                              </div>
                            ) : (
                              this.validator.message(
                                "email",
                                this.state.email,
                                "required|max:100",
                                { className: "valid_info" }
                              )
                            )}
                          </div>
                          {/* Phone */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Phone
                            </label>
                            <input
                              name="phone"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.phone}
                              // onChange={this.onHandleChangeNumeric}
                              onChange={this.changeHandler}
                              maxLength="12"
                            />

                            {this.state.errors.phone ? (
                              <div className="valid_info">
                                {this.state.errors.phone}
                              </div>
                            ) : (
                              this.validator.message(
                                "phone",
                                this.state.phone,
                                "required|phone",
                                { className: "valid_info" }
                              )
                            )}
                          </div>
                           {/* Job Title */}
                           <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Job Title
                            </label>
                            <input
                              name="job_title"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.job_title}
                              // onChange={this.onHandleChangeNumeric}
                              onChange={this.changeHandler}
                              maxLength="12"
                            />

                            {this.state.errors.job_title ? (
                              <div className="valid_info">
                                {this.state.errors.job_title}
                              </div>
                            ) : (
                              this.validator.message(
                                "job_title",
                                this.state.job_title,
                                "required",
                                { className: "valid_info" }
                              )
                            )}
                          </div>
                          {/* Role */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Role
                            </label>
                            <input
                              name="role"
                              className="form-control"
                              autoComplete="off"
                              value={global.roleTypeName}
                              onChange={this.changeHandler}
                              readOnly
                            />
                          </div>
                        
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                            <strong>Access Details  </strong>
                            </label>
                              <p><strong>Customer Name: </strong> 
                              {firstCustomerName+","}
                              {remainingProperties1.length > 0 && !showMore1 && (
                                <span onClick={this.toggleShowMore1}>
                                  <b>{" +"+remains1+" "}More</b>
                                </span>
                              )}
                              {showMore1 && (
                                <>
                                  {remainingProperties1.map((property, index) => (
                                    <span key={index}>{property},</span>
                                  ))}
                                  <span onClick={this.toggleShowMore1}>
                                    <b>{" "}Show Less</b>
                                  </span>
                                </>
                              )}
                              </p>
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <span>
                              <strong>Property Name: </strong>
                              {firstPropertyName+","}
                              {remainingProperties.length > 0 && !showMore && (
                                <span onClick={this.toggleShowMore}>
                                  <b>{" +"+remains+" "}More</b>
                                </span>
                              )}
                              {showMore && (
                                <>
                                  {remainingProperties.map((property, index) => (
                                    <span key={index}>{property},</span>
                                  ))}
                                  <span onClick={this.toggleShowMore}>
                                    <b>{" "}Show Less</b>
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                          <div className="col-md-6 btnNavigate d-flex align-items-center flex-wrap-bothBtn p-0">
                            <a href="/logout" className="detlet-text mg-r-20">
                              <span>LOGOUT</span>
                            </a>
                            <span className="divider-login">| &emsp;</span>
                            <Link
                              to="/change-password"
                              className="detlet-text mg-r-20"
                            >
                              <span>CHANGE PASSWORD </span>
                            </Link>
                            <span className="divider-login">| &emsp;</span>
                            <Link to='/update-profile'
                              className="detlet-text mg-r-20 cust_pb_10"
                              onClick={() => window.location = 'mailto:gfbotsupport@gfhotels.com'}>
                              Contact Us
                            </Link>
                          </div>
                          <div className="col-md-6 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                            <div className="d-flex align-items-center">
                              <Link to="/dashboard">
                                <button
                                  className="gf-btn-lg-outline mg-r-7"
                                  type="button"
                                >
                                  Cancel
                                </button>
                              </Link>
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                onClick={this.updateProfile}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit ? global.loader : "Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default UpdateProfile;
