
import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import QuickLinkService from "../../services/QuickLinkService";
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import CustomerService from "../../services/CustomerService";
import placeholderImage from "./../../assets/images/image-placeholder.png";
import FileWidget from "../fileUploader/FileWidget";

export class QuickLinkForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      title: "",
      url: "",
      description: "",
      status_id: true,
      sort_order: "",
      selectedRoleList: [],
      roleList: [],
      selectedPropertyList: [],
      propertyList: [],
      selectedBrandList: [],
      quickLinkLogo: [],
      customerList: [],
      selectedCustomerList: [],
      deletedFileList: [],
      uploadImageURL: ""
    };
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateQuickLink = this.saveOrUpdateQuickLink.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.deleteQuickLinks = this.deleteQuickLinks.bind(this);
    this.opendeleteQuickLinksModal = this.opendeleteQuickLinksModal.bind(this);
    this.getCustomerList = this.getCustomerList.bind(this);
    this.getQuickLinkData = this.getQuickLinkData.bind(this);
  }

  getRoleList() {
    const roleList = global.selectRoleList;
    let roles = [];
    if (roleList != null && roleList.length > 0) {
      roleList.forEach((item, i) => {
        if (global.roleTypeId !== 1) {
          if (item.value > global.roleTypeId) {
            roles.push({
              value: item.value,
              label: item.name,
            });
          }
        } else {
          roles.push({
            value: item.value,
            label: item.name,
          });
        }
      });
    }
    this.setState({ selectRoleList: roles });
  }

  // Get Customer List
  async getCustomerList(queryString = "") {
    this.setState({ loading: true });
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    this.setState({
      customerList: customerList,
      allSelectedCustomers: customerList,
    });
    customerList.forEach((item) => {
      customerListData.push({
        label: item.customer_name,
        value: item.slug,
        valuep: item.slug,
      });
    });
    this.setState({ customerList: customerListData });
    this.setState({ loading: false });
  }

  handleChangeRole(value) {

    let role_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        role_id.push(item.value);
      });
    }

    /* use to hide show the dropdown list end*/
    this.setState({
      selectedRoleList: value,
      role_id: value != null ? value.value : null,
    });

  }

  handleChangeCustomerList(value) {
    let customer_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        customer_slug.push(item.value);
      });
    }
    this.setState({
      selectedCustomerList: value,
      customer_slug: value != null ? value.value : null,
    });

    customer_slug != null && this.getPropertyList(customer_slug, "");
  }

  async getPropertyList(customerSlug = "", queryString = "",) {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    var res = ""
    if (customerSlug != "") {
      this.setState({ selectedPropertyList: [] });
      res = await PropertyService.getPropertyByCustomer(
        "",
        (queryString = "customer_slug=" + customerSlug)
      );

    } else {
      res = await PropertyService.getPropertyList(
        "is_dropdown=1" + (queryString ? "&" + queryString : "")
      );
    }

    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });


    this.setState({ propertyList: propertyListData });
    this.setState({ loading: false });
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.getPropertyList();
    await this.getRoleList();
    await this.getCustomerList();
    await this.getQuickLinkData();

    this.setState({ loading: false });
  }

  async getQuickLinkData() {
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await QuickLinkService.Show(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.title = res.data.title;
        resData.url = res.data.url;
        resData.imageUrl = res.data.quickLinkLogo[0]?.uri != null ? res.data.quickLinkLogo[0].uri : null;
        resData.status_id = res.data.status_id === 1 ? true : false;
        resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
        resData.roles = res.data.roles ? res.data.roles : "";
        resData.brands = res.data.brands ? res.data.brands : "";
        resData.properties = res.data.properties ? res.data.properties : "";
        this.setState(resData);
      }

      var roles = [];
      var properties = [];
      var brands = [];

      res.data.roles.forEach((globalRoleList) => {
        global.selectRoleList.forEach((responseRoleList) => {
          if (globalRoleList == responseRoleList.value) {
            roles.push({
              value: responseRoleList.value,
              label: responseRoleList.name,
            });
          }
        })
      });

      if (
        res.data.brands.length > 0 &&
        this.state.customerList &&
        this.state.customerList.length > 0
      ) {
        res.data.brands.forEach((globalCustomerList) => {
          this.state.customerList.forEach((responseCustomerList) => {
            if (globalCustomerList == responseCustomerList.value) {
              brands.push(responseCustomerList);
            }
          });
        });
      }


      if (
        res.data.properties.length > 0 &&
        this.state.propertyList &&
        this.state.propertyList.length > 0
      ) {
        res.data.properties.forEach((globalpropertyList) => {
          this.state.propertyList.forEach((responsepropertiesList) => {
            if (globalpropertyList == responsepropertiesList.value) {
              properties.push(responsepropertiesList);
            }
          });
        });
      }

      this.setState({ selectedRoleList: roles });
      this.setState({ selectedCustomerList: brands });
      this.setState({ selectedPropertyList: properties });

      this.defaultData = resData;
    }
  }

  // Delete Logo Start
  async deleteLogo(url) {
    console.log(url);
    const fileName = url.split('/');
    console.log(fileName);
    this.setState({ loading: true, isSubmit: true });
    let res = await QuickLinkService.destroyQuickLinkLogo(fileName[4] !== "undefine" ? fileName[4] :  fileName[5]);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getQuickLinkData();
      //setTimeout(() => this.props.history.push("/mros"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete Logo End

  saveOrUpdateQuickLink = async (e) => {
    e.preventDefault();

    const FileWidgetObj = new FileWidget();
    this.setState({ loading: true, isSubmit: true });
    const output = await FileWidgetObj.fileUploadFun("customer_form");
    let inputFilesData = [];
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          this.setState({ uploadImageURL: output.response[0].filesData[0].url })

          inputFilesData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };
        }
      }
      if (this.validator.allValid()) {
        this.setState({ loader: true, isSubmit: true });


        const roleIds = this.state.selectedRoleList && this.state.selectedRoleList.length > 0
          ? this.state.selectedRoleList.map(data => data.value)
          : [];

        const brandIds = this.state.selectedCustomerList && this.state.selectedCustomerList.length > 0
          ? this.state.selectedCustomerList.map(data => data.value)
          : [];

        const proipertyIds = this.state.selectedPropertyList && this.state.selectedPropertyList.length > 0
          ? this.state.selectedPropertyList.map(data => data.value)
          : [];


        let inputData = {
          title: this.state.title,
          url: this.state.url,
          status_id: this.state.status_id === true ? 1 : 2,
          sort_order: this.state.sort_order,
          role_slug: roleIds,
          brand_slugs: brandIds,
          property_slug: proipertyIds,
          description: this.state.description,
          logoData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
          uploadImageURL: this.state.uploadImageURL
        };

        if (this.state.slug !== undefined) {
          let res = await QuickLinkService.updateQuicklLink(
            this.state.slug,
            inputData
          );
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/quick-links/"),
              global.redirect_time
            );
          } else {
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
          }
        } else {
          let res = await QuickLinkService.store(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/quick-links"),
              global.redirect_time
            );
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        }
        this.setState({ loader: false, isSubmit: false });
        setTimeout(
          () => this.setState({ showAlertModal: false }),
          global.alert_close_time
        );
      } else {
        if (this.state.status_id !== true) {
          this.validator.showMessageFor("title");
        } else {
          this.validator.showMessages();
        }
      }

    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
        loading: false,
        isSubmit: false,
      });
      setTimeout(
        () =>
          this.setState({
            showAlertModal: false,
            loading: false,
            isSubmit: false,
          }),
        global.alert_close_time
      );
    }
    //Image Section End
  };

  opendeleteQuickLinksModal(slug) {
    this.setState({ deleteQuickLinksModal: true });
  }

  closedeleteQuickLinksModal() {
    this.setState({ deleteQuickLinksModal: false });
  }

  async deleteQuickLinks(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await QuickLinkService.destroyQuickLink(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteQuickLinksModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/quick-links"),
        global.redirect_time
      );
    } else {
      this.closedeleteQuickLinksModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_slug: value != null ? value.value : null,
    });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    // this.customValidate();
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  // customValidate() {
  //   let isValid = true;
  //   return isValid;
  // }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Quick Links";
    } else {
      return "Add Quick Links";
    }
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
              ? "black"
              : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };

    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="container-fluid p-0">
              <div className="col-md-12 p-0">
                <div className="dashbaord-pageHeadertitle heading_alignment">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>
                        {this.state.slug !== undefined
                          ? this.state.title
                            ? this.state.title
                            : this.state.title
                          : "Add Quick Links"}
                      </h3>
                      <div className="btnNavigate d-flex justify-content-end">
                        <Link to={"/quick-links"}>
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                    </div>
                    <span>
                      {this.state.slug !== undefined
                        ? 'UPDATE Quick Link'
                        : "Add Quick Links"}
                    </span>
                  </h3>
                </div>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="col-md-12 p-5 dashboardRightcard ">
                <div className="tabNavigation">
                  <form id="customer_form">
                    <div className="m-0">
                      <div className="mg-t-0 mg-b-0">
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label mg-b-5">Title</label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              onChange={this.changeHandler}
                              name="title"
                              value={this.state.title}
                              maxLength="50"
                              tabIndex="1"
                            />
                            {this.state.errors.title ? (
                              <div className="text-danger">
                                {this.state.errors.title}
                              </div>
                            ) : (
                              this.validator.message(
                                "title",
                                this.state.title,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label mg-b-5">Quick Link</label>
                            <Form.Control
                              type="url"
                              autoComplete="off"
                              onChange={this.changeHandler}
                              name="url"
                              value={this.state.url}
                              maxLength="50"
                              tabIndex="1"
                            />
                            {this.state.errors.url ? (
                              <div className="text-danger">
                                {this.state.errors.url}
                              </div>
                            ) : (
                              this.validator.message(
                                "url",
                                this.state.url,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          {/* col */}
                        </div>

                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Description
                            </label>
                            <textarea
                              type="text"
                              as="textarea"
                              onChange={this.changeHandler}
                              name="description"
                              value={this.state.description}
                              maxLength="150"
                              tabIndex="1"
                              placeholder="Type here..."
                            />
                            {this.state.errors.description ? (
                              <div className="text-danger">
                                {this.state.errors.description}
                              </div>
                            ) : (
                              this.validator.message(
                                "description",
                                this.state.description,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>


                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">

                        </div>
                        {/* Images Section Start */}
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-20 pd-r-70">
                            <label className="form-label text-left mg-b-5 pd-l-20">
                              Logo
                            </label>
                            <div className="row m-0">
                              <FileWidget
                                fieldName="Logo"
                                fieldTitle=""
                                fieldLabel=""
                                checkValidation="true"
                                allowedType="jpeg,jpg,png"
                                maxFileSize={1000}
                                maxFileSizeType="mb"
                                maxFileAllowed={1}
                                uploadOn="s3"
                                refTableId="5"
                                displayFileName=""
                                documentTypeSlug=""
                                mediaType={1}
                                sortOrder=""
                                statusId=""
                                helpText="To download sample file."
                                sampleFileUrl="/sample.jpg"
                                drag="false"
                                dragText=""
                                inputClassName="form-control"
                                labelClassName="form-label"
                                formID="customer_form"
                                uploadType="multiple"
                                resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:300,height:300,maintainAspectRatio:false,resizeDestinationFolder:thumb22"
                                destinationFolder="quickLinkLogo"
                                uploadedFiles={this.state.profile_image2}
                                deletedFiles={this.state.deletedFileList}
                                acceptOnly="image/*"
                                displayPreviewStyle="vertical"
                                newRelatedFilesDescChange={
                                  this.newRelatedFilesDescChange
                                }
                                currentFile={this.state.quickLinkLogo.length}
                              />

                              {/* Image Preview */}

                              {
                                this.state.imageUrl && this.state.imageUrl != undefined && <div className="img_close">
                                  <a
                                    href={
                                      this.state.imageUrl
                                    }
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        this.state.imageUrl
                                      }
                                      alt="Logo"
                                      className="brand_list_image"
                                      onError={({
                                        currentTarget,
                                      }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                          placeholderImage;
                                      }}
                                    />
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn_remove"
                                    onClick={() =>
                                      this.deleteLogo(this.state.imageUrl.toString())
                                    }
                                  >
                                    <span className="fa fa-times"></span>
                                  </button>
                                </div>
                              }
                            </div>
                            {/* Image Preview end*/}
                          </div>
                        </div>
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          {/* col */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label mg-b-5">Sort Order (Optional)</label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              onChange={this.changeHandler}
                              name="sort_order"
                              value={this.state.sort_order}
                              maxLength="50"
                              tabIndex="1"
                            />

                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label mg-b-5">
                              Role  (Optional)
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              isMulti={true}
                              value={this.state.selectedRoleList}
                              options={this.state.selectRoleList}
                              onChange={(value) =>
                                this.handleChangeRole(value)
                              }
                              defaultValue={this.state.selectedRoleList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </div>
                          {/* col */}
                        </div>

                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          {/* col */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label mg-b-5">
                              Brand  (Optional)
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              autoComplete="off"
                              options={this.state.customerList}
                              isMulti={true}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                              getOptionValue={(option) => `${option.label}`}
                              closeMenuOnSelect={false}
                              value={this.state.selectedCustomerList}
                              defaultValue={this.state.selectedCustomerList}
                              onChange={(value) =>
                                this.handleChangeCustomerList(value)
                              }
                            />
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label mg-b-5">
                              Property (Optional)
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              autoComplete="off"
                              options={this.state.propertyList}
                              isMulti={true}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                              getOptionValue={(option) => `${option.label}`}
                              closeMenuOnSelect={false}
                              value={this.state.selectedPropertyList}
                              defaultValue={this.state.selectedPropertyList}
                              onChange={(value) =>
                                this.handleChangePropertyList(value)
                              }
                            />
                          </div>
                          {/* col */}
                        </div>

                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          {/* col */}
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70 d-flex align-items-center justify-content-start">
                            <label className="form-label mg-b-0">
                              {this.state.status_id ? "Active" : "Inactive"}
                            </label>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() => this.changeStatus()}
                            >
                              <span></span>
                            </div>
                          </div>
                          {/* col */}
                        </div>
                        {/* col */}
                      </div>
                      <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-70 pd-r-70">
                        <div className="col-md-12 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn pd-t-30 pd-l-70 pd-r-70">
                          <div className="d-flex align-items-center">
                            
                              <Link
                                className="gf-btn-lg-outline mg-r-7"
                                to={"/quick-links"}
                              >
                                Cancel
                              </Link>                        
                            {this.state.slug == undefined && global.userPermissions.checkPermission("manage-link-add") && ( 
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                type="button"
                                onClick={this.saveOrUpdateQuickLink}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit
                                  ? global.loader
                                  : "Add"
                                }
                              </button> )}

                              { this.state.slug !== undefined && global.userPermissions.checkPermission("manage-link-update") && ( 
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                type="button"
                                onClick={this.saveOrUpdateQuickLink}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit
                                  ? global.loader
                                  :"Update"
                                }
                              </button> )}
                              
                          </div>
                          &emsp;
                          {this.state.slug !== undefined ? (
                             global.userPermissions.checkPermission("manage-link-delete") && (
                            <button
                              type="button"
                              onClick={() => {
                                this.opendeleteQuickLinksModal(this.state.slug);
                              }}
                              className="detlet-text mg-r-20 pl-0"
                            >
                              Delete Quick Link
                            </button> )
                          ) : (
                            ""
                          )}
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div >
        </main >
        <Modal
          show={this.state.deleteQuickLinksModal}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <h3>Delete Quick Link?</h3>
              <p>
                Do you really want to delete this Quick Link? This process cannot be
                undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteQuickLinksModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteQuickLinks(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div >
    );
  }
}
export default QuickLinkForm;