import { Component } from "react";

class FileValidations extends Component {

    checkAllowFiles = async (actualFileType, validationRules) => {
        let allowedFileType = validationRules.allowedType.split(',');
         if (!allowedFileType.includes(actualFileType) && validationRules.selectedFileLength !== '0') {
            const response = {
                status: false,
                message: validationRules.fieldName + ' type should  be ' + validationRules.allowedType + ".",
            }
            return response;
        } else {
            const response = {
                status: true,
                message: "",
            }
            return response;
        }
    }

    checkMaxFilesAllow = async (validationRules) => {
        let totalAllowFile = validationRules.maxFileAllowed;
        let currentFile = validationRules.currentFile;
        let selectedFileLength = validationRules.selectedFileLength;
        let totalfiles = parseInt(currentFile) + parseInt(selectedFileLength);
        if (parseInt(totalAllowFile) < parseInt(totalfiles)) {
            const response = {
                status: false,
                message: "Please upload maximum of 5 files at a time.",
            }
            return response;
        } else {        
            const response = {
                status: true,
                message: "",
            }
            return response;
        }
    }

    checkFileSize = async (actualFileSize, validationRules) => {
        const k = 1000;

        let fileSizeType = validationRules.maxFileSizeType;
        let allowedFileSize = validationRules.maxFileSize;
        fileSizeType = fileSizeType.toLowerCase();

        if (fileSizeType == 'mb') {
            allowedFileSize = k * k * allowedFileSize;
        } else if (fileSizeType == 'kb') {
            allowedFileSize = k * allowedFileSize;
        }

        if (actualFileSize > allowedFileSize) {
            const response = {
                status: false,
                message: validationRules.fieldName + ' should not be greater then ' + validationRules.maxFileSize + " " + fileSizeType + ".",
            }
            return response;
        } else {
            const response = {
                status: true,
                message: '',
            }
            return response;
        }
    }

    checkValidation = async (fileObject, validationRules) => {
         
        let finalResponse = [];
        let validationStatus = true;
        let validationMessage = [];


        const fileSizeResponse = await this.checkFileSize(fileObject.size, validationRules);
        if (!fileSizeResponse.status) {
            validationStatus = false
            validationMessage.push(fileSizeResponse.message);
        }

        // var type = fileObject.type  ? fileObject.type :fileObject.name.split(".")[1]; last code
        var type = fileObject.type  ? fileObject.name.split(".")[1].toLowerCase() :fileObject.type;
        const allowFileResponse = await this.checkAllowFiles(type, validationRules);
        if (!allowFileResponse.status) {
            validationStatus = false
            validationMessage.push(allowFileResponse.message);
        }

        const checkMaxFilesAllow = await this.checkMaxFilesAllow(validationRules);
        if (!checkMaxFilesAllow.status) {
            validationStatus = false
            validationMessage.push(checkMaxFilesAllow.message);
        }

        finalResponse = {
            validationStatus: validationStatus,
            validationMessage: validationMessage,
        }
        return finalResponse;
    }
}

export default FileValidations;