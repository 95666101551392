/*
* Program: Company.jsx
* Application: Company
* Option: to view Company Information
* Developer: Chandra Shekhar
* Date: 2022-03-10
*/

import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CompanyService from "../../services/CompanyService";
import companyLogo from "./../../assets/images/company_placeholder.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCompany();
    if (global.successStatus.includes(res.status)) {
      
      resData = res.data ? res.data : {};
      // console.log(resData);
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>
                    {this.state.company_name
                      ? this.state.company_name
                      : "Company"}
                  </h3>
                  <Link to={"company/update/" + this.state.slug}>
                    <button className="btn-success-outline">
                      <img src={editIcon} />
                      Edit
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs mg-t-30 pd-t-2">
              <div className="col-md-4 col-lg-3 mg-b-30">
                <div className="dashboardLeftcard az-profile-overview">
                  <div className="az-img-user m-auto align-items-center">
                    <img
                      src={
                        this.state.company_logo_url
                          ? this.state.company_logo_url
                          : companyLogo
                      }
                      alt=""
                    />
                  </div>
                  {/* az-img-user */}
                </div>
                {/* az-profile-overview */}
              </div>

              <div className="col-md-8 col-lg-9 mg-b-30">
                <div className="dashboardRightcard">
                  <div className="row col-md-12 mg-t-40 mg-md-t-0">
                  <div className="col-md-3">
                      <p className="az-profile-name-text pl-0">Company Name</p>
                      <p className="media-body">
                      {this.state.company_name ? this.state.company_name : ""}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="az-profile-name-text pl-0">Email</p>
                      <p className="media-body">
                        {this.state.company_email ? this.state.company_email : ""}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="az-profile-name-text pl-0">Phone</p>
                      <p className="media-body">
                        {this.state.phone_number ? this.state.phone_number : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      </div>
    );
  }
}
export default Company;
