////////////////////////////////////////////////////////////
//     							                                      //
//  Program: montly audit inspetion                        //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Bar} from "react-chartjs-2";

export class MonAuditInspection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    inspectionData: this.props.inspectionData ? this.props.inspectionData :[],
    auditData: this.props.auditData ? this.props.auditData :[],     
    };

  }
  render() {
    return (
      <>
     <div className="card">
                        <div className="card-header">
                          <h6 className="card-title tx-14 mg-b-5 dash_head">
                            Monthly Audits & Inspection Details{" "}
                            {/* <a href="#" className="btn_view">
                            View More
                          </a> */}
                          </h6>
                        </div>
                        <div className="card-body">
                          <div className="dashboard-five-stacked-chart">
                            <div className="chartjs-size-monitor">
                              <div className="chartjs-size-monitor-expand">
                                <div className=""></div>
                              </div>
                              <div className="chartjs-size-monitor-shrink">
                                <div className=""></div>
                              </div>
                            </div>
                            <Bar
                              data={this.state.inspectionData ? this.state.inspectionData : ""}
                              options={this.state.auditData ? this.state.auditData:""}
                            />
                          </div>
                        </div>
                      </div>
      </>
    );
  }
}
export default MonAuditInspection;
