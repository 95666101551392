/*
 * Program: ActionItemNotes.jsx
 * Application: ActionItemNotes
 * Option: to List out of ActionItemNotes Information
 * Developer: Chandra Shekhar
 * Date: 18-04-2022
 */
import React, { Component } from "react";
// import { Form } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import ActionItemNotesService from "../../services/ActionItemNotesService";
import Moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "../CustomToolbar";
import LinkModal from "../LinkModal";
import DeleteImageModal from '../DeleteImageModal';
export class ActionItemNotes extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.quillRef = React.createRef();
    this.state = {
      showModal: false,
      showModalImage: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      isSubmit: false,
      total_record: 0,
      records: [],
      note: "",
      file_name: "",
      selectedFile: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onCloseImagePop = this.onCloseImagePop.bind(this);
  }

  componentDidMount() {
    this.getData();
    const quill = this.quillRef.current.getEditor();
    quill.getModule("toolbar").addHandler("link", this.handleLink);

    // Add event listener for clicks on links
    quill.root.addEventListener("click", this.handleLinkClick);
  }

  componentWillUnmount() {
    const quill = this.quillRef.current.getEditor();
    quill.root.removeEventListener("click", this.handleLinkClick);
  }

  handleLink() {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range,
        url: "",
        text: "",
        isEditing: false,
      });
    }
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleModalSubmit(url, text) {
    const quill = this.quillRef.current.getEditor();
    const { range } = this.state;
    if (range && url && text) {
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, text, "link", url);
      quill.setSelection(range.index + text.length);
    }
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleLinkClick(event) {
    if (event.target.tagName === "A") {
      event.preventDefault();
      const quill = this.quillRef.current.getEditor();
      const range = quill.getSelection();
      const url = event.target.getAttribute("href");
      const text = event.target.innerText;

      // Prevent Quill's tooltip from showing
      quill.blur();
      // quill.focus();

      this.setState({
        showModal: true,
        range: { index: range.index, length: text.length },
        url,
        text,
        isEditing: true,
      });
    }
  }

  handleModalRemove() {
    const quill = this.quillRef.current.getEditor();
    const textLength = quill.getLength();
    // let range = { index: 0, length: textLength };

    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
      const [link] = quill.getFormat(i, 1).link || [];
      if (link) {
        // Remove the link format only, leaving the text intact
        quill.formatText(i, 1, "link", false);
      }
    }

    this.setState({ showModal: false, url: "", text: "", isEditing: false });
  }

  handleChange(value) {
    this.setState({ note: value }, () => {
      this.customValidate();
    });
  }

  async getData(queryString = "action_item_slug=" + this.props.mro_slug) {
    this.setState({ loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await ActionItemNotesService.getMroNotesList(queryString);

    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;
    }
    this.setState({
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  async deleteImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await ActionItemNotesService.deleteImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showModalImage: false,
      });
      this.getData();
      //setTimeout(() => this.props.history.push("/mros"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input, errors: "" });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  saveOrUpdate = async (e) => {
    e.preventDefault();
    this.setState({ isSubmit: true });
    // Create an object of formData
    const formData = new FormData();
    if (this.state.selectedFile != null) {
      formData.append(
        "file_name",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
    }
    formData.append("note", this.state.note);
    formData.append("action_item_slug", this.props.mro_slug);
    let res = await ActionItemNotesService.createMroNote(formData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
      document.getElementById("mro_note_form").reset();
      this.setState({ note: "", selectedFile: null, errors: "" });
    } else {
      let alertMessage = "";
      if (res.data.result.errorDetail !== null) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }

    this.setState({ isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ selectedFile: event.target.files[0] });
      this.setState({ errors: "" });
    }
  };

  toggleModal(slug) {
    // this.setState({ showModalImage: !this.state.showModalImage, imageSlug:slug });
    this.setState({
      showModalImage: true,
      imageSlug: slug,
    });
  }
  onCloseImagePop() {
    this.setState({ showModalImage: false});
  }

  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }
  render() {
    const modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          link: this.handleLink,
        },
      },
    };
    return (
      <div>
        <main className="p-0">
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid p-0">
            <div className="col-md-12 mg-t-18 p-5 dashboardRightcard mb-5 ">
              <div className="tabNavigation">
                <form id="mro_note_form">
                  <div className=" row dashbaord-pageHeadertitle p-0 new_mg-0">
                    <div className="col-md-6 col-lg-6">
                      {/* <Form.Control
                        type="text"
                        as="textarea"
                        onChange={this.changeHandler}
                        name="note"
                        value={this.state.note}
                        maxLength="500"
                        tabIndex="1"
                        placeholder="Type Here..."
                      /> */}
                      <CustomToolbar />
                      <ReactQuill
                        ref={this.quillRef}
                        theme="snow"
                        name="note"
                        value={this.state.note ?? ""}
                        onChange={this.handleChange}
                        modules={modules}
                        formats={global.formats}
                        className="custom_editor_style"
                      />
                      {this.state.showModal && (
                        <LinkModal
                          onClose={this.handleModalClose}
                          onSubmit={this.handleModalSubmit}
                          onRemove={this.handleModalRemove}
                          url={this.state.url}
                          text={this.state.text}
                          isEditing={this.state.isEditing}
                          showModal={this.state.showModal}
                        />
                      )}
                      {this.state.errors.note ? (
                        <div className="text-danger">
                          {this.state.errors.note}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-3 col-lg-3">
                      <input
                        type="file"
                        onChange={this.onImageChange}
                        name="file_name"
                        tabIndex="2"
                        className="p-0 mg-t-30"
                      />
                      {this.state.errors.file_name ? (
                        <div className="text-danger">
                          {this.state.errors.file_name}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {global.userPermissions.checkPermission(
                      "action_items-notes"
                    ) && (
                      <div className="col-md-2 col-lg-2">
                        <button
                          className="gf-btn-lg-stroke mg-l-2 mg-t-30"
                          type="button"
                          onClick={this.saveOrUpdate}
                          disabled={this.state.isSubmit ? true : false}
                        >
                          ADD
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              {this.state.records
                ? this.state.records.map((record, i) => {
                    return (
                      <div key={i}>
                        {record.note ? (
                          <span>
                            <div className="col-md-6 py-2 note_content ">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.modifyTaskName(record.note),
                                }}
                              />
                            </div>
                            <p className="date-time">
                              {Moment(record.created_at).format(
                                "DD MMM YY, hh:mm A"
                              )}{" "}
                              | {record.user.full_name}
                            </p>
                          </span>
                        ) : (
                          ""
                        )}

                        {record.note_image_url ? (
                          <div className="note_img single_item_note_box mt-3">
                            <img
                              src={record.note_image_url}
                              alt="Action item note image"
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn_remove"
                              onClick={() => this.toggleModal(record.slug)}
                            >
                              <span className="fa fa-times"></span>
                            </button>
                            <p className="date-time">
                              {Moment(record.created_at).format(
                                "DD MMM YY, hh:mm A"
                              )}{" "}
                              | {record.user.full_name}
                            </p>
                            
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </main>
        {this.state.showModalImage && (
          <DeleteImageModal
            showModalImage={this.state.showModalImage}
            slug={this.state.imageSlug}
            onRemove={() => this.deleteImage(this.state.imageSlug)}
            onClose={this.onCloseImagePop}
          />
        )}
      </div>
    );
  }
}

export default ActionItemNotes;
