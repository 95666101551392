/*
 * Option: to Show performchecklist before inspection
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
import ChecklistService from "../../services/ChecklistService";
export class StartInspectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cheklist_slug: this.props.match.params.slug,
      showConfirmModel: false,
      resData: [],
    };
  }
  componentDidMount() {
    this.getChecklistData();
  }
  getChecklistData = async (e) => {
    var checklistSlug = this.state.cheklist_slug ?? "";
    if (checklistSlug != undefined) {
      var resData = [];
      let res = await ChecklistService.getChecklistRowBySlug(checklistSlug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data : {};
      }
      this.setState({
        category: resData.category,
        checklist_name: resData.checklist_name,
        zone: resData.zone ? resData.zone.zone_name : "",
        property_slug: resData.property ? resData.property.slug : "",
        checklist_slug: resData.slug ? resData.slug : "",
        zone_type: resData.zone_type ? resData.zone_type : "",
        property_name: resData.property ? resData.property.property_name : "",
      });
    }
  };
  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }

  startInspHandler = async () => {
    window.open(
      `/inspection-perform/${this.state.property_slug}/${this.state.zone_type}/${this.state.checklist_slug}`,
      "_self"
    );
  };

  render() {
    return (
      <div>
        <main className="start_inspectionlist_section">
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        Checklists
                        <Link to="/checklists">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                      <span>{this.state.property_name ?? ""}</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mg-t-18 p-5 dashboardRightcard mb-5">
              <div className="start_insp_title">
                <div className="insp_innerBox">
                  <h4>
                    {this.state.checklist_name != undefined
                      ? this.state.checklist_name
                      : ""}
                  </h4>
                  <span>
                    <strong>{this.state.zone}</strong>
                  </span>
                </div>
                <button
                  className="gf-primary-btn-small-one"
                  type="button"
                  onClick={() => this.startInspHandler()}
                >
                  Start Inspection
                </button>
              </div>
              {this.state.category != undefined &&
              this.state.category.length > 0
                ? this.state.category.map((el, index) => (
                    <>
                      <div
                        className="start_insp_category_list_box"
                        key={"cate_" + index}
                      >
                        <div className="bedroom_title">{el.category_name}</div>
                        {el.task_name
                          ? el.task_name.map((e, indexes) => (
                              <>
                                <ul key={"task_" + indexes}>
                                  <li>
                                    {" "}
                                    <span className="mr-1">{`${indexes + 1}. `}</span>
                                    <span dangerouslySetInnerHTML={{ __html: this.modifyTaskName(e.task_name) }} />
                                  </li>
                                </ul>
                              </>
                            ))
                          : ""}
                      </div>
                    </>
                  ))
                : ""}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default StartInspectionList;
