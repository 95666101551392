////////////////////////////////////////////////////////////
//     							                          //
//  Program: TrackersView.jsx                             //
//  Application:  Properties                              //
//  Option: For add or update Properties                  //
//  Developer: kiran singh                                //
//  Date: 2022-05-03                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Alerts from "../common/Alerts";
import UserService from "../../services/UserService";

export class RolePermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleSwitchDark2: true,
      addModal: false,
      editModal: false,
      deleteModal: false,
      property_name: "",
      status_id: 1,
      open: false,
      eventKey: "",
      slug: this.props.match.params.slug,
      pathname: window.location.pathname,
    };
    this.handleCheckboxChangeSelectAll =
      this.handleCheckboxChangeSelectAll.bind(this);
    this.changeHandlerSelectAll = this.changeHandlerSelectAll.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.saveRolePermission = this.saveRolePermission.bind(this);
  }

  async componentDidMount() {
    this.getData();
  }
  getData = async () => {
    if (this.state.slug !== undefined) {
      var resData = {};
      let selectAllChecked = false;
      let res = await UserService.getPermissionModule(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data : {};
        resData.role_name =
          res.data && res.data.roleData ? res.data.roleData.role_name : "";

        resData.forEach((item) => {
          let allPermissionsChecked = item.permission.every(
            (perm) => perm.status_id === 1
          );
          if (allPermissionsChecked) {
            item.status_id = true;
          } else {
            item.status_id = false;
          }
        });
        selectAllChecked = resData.every((item) => item.status_id === true);
      }
      this.setState({
        dataList: resData,
        role_name: resData.role_name,
        select_all: selectAllChecked,
      });
      console.log("dataList", this.state.dataList);
    }
  };

  handleCheckboxChangeSelectAll = (e) => {
    const checked = e.target.checked;
    const updatedDataList = this.state.dataList.map((item) => {
      item.status_id = checked;
      if ((item.status_id = checked)) {
        item.parent_module_id = 1;
      } else {
        item.parent_module_id = 0;
      }
      item.permission.forEach((perm) => (perm.status_id = checked));
      return item;
    });
    this.setState({ dataList: updatedDataList, select_all: checked });
  };
  changeHandlerSelectAll = (e, i) => {
    const checked = e.target.checked;
    const { dataList } = this.state;
    // console.log("dataList[i]", dataList[i]);
    dataList[i].status_id = checked;
    if ((dataList[i].status_id = checked)) {
      dataList[i].parent_module_id = 1;
    } else {
      dataList[i].parent_module_id = 0;
    }
    dataList[i].permission.forEach((perm) => (perm.status_id = checked));

    const allSelected = dataList.every((item) => item.status_id);
    this.setState({ dataList, select_all: allSelected });
  };
  handleCheckboxChange = (e, i, j) => {
    const checked = e.target.checked;
    const { dataList } = this.state;
    dataList[i].permission[j].status_id = checked;

    const allPermissionsSelected = dataList[i].permission.every(
      (perm) => perm.status_id
    );
    dataList[i].status_id = allPermissionsSelected;

    const allSelected = dataList.every((item) => item.status_id);
    this.setState({ dataList, select_all: allSelected });
  };
  saveRolePermission = async (e) => {
    e.preventDefault();
    var count = 0;
    var permission_list = [];
    // this.setState({ showSpinner: true, isSubmit: true });
    this.state.dataList.map((item, i) => {
      item.permission.map((item1, j) => {
        var permission_data = {};
        permission_data.module_id = item.module_id;
        permission_data.module_permission_id = item1.module_permission_id;
        permission_data.status_id = item1.status_id ? item1.status_id : 0;
        permission_list[count] = permission_data;
        count++;
      });
    });
    let inputData = {
      permission: permission_list,
    };
    if (this.state.slug !== undefined) {
      let res = await UserService.setRolePermissionStatusList(
        this.state.slug,
        inputData
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getData();
        // setTimeout(
        //   () => this.props.history.push("/role-permission/" + this.state.slug),
        //   global.redirect_time
        // );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.errorMessage
              ? res.data.errorMessage
              : res.data.message,
          });
        }
      }
    }
    this.setState({ showSpinner: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  };
  render() {
    return (
      <main>
        {this.state.showSpinner ? global.spinnerLoader : ""}
        <div className="container-fluid">
          <div className="row row-xs">
            <div className="col-md-12">
              <div className="dashbaord-pageHeadertitle heading_alignment">
                <h3 className="flex-column">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    Role Permissions
                  </div>
                  <span>all Permissions</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
        </div>

        <div className="container-fluid">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-12">
                <div className="scrolling-carousel pd-l-0">
                  <div className="container-fluid p-0">
                    <div className="mgb-neg-20 brandList dashboardRightcard">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="checklist_table_heading">
                            <span className="lspan">Role: </span>
                            <span className="rspan">
                              {this.state.role_name}
                            </span>
                          </div>
                        </div>
                      </div>

                      <Table striped bordered className="rolepermission">
                        <thead>
                          <tr>
                            <th className="col-md-2 pt-3 pb-3 select_all_cus_col role_fst_td">
                              <div className="select_all_tbl ckbox form-check">
                                <div className="">
                                  <input
                                    name="select_all"
                                    type={"checkbox"}
                                    id={`select_all`}
                                    onChange={(e) =>
                                      this.handleCheckboxChangeSelectAll(e)
                                    }
                                    className="form-check-input"
                                    checked={this.state.select_all}
                                  />
                                  <label
                                    title=""
                                    htmlFor="select_all"
                                    className="form-check-label mr-2"
                                  ></label>
                                </div>
                                Select All
                              </div>
                            </th>
                            <th className="col-md-10 pt-3 pb-3 permission_title_col select_all_cus_col">Permissions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataList != undefined
                            ? this.state.dataList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="align-middle pt-3 pb-3">
                                      <div className="align-items-center ckbox form-check">
                                        <div className="col-lg-12 d-flex p-0 tems-center role_permiss_td_fst mg-b-0 align-items-center">
                                          <input
                                            type="checkbox"
                                            name="status_id"
                                            className="mg-r-10 mb-0 w-auto"
                                            id={"select_all" + i}
                                            onChange={(e) =>
                                              this.changeHandlerSelectAll(e, i)
                                            }
                                            checked={item.status_id}
                                          />
                                          <label
                                            title=""
                                            htmlFor={"select_all" + i}
                                            className="form-label text-left pd-t-2 m-0"
                                          >
                                            {item.name}
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="align-middle pt-3 pb-3">
                                      <div className="row mx-0">
                                        {item.permission.map((item1, j) => {
                                          return (
                                            <div
                                              className="col-md-2 d-flex align-items-center checkbox_align_role pl-0"
                                              key={j}
                                            >
                                              <div className="align-items-center ckbox form-check">
                                                <div className="col-lg-12 p-0 d-flex tems-center role_permiss_td_fstmg-b-0 align-items-center">
                                                  <input
                                                    type="checkbox"
                                                    name="status_id"
                                                    value={
                                                      item1.module_permission_id
                                                    }
                                                    id={i + "_" + j}
                                                    className="mg-r-10 mb-0 w-auto"
                                                    onChange={(e) =>
                                                      this.handleCheckboxChange(
                                                        e,
                                                        i,
                                                        j
                                                      )
                                                    }
                                                    checked={item1.status_id}
                                                  />

                                                  <label
                                                    className="form-label text-left pd-t-2 m-0"
                                                    htmlFor={i + "_" + j}
                                                  >
                                                    {item1.name}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </Table>
                      <div className="row row-xs align-items-left flex-wrap m-0 pd-t-30 pd-b-0 pd-l-140 ">
                        <div className="col-md-12 btnNavigate d-flex align-items-left justify-content-end flex-row flex-wrap-bothBtn p-0">
                          <div className="d-flex align-items-left">
                            <button
                              type="button"
                              onClick={this.saveRolePermission}
                              className="gf-btn-lg-stroke mg-l-2"
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug !== undefined
                                ? "Update"
                                : "ADD"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ht-50"></div>
          </div>
        </div>
      </main>
    );
  }
}

export default RolePermission;
