////////////////////////////////////////////////////////////
//  Program: DashboardService.js                          //
//  Application: DashboardService                         //
//  Option: Used for manage DashboardService              //
//  Developer: Kiran singh 					              //
//  Date: 2022-06-02                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class DashboardService {
  getDashboardData(queryString) {
    let endPoint = "dashboard" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

}

export default new DashboardService();
