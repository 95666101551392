////////////////////////////////////////////////////////////
//  Program: InspectionService.js                          //
//  Application: InspectionService                         //
//  Option: Used for manage Properties                    //
//  Developer: Kiran singh 						          //
//  Date: 2022-04-06                                      //
////////////////////////////////////////////////////////////
import ApiService from "./ApiService";

class InspectionService {
  getInspectionList(queryString) {
    let endPoint = "inspection" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createInspection(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;
    let endPoint = "inspection";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getInspectionBySlug(slug) {
    let endPoint = "inspection/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getInspectionPdfBySlug(slug) {
    let endPoint = "inspection-pdf/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  updateInspection(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "inspection/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteInspection(slug) {
    let endPoint = "inspection/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
  uploadInspFile(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;
    let endPoint = "inspection-fileUpload";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new InspectionService();
