import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

class DeleteImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModalImage || false,
      // classRemove: props.classRemove || false,
      slug: props.slug,
      isDelete: false,
    };
    this.handleRemove = this.handleRemove.bind(this);
    this.closedeleteModal = this.closedeleteModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    // if (this.props.classRemove !== prevProps.classRemove) {
    //   this.setState({ classRemove: this.props.classRemove });
    // }
  }

  handleRemove(event) {
    event.preventDefault();
    this.setState({ isDelete: true });
    this.props.onRemove();
  }

  closedeleteModal() {
    this.setState({ showModal: false});
    this.props.onClose();
  }

  render() {
    return (
        <Modal show={this.state.showModal} centered>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center">
            <h3>Delete Image?</h3>
            <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
              Do you really want to delete this image? This process cannot
              be undone.
            </p>
            <div className="d-flex cnt_center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={this.closedeleteModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-0 btn btn btn-danger btn-block"
                onClick={this.handleRemove}
                disabled={this.state.isDelete}
              >
                {this.state.isDelete ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default DeleteImageModal;
