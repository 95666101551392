/*
 * Program: Brand.jsx
 * Application: Brand
 * Option: to List out of Brand Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-10
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import NotificationService from "../../services/NotificationService";
import Moment from "moment";
// import { Row, Col } from "react-bootstrap";
export class Notification extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      config: {
        sort: {
          column: "Subject",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: true,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by Subject",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "technician",
          text: "Technician",
          className: "technician",
          sortable: true,
          cell: (record) => {
            return record.sender_user ? record.sender_user.full_name :"";
          },
          },
          {
            key: "module",
            text: "Module",
            className: "module",
            sortable: true,
            cell: (record) => {
              return record.record_type ? record.record_type :"";
            },
            },
          {
            key: "subject",
            text: "Subject",
            className: "subject",
            sortable: true,
            cell: (record) => {
              return record.message_subject ? this.limitTextTo50Words(record.message_subject) : ""; 
              // return record.message_subject ? record.message_subject : "";
            },
        },
        {
          key: "date",
          text: "Date",
          className: "date hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.created_at
              ? Moment(record.created_at).format("DD MMM, YYYY")
              : "";
          },
        },

      ],
      isFirstLoad : true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    if (this.state.isFirstLoad) {
      this.setState({ loading: true});
    }
    var list = [];
    var totalRecords = 0;
    let res = await NotificationService.getNotificationList(queryString);
      if (global.successStatus.includes(res.status)) {
      list = res.data != undefined && res.data.notifications != undefined ? res.data.notifications.data : [];
      totalRecords = res.data != undefined && res.data.notifications != undefined ? res.data.notifications.total : 0;
    }
    this.setState({
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add('bdy_over');
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          } else if (key === "page_size") {
            newKey = "per_page";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    let moduleName = data.ref_table_id ? data.ref_table_id :"";
    let moduleSlug = data.ref_table_rec_slug ? data.ref_table_rec_slug :"";
    if(moduleName != undefined){
      if(moduleName === 1){
        window.open(`/mros/update/${moduleSlug}`, "_blank" );
      }
      if(moduleName === 3){
        window.open(`/action-item/update/${moduleSlug}`, "_blank" );
      }
      
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  limitTextTo50Words(htmlContent) {
    // Parse HTML content to extract text
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const textContent = doc.body.textContent || "";

    // Limit the text content to 50 characters
    const limit = 180;
    let result = textContent.slice(0, limit);

    // Append "..." if the original content is longer than 50 characters
    if (textContent.length > limit) {
        result += '...';
    }
    return result;
  }
  render() {
    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                <div className="dashbaord-pageHeadertitle ">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                    <h3>Notifications</h3>
                    <div className="btnNavigate d-flex justify-content-end">
                    </div>
                    </div>
                    <span>All Notifications</span>
                  </h3>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="mg-tneg-4 brandList dashboardRightcard">
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2 customerTable table_wrap"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Notification;
