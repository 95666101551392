import React, { Component } from "react";
import deleteicon from "../../assets/images/delete.svg";
// import pluswIcon from "./../../assets/images/plus.svg";
import pluswIcon from "./../../assets/images/file-uploader-image.png";
// import { Form } from "react-bootstrap";
class HtmlTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedFiles: "",
      updateFileList: this.props.uploadedFiles,
      newRelatedFiles: [],
      deletedFileList: [],
      newRelatedFileLength: 0,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    this.removeNewSelectedFile = this.removeNewSelectedFile.bind(this);
    this.removeUpdatedFile = this.removeUpdatedFile.bind(this);
  }

  //@logic New preview file array set here.
  previewFileList = async (event) => {
    const allFiles = event.target.files;
    /* //@logic delete old single file when selecting new single file */
    if (this.props.uploadType === "single") {
      // let deletedFileList = this.state.deletedFileList;
      // console.log(deletedFileList.length, deletedFileList.length <= 1);
      //commented on 08/02/22 by NP & GP for underfined errror
      // if (deletedFileList.length === 0) {
      //     this.removeUpdatedFile(0);
      // }
    }

    let newRelatedFiles = [];
    for (let i = 0; i < allFiles.length; i++) {
      let fileObject = allFiles[i];
      //Display preview if it's image
      if (fileObject.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        allFiles[i]["src"] = URL.createObjectURL(fileObject);
      } else {
        allFiles[i]["src"] = "";
      }
      newRelatedFiles.push(allFiles[i]);
    }
    this.setState({
      newRelatedFiles: newRelatedFiles,
      newRelatedFileLength: newRelatedFiles.length,
    });
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value = event.target.value;

    let newRelatedFiles = this.state.newRelatedFiles;
    if (event.target.name === "description") {
      newRelatedFiles[index][event.target.name] = value;
      this.setState({ newRelatedFiles: newRelatedFiles });
      if (this.props.uploadType === "multiple") {
        this.props.newRelatedFilesDescChange(index, value);
      }
    } else {
      input[event.target.name] = value;
      this.setState({ [event.target.name]: value, input: input });
    }
  };

  //@logic New file remove manage here
  removeNewSelectedFile = async (key) => {
    let newFileList = this.state.newRelatedFiles;
    let newLength = this.state.newRelatedFileLength;
    // newFileList.splice(key,1);
    delete newFileList[key];
    this.setState({
      newRelatedFileLength : newLength-1,
      newRelatedFiles: newFileList,
    });
  };

  //@logic updated file remove manage here.
  removeUpdatedFile = async (key) => {
    // console.log(key);
    let updateFileList = this.state.updateFileList;
    // console.log("updateFileList", updateFileList);
    let deletedFileList = this.state.deletedFileList;
    // console.log("deletedFileList", deletedFileList);
    // let tempArr = updateFileList[key];
    let tempArr = {
      fieldName: this.props.fieldName,
      uploadedTo: this.props.uploadOn,
      fileDestination: this.props.destinationFolder,
      ...updateFileList[key],
    };
    deletedFileList.push(tempArr);
    this.props.deletedFiles.push(tempArr);
    delete updateFileList[key];
    this.setState({
      updateFileList: updateFileList,
      deletedFileList: deletedFileList,
    });
    // this.props.onRemovedFiles(deletedFileList);
  };

  render() {
    const allowMultiple = this.props.uploadType == "multiple" ? true : false;
    const dragClassName =
      this.props.drag === "true"
        ? "image-upload-wrap"
        : "multiImgBuuton file_widget_class";
    const inputDragClassName =
      this.props.drag === "true" ? "file-upload-input" : "file_widget_class";
    return (
      <div className="form-group row pd-l-20 m-0">
        {allowMultiple === true ? (
          <div className="row border-none align-items-center">
            <div className="col-md-12 pl-0 d-none">
              <label
                title={this.props.fieldTitle ? this.props.fieldTitle : ""}
                className={
                  this.props.labelClassName
                    ? this.props.labelClassName + " form-label text-left mg-b-0"
                    : "az-profile-name-text pl-0 pd-b-0"
                }
              >
                {this.props.fieldLabel ? this.props.fieldLabel : "Image:"}
              </label>
            </div>

            <div className="col-md-12">
              <div className={dragClassName + "pd-l-30"}>
                <label htmlFor="contained-button-file">
                  <div
                    variant="file"
                    // type="file"
                    className="btn-multiupload-outline d-flex flex-column text-center file_widget_class"
                  >
                    <img src={pluswIcon} alt="" />
                    <span className="add">
                      <b>{this.props.dragText}</b>
                    </span>
                  </div>
                </label>
                {/* <label
                title={this.props.fieldTitle}
                className={this.props.labelClassName}
              >
                {this.props.fieldLabel}
              </label> */}
                <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  multiple={allowMultiple}
                  name={this.props.fieldName}
                  title={this.props.fieldTitle}
                  className={
                    this.props.inputClassName +
                    " file_widget_class " +
                    inputDragClassName
                  }
                  allowedtype={this.props.allowedType}
                  maxfilesize={this.props.maxFileSize}
                  maxfilesizetype={this.props.maxFileSizeType}
                  maxfileallowed={this.props.maxFileAllowed}
                  uploadon={this.props.uploadOn}
                  reftableid={this.props.refTableId}
                  displayfilename={this.props.displayFileName}
                  documenttypeslug={this.props.documentTypeSlug}
                  mediatype={this.props.mediaType}
                  sortorder={this.props.sortOrder}
                  statusid={this.props.statusId}
                  checkvalidation={this.props.checkValidation}
                  resizeoptions={this.props.resizeOptions}
                  destinationfolder={this.props.destinationFolder}
                  onChange={this.previewFileList}
                  uploadedfiles={this.props.uploadedFiles}
                  // onchange="readURL(this);"
                  accept={this.props.accept}
                  currentfile={this.props.currentFile}
                  newfilelength={
                    this.state.newRelatedFileLength
                      ? this.state.newRelatedFileLength
                      : 0
                  }
                />
                {this.props.drag === "true" && (
                  <div className="drag-text">
                    <h5>{this.props.dragText}</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={dragClassName}>
            <input
              type="file"
              multiple={allowMultiple}
              name={this.props.fieldName}
              title={this.props.fieldTitle}
              className={
                this.props.inputClassName +
                " file_widget_class " +
                inputDragClassName
              }
              allowedtype={this.props.allowedType}
              maxfilesize={this.props.maxFileSize}
              maxfilesizetype={this.props.maxFileSizeType}
              maxfileallowed={this.props.maxFileAllowed}
              uploadon={this.props.uploadOn}
              refTableId={this.props.refTableId}
              displayFileName={this.props.displayFileName}
              documentTypeSlug={this.props.documentTypeSlug}
              mediaType={this.props.mediaType}
              sortOrder={this.props.sortOrder}
              statusId={this.props.statusId}
              checkvalidation={this.props.checkValidation}
              resizeoptions={this.props.resizeOptions}
              destinationfolder={this.props.destinationFolder}
              onChange={this.previewFileList}
              uploadedfiles={this.props.uploadedFiles}
              // onchange="readURL(this);"
              accept={this.props.accept}
            />
            {this.props.drag === "true" && (
              <div className="drag-text">
                <h5>{this.props.dragText}</h5>
              </div>
            )}
          </div>
        )}

        {/* <small className="form-text text-muted">
                    <a
                        href={this.props.sampleFileUrl}
                        target="_blank"
                        rel="noreferrer"
                        download>Click Here </a>
                    {this.props.helpText}
                </small> */}

        {/* //@logic  when updated file array need to preview files. */}
        {this.props.displayPreviewStyle === "vertical" ? (
          <ul
            className="fileList list-group list-group-flush "
            id={"old_" + this.props.fieldName}
          >
            {this.state.updateFileList !== undefined &&
              this.state.updateFileList.map((val, key) => {
                return (
                  <React.Fragment key={key}>
                    <li className="list-group-item border-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {val.orgFileName.match(/.(jpg|jpeg|png|gif)$/i) &&
                          val.fileUrl !== "" ? (
                            <img src={val.fileUrl} width="30" height="30" />
                          ) : (
                            <span
                              style={{ marginLeft: "32px" }}
                              width="30"
                              height="30"
                            ></span>
                          )}
                          <strong> {val.orgFileName} </strong> - {val.fileSize}{" "}
                          bytes.
                        </div>

                        <button
                          className="removeFile btn h3 float-end"
                          type="button"
                          data-fileid={key}
                          onClick={() => this.removeUpdatedFile(key)}
                        >
                          <span className="h3" aria-hidden="true">
                            <img src={deleteicon} alt="" />
                          </span>
                        </button>
                      </div>
                    </li>
                  </React.Fragment>
                );
              })}
          </ul>
        ) : (
          this.state.updateFileList !== undefined && (
            <div className="container mt-3">
              <div className="gallery">
                {this.state.updateFileList.map((val, key) => {
                  return (
                    <React.Fragment key={key}>
                      <div className="gallery-item">
                        <div className="gallery-image-div">
                          {val.orgFileName.match(/.(jpg|jpeg|png|gif)$/i) &&
                          val.fileUrl !== "" ? (
                            <img
                              className="gallery-image"
                              width="150"
                              height="150"
                              src={val.fileUrl}
                            />
                          ) : (
                            <span className="gallery-image"></span>
                          )}
                          <p className="title">
                            <strong> {val.orgFileName} </strong> <br />
                            <strong> {val.fileSize} </strong>{" "}
                            <small> bytes</small>
                          </p>
                          <div
                            className="button"
                            data-fileid={key}
                            onClick={() => this.removeUpdatedFile(key)}
                          >
                            <span className="h2" aria-hidden="true">
                              &times;
                            </span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )
        )}

        {/* //@logic  when new file array need to preview files. */}
        {this.props.displayPreviewStyle === "vertical" ? (
          <ul
            className="multiImgsection fileList list-group list-group-flush list_img"
            id={this.props.fieldName}
          >
            {this.state.newRelatedFiles.length > 0 &&
              this.state.newRelatedFiles.map((val, key) => {
                {
                  /* In use this */
                }
                return (
                  <React.Fragment key={key}>
                    <li className="list-group-item  border-0">
                      <div className="row ml-0">
                        {/* <div>
                      {val.name.match(/.(jpg|jpeg|png|gif)$/i) &&
                      val.src !== "" ? (
                        <img src={val.src} width="30" height="30" />
                      ) : (
                        <span
                          style={{ marginLeft: "32px" }}
                          width="30"
                          height="30"
                        />
                      )}
                      <strong> {val.name} </strong> - {val.size} bytes C.
                      </div> */}
                        <div className="col-md-12 pr-0  pl-0 pt-0 d-flex">
                          <figure className="img_close m-0 p-0">
                            <img
                              src={val.src}
                              alt=""
                              width="150"
                              height="150"
                              className="brand_list_image"
                            />
                          </figure>
                          <div className="mg-l-0 mg-r-0 text-center">
                            <button
                              type="button"
                              className="removeFile btn-multilist-outline-danger btn_remove"
                              onClick={() => this.removeNewSelectedFile(key)}
                            >
                              <span className="fa fa-times"></span>
                            </button>
                            {/* <span className="danger">
                              Remove <b>image</b>
                            </span> */}
                          </div>
                        </div>

                        {/* <div className="col-md-2 text-center d-flex justify-content-start">
                     
                      </div> */}
                      </div>
                      {/* //@logic progress bar */}
                      <span>
                        <div
                          className="progress hidden"
                          id={"progress" + this.props.fieldName + key}
                        >
                          <div
                            id={"progressBar" + this.props.fieldName + key}
                            className=" progress-bar "
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </span>
                    </li>
                  </React.Fragment>
                );
              })}
          </ul>
        ) : (
          this.state.newRelatedFiles !== undefined && (
            <div className="container mt-3">
              <div className="gallery">
                {this.state.newRelatedFiles.map((val, key) => {
                  return (
                    <React.Fragment key={key}>
                      <div className="gallery-item">
                        <div className="gallery-image-div">
                          {val.name.match(/.(jpg|jpeg|png|gif)$/i) &&
                          val.src !== "" ? (
                            <img className="gallery-image" src={val.src} />
                          ) : (
                            <span className="gallery-image"></span>
                          )}
                          <p className="title">
                            <strong> {val.name} </strong> <br />
                            <strong> {val.size} </strong> <small> bytes</small>
                          </p>
                          {/* <div
                            className="button"
                            data-fileid={key}
                            onClick={() => this.removeNewSelectedFile(key)}
                          >
                            <span className="h2" aria-hidden="true">
                              &times;
                            </span>
                          </div> */}

                          {/* //@logic progress bar */}
                          <span>
                            <div
                              className="progress hidden hpreview"
                              id={"progress" + this.props.fieldName + key}
                            >
                              <div
                                id={"progressBar" + this.props.fieldName + key}
                                className=" progress-bar "
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

export default HtmlTemplate;
