/*
 * Program: CompanyService.js
 * Application: Company Services
 * Option: Used for Company Company Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-10
 */

import ApiService from "./ApiService";

class CompanyService {
  getCompany() {
    let endPoint = "company-info";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateCompany(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "company-update/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  uploadCompanyLogo(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "company-update-logo";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new CompanyService();
