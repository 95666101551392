/*
 * Option: to Show performchecklist before inspection
 */
import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
import CheckListInfoTab from "./ChecklistInfoTab";
import CheckListRoomTab from "./CheckListRoomTab";
import CheckListCommercialTab from "./ChecklistCommercialTab";
import CheckListEquipmentZoneTab from "./ChecklistEquipmentZoneTab";
import CheckListEquipmentTab from "./ChecklistEquipmentTab";
export class PerformChecklistForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <main className="per_checklist_section">
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        Checklists
                        <Link to="/checklists">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                      <span>
                          Hotel Harmony Inn
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
            </div>
            {/* Nav Bar start */}
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="info"
            >
            <div className="scrolling-carousel pd-l-0">
                <ScrollingCarousel rightArrow={true} leftArrow={true}>
                <div className="swipesection ">
                    <Nav variant="pills" className="">
                    <Nav.Item>
                        <Nav.Link
                        eventKey="info"
                        className=""
                        >
                        All
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="room"
                        className=""
                        >
                        Room
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="commercial-area"
                        className=""
                        >
                        Commercial Area
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="equipment-zone"
                        className=""
                        >
                        Equipment Zone
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="equipment"
                        className=""
                        >
                        Equipment
                        </Nav.Link>
                    </Nav.Item>
                    </Nav>
                </div>
                </ScrollingCarousel>
            </div>
              <Tab.Content>
                <Tab.Pane eventKey="info">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                        <CheckListInfoTab />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="room">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListRoomTab />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="commercial-area">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListCommercialTab />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="equipment-zone">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListEquipmentZoneTab />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="equipment">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListEquipmentTab />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            {/* Nav bar end */}
          </div>
        </main>
      </div>
    );
  }
}

export default PerformChecklistForm;
