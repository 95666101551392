/*
 * Program: MroCategory.jsx
 * Application: MroCategory
 * Option: to List out of MroCategory Information
 * Developer: Chandra Shekhar
 * Date: 2022-04-01
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import MroCategoryService from "../../services/MroCategoryService";
import { Link } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
export class MroCategory extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      addMroCategoryModal: false,
      data: [],
      records: [],
      status_id: true,
      name: "",
      slug: "",
      config: {
        sort: {
          column: "name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by category",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "name",
          text: "Category",
          className: "name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.slug ? record.name : record.name;
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">Inactive</b>
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.getMroCategoryData = this.getMroCategoryData.bind(this);
    this.saveOrUpdateMroCategory = this.saveOrUpdateMroCategory.bind(this);
    this.opendeleteMroCategoryModal =
      this.opendeleteMroCategoryModal.bind(this);
    this.deleteMroCategory = this.deleteMroCategory.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await MroCategoryService.getMroCategoryList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total;
    }
    this.setState({
      total_record: totalRecords,
      records: list,
    });
  }

  saveOrUpdateMroCategory = async (e) => {
    e.preventDefault();
    if (
      this.state.status_id === true
        ? this.validator.allValid() && this.customValidate()
        : !this.validator.errorMessages.name
    ) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        name: this.state.name,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      if (this.state.slug !== undefined) {
        let res = await MroCategoryService.updateMroCategory(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });

          setTimeout(
            () => this.setState({ addMroCategoryModal: false }),
            global.redirect_time
          );
          setTimeout(() => this.getData(), global.redirect_time);
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await MroCategoryService.createMroCategory(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.setState({ addMroCategoryModal: false }),
            global.redirect_time
          );
          setTimeout(() => this.getData(), global.redirect_time);
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  };

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.setState({ isFilterTrue: true });
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add('bdy_over');
    }
  };

  closeModal() {
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.setState({ showAlertModal: false });
    this.setState({ addMroCategoryModal: false });
    this.setState({
      name: "",
      errors: {},
    });
  }

  async getMroCategoryData(slug) {
    let res = await MroCategoryService.getMroCategory(slug);
    if (this.state.slug !== undefined) {
      if (global.successStatus.includes(res.status)) {
        // console.log('res',res)
        this.setState({ slug: res.data.slug });
      }
    }
  }
  /* for amenity type modal */
  openModal(data) {
    this.setState({ slug: data.slug });
    this.getMroCategoryData(data.slug);
    this.setState({ addMroCategoryModal: true });
    this.setState({ slug: data.slug });
    if (data.status_id !== undefined) {
      this.setState({ status_id: data.status_id === 1 ? true : false });
    }
    this.setState({ name: data.name });
    setTimeout(() => {
      this.nameInput.focus();
    }, 0);
  }

  async deleteMroCategory(slug) {
    this.setState({ loading: true });
    let res = await MroCategoryService.deleteMroCategory(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ deleteMroCategoryModal: false }),
        global.redirect_time
      );
      setTimeout(
        () => this.setState({ addMroCategoryModal: false }),
        global.redirect_time
      );
      setTimeout(() => this.getData(), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  opendeleteMroCategoryModal(slug) {
    this.setState({ deleteMroCategoryModal: true });
  }

  closedeleteMroCategoryModal() {
    this.setState({ deleteMroCategoryModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    if (value.trim() !== "") {
      this.validator.showMessageFor(event.target.name);
    }
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString + this.state.customQueryString);
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    this.openModal(data);
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Categories</h3>
                        {/* <Link to="/mros">
                          <img src={back} />
                          Back
                        </Link> */}
                        <button
                          className="gf-primary-btn-small-one "
                          onClick={this.openModal}
                        >
                          Add Category
                        </button>
                      </div>
                      <span>all category</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
          </div>
          <div className="container-fluid">
            <div className=" brandList dashboardRightcard">
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>

          {/* Add Edit Category Modal */}
          <Modal
            centered
            show={this.state.addMroCategoryModal}
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span className="welcomeClose" onClick={() => this.closeModal()}>
                {global.closee}
              </span>

              <h3 className="text-center">
                {this.state.slug !== undefined ? "Update" : "Add"} Category
              </h3>
              <div className="row align-items-basline flex-wrap m-0">
                <div className="col-md-12 col-lg-12 mg-t-5 p-0 pd-b-1 mg-md-t-0">
                  <label className="form-label text-left mg-b-5">
                    Category
                  </label>
                  <Form.Control
                    onChange={this.changeHandler}
                    name="name"
                    ref={(input) => {
                      this.nameInput = input;
                    }}
                    value={this.state.name}
                    className="form-control max_width_100"
                    id="inlineFormInput"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                  />
                  {this.state.errors.name ? (
                    <div className="text-danger">{this.state.errors.name}</div>
                  ) : (
                    this.validator.message(
                      "category",
                      this.state.name,
                      "required|max:50",
                      { className: "text-danger" }
                    )
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mg-b-30 pd-b-3 pt-3">
                {global.userData.role_id == 1 ? (
                  <div className="d-flex">
                    <b className={this.state.status_id ? "active" : "inactive"}>
                      {this.state.status_id ? "Active" : "Inactive"}
                    </b>
                    <div
                      className={
                        this.state.status_id ? "az-toggle on" : "az-toggle"
                      }
                      onClick={() =>
                        this.setState({ status_id: !this.state.status_id })
                      }
                    >
                      <span></span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.slug !== undefined && (global.userData.role_id == 1 )? (
                  <Link
                    to="#"
                    onClick={() => {
                      this.opendeleteMroCategoryModal(this.state.slug);
                    }}
                    className="delete-text"
                  >
                    DELETE CATEGORY
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closeModal()}
                >
                  Cancel
                </button>
                <button
                  className="m-0 btn btn btn-success btn-block"
                  type="button"
                  onClick={this.saveOrUpdateMroCategory}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit
                    ? global.loader
                    : this.state.slug !== undefined
                    ? "Update"
                    : "Add"}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.deleteMroCategoryModal}
            centered
          >
            <Modal.Body className="width_402 p-4 border_radius_14 h-240">
              <div className="text-center">
                <h3>Delete Category?</h3>
                <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                  Do you really want to delete this Category? This process cannot
                  be undone.
                </p>
                <div className="row d-flex cnt_center mt-3 row">
                  <button
                    type="button"
                    className=" m-0 btn btn-outline-light btn-block"
                    onClick={() => this.closedeleteMroCategoryModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className=" m-0 btn btn btn-danger btn-block"
                    onClick={() => this.deleteMroCategory(this.state.slug)}
                    disabled={this.state.isSubmit ? true : false}
                  >
                    {this.state.isSubmit ? global.loader : "Delete"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </main>
      </div>
    );
  }
}

export default MroCategory;
