// CustomToolbar.js
import React from 'react';

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-link"></button>
  </div>
);

export default CustomToolbar;
