/*
 * Program: CompanyForm.js
 * Application: Company Update form
 * Option: Used for update Company Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-10
 */

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import companyLogo from "./../../assets/images/company_placeholder.png";
import CompanyService from "../../services/CompanyService";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import infosize from "../../assets/images/Info.svg";

class CompanyForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      company_name: "",
      phone_number: "",
      company_email: "",
      company_logo: "",
      imageUrl: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async getData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};

    let res = await CompanyService.getCompany();
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : {};
      resData.imageUrl = res.data.company_logo_url
        ? res.data.company_logo_url
        : companyLogo;
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "company_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      let res = await CompanyService.uploadCompanyLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          company_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if(res.status === 413){
        this.setState({showSpinner: false, showAlertModal: true,alertModalType: 'error', alertModalTitle: 'Error!', alertModalMessage: "Content Too Large"});
      }else {
        let alertMessage = '';
        if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = 'Validation Failed';
            res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = '';
            })
            this.setState({ errors: errors });
        }
        this.setState({ showSpinner: false, showAlertModal: true, alertModalType: 'error', alertModalTitle: (res.data.errorCode) ? res.data.errorCode : 'Error!', alertModalMessage: (alertMessage !== '') ? alertMessage : res.data.message });
    }
    setTimeout(() => this.setState({ showAlertModal: false }), global.alert_close_time);
  }
  }

  updateCompany = async (e) => {
    e.preventDefault();
    if (this.customValidate() && this.validator.allValid()) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        company_name: this.state.company_name,
        aka_name: this.state.aka_name,
        company_logo: this.state.company_logo,
        phone_number: this.state.phone_number,
        company_email: this.state.company_email,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      let res = await CompanyService.updateCompany(this.state.slug, inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/company"),
          global.redirect_time
        );
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
          //change tab logic
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      //change tab logic
    }
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
                <div className="backpage">
                  <Link to="/company">COMPANY </Link>
                  <span> / </span>
                  {this.state.slug !== undefined ? (
                    <span>EDIT</span>
                  ) : (
                    <span>ADD</span>
                  )}
                </div>
              </div>

              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>

              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap">
                  <h3>{this.state.company_name ? this.state.company_name : 'Company'}</h3>
                  <button
                    className="btn-success"
                    type="button"
                    disabled={this.state.isSubmit ? true : false}
                    onClick={this.updateCompany}
                  >
                    {this.state.isSubmit ? global.loader : "Update" }
                  </button>
                </div>
              </div>

              <div className="col-md-12 mg-t-46">
                <div className="tabNavigation">
                  <form id="a">
                        <div className="m-0">
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            {/* col */}
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="company_name"
                                value={this.state.company_name}
                                maxLength="50"
                                tabIndex="1"
                                placeholder="Company Name"
                              />
                              {this.state.errors.company_name ? (
                                <div className="text-danger">
                                  {this.state.errors.company_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "company_name",
                                  this.state.company_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>

                            {/* col */}
                          </div>
                          {/* row */}
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Company Logo{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            {/* col */}
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <div className="fileUpload d-flex flex-wrap justify-content-between">
                                <span>
                                  <label className="hand-cursor">
                                    <input
                                      onChange={this.onImageChange}
                                      type="file"
                                      name="company_logo"
                                      tabIndex="2"
                                    />
                                    {this.state.imageUrl ? (
                                      <img
                                        src={this.state.imageUrl}
                                        alt="Company Logo"
                                        width={150}
                                        height={150}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </span>

                                <ul className="ruLink">
                                  <li>
                                    <label>
                                      <input
                                        onChange={this.onImageChange}
                                        type="file"
                                        name="company_logo"
                                        tabIndex="2"
                                      />
                                    </label>
                                    Change Logo
                                  </li>
                                </ul>
                              </div>
                              {this.state.errors.company_logo ? (
                                <div className="text-danger">
                                  {this.state.errors.company_logo}
                                </div>
                              ) : (
                                this.validator.message(
                                  "company_logo",
                                  this.state.company_logo,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}

                              <span className="d-inline-flex  align-items-start justify-content-start infosize">
                                <img src={infosize} className="mg-r-5" />
                                <p>
                                  {" "}
                                  Recommended size 139px by 139px, and use 1:1 ratio image for best fit. Max file size - 10 MB
                                </p>
                              </span>
                            </div>
                          </div>
                          {/* row */}
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            {/* col */}
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="phone_number"
                                value={this.state.phone_number}
                                placeholder="Phone Number"
                                tabIndex="14"
                              />
                              {this.state.errors.phone_number ? (
                                <div className="text-danger">
                                  {this.state.errors.phone_number}
                                </div>
                              ) : (
                                this.validator.message(
                                  "phone_number",
                                  this.state.phone_number,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* col */}
                          </div>
                          {/* row */}
                          <div className="row row-xs align-items-center pd-t-30 pd-b-30">
                            <div className="col-md-3 pl-0">
                              <label className="form-label mg-b-0">
                                Email <span className="text-danger">*</span>
                              </label>
                            </div>
                            {/* col */}
                            <div className="col-md-7 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="company_email"
                                value={this.state.company_email}
                                placeholder="Email"
                                tabIndex="15"
                              />
                              {this.state.errors.company_email ? (
                                <div className="text-danger">
                                  {this.state.errors.company_email}
                                </div>
                              ) : (
                                this.validator.message(
                                  "email",
                                  this.state.company_email,
                                  "required|email",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* row */}
                          </div>
                        </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </main>
        {/* <Toast show={this.state.showAlertModal} type={this.state.alertModalType} title={this.state.alertModalTitle} message={this.state.alertModalMessage} /> */}
      </div>
    );
  }
}
export default CompanyForm;
