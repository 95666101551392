import React, { Component } from 'react';
import { Modal } from "react-bootstrap";

class LinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url || '',
      text: props.text || '',
      showModal: props.showModal || false,
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url || this.props.text !== prevProps.text) {
      this.setState({ url: this.props.url, text: this.props.text });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value, errors: { ...this.state.errors, [name]: '' } });
  }

  validate() {
    const { url } = this.state;
    const errors = {};

    // Simple URL validation regex
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!urlPattern.test(url)) {
      errors.url = 'Please enter a valid URL.';
    }
    return errors;
  }

  handleSubmit(event) {
    event.preventDefault();
    const errors = this.validate();

    if (Object.keys(errors).length === 0) {
      this.props.onSubmit(this.state.url, this.state.text);
    } else {
      this.setState({ errors });
    }
  }

  handleRemove(event) {
    event.preventDefault();
    this.props.onRemove();
  }

  render() {
    const { url, text, errors } = this.state;
    const { onClose, isEditing } = this.props;

    return (
      <Modal show={this.state.showModal} centered className='editor_common_modal'>
        <Modal.Body className="width_402 p-4 border_radius_14">
          <div className="text-center editor_modal_popup">
            <span className="close" onClick={onClose}>&times;</span>
            <div className='row'>
              <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1">
                <label className="form-label text-left mg-b-5">URL</label>
                <input
                  autoComplete="off"
                  type='text'
                  name="url"
                  className="form-control"
                  value={url}
                  onChange={this.handleChange}
                />
                {errors.url && <div className="text-danger text-left pd-lg-5">{errors.url}</div>}
              </div>
              <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1">
                <label className="form-label text-left mg-b-5">Display Text</label>
                <input
                  autoComplete="off"
                  type='text'
                  name="text"
                  className="form-control"
                  value={text}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70'>
                <div className="cnt_center d-flex mt-3 mr-0">
                  <button className="btn-success" onClick={this.handleSubmit}>Insert Link</button>
                  {isEditing && (
                    <button className="btn-outline-light ml-2 mr-0 remove_link_btn" onClick={this.handleRemove}>Remove Link</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LinkModal;
