////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertiesView.jsx                           //
//  Application:  Properties                              //
//  Option: For add or update Properties                  //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav} from "react-bootstrap"
import PropertyService from "../../services/PropertyService"
import Basic from "../properties/Basic"
import Buildings from "../properties/Buildings";
import Floors from "../properties/Floor";
import Rooms from "../properties/Rooms";
import Commercials from "../properties/CommercialsArea";
import EquipmentZones from "../properties/EquipmentZones";
import Equipment from "../properties/Equipment";

import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";

export class PropertiesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleSwitchDark2: true,
            addModal:false,
            editModal:false,
            deleteModal:false,
            property_name:"",
            status_id:1,
            open:false,
            eventKey:'',
            property_slug: this.props.match.params.propertySlug,
            pathname: window.location.pathname,
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
    }

  async componentDidMount() {
        if (this.state.property_slug !== undefined) {
          var resData = {};
          let res = await PropertyService.getProperty(this.state.property_slug);
          if (global.successStatus.includes(res.status)) {
            resData = res.data.data ? res.data.data : {}
            localStorage.setItem('property_name',res.data.property_name ? res.data.property_name : '');
          }
          this.setState(resData);
        }
    }
  // getTitle() {
  //   {this.state.property_name}
  //   return "Marriott International";
  // }

setOpen(open,e){
    this.setState({open:open});
    this.setState({eventKey:e.target.eventKey});
}

changeHandler = (event) => {
    let input = this.state.input;
    let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
    input[event.target.name] = value;
    this.setState({[event.target.name]:value, input: input});
}

openAddModal(){
    this.setState({addModal:true});
}

closeAddModal(){
    this.setState({addModal:false});
}

openEditModal(){
    this.setState({editModal:true});
}

closeEditModal(){
    this.setState({editModal:false});
}

openDeleteModal(){
    this.setState({deleteModal:true});
}

closeDeleteModal(){
    this.setState({deleteModal:false});
}

  render() {
    return (
      <main>
        {this.state.showSpinner ? global.spinnerLoader : ""}
        <div className="container-fluid">
            <div className="row row-xs">
                <div className="col-md-12">
                 <div className="dashbaord-pageHeadertitle prp_mb-10 heading_alignment">
                   <h3 className="flex-column">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          {this.state.property_name ? this.state.property_name: '' }
                          <Link to="/properties"><img src={back}/>Back</Link>
                        </div>
                        <span> {this.state.customer ? this.state.customer.customer_name: '' }</span>
                   </h3>
                   </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
        </div>

        <div className="container-fluid">
            <div className="d-flex flex-column">
                    <div className="row row-xs mg-t-0 pd-t-0">
                        <div className="col-md-12">
                        <div className="scrolling-carousel pd-l-0">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
                            <ScrollingCarousel rightArrow={true} leftArrow={true}>
                              <div className="swipesection mgt_10">
                                <Nav variant="pills" className="">
                                    <Nav.Item>
                                        <Nav.Link eventKey="basic" >Property Info</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="buildings" >Buildings</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="floors" >Floors</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="rooms" >Rooms</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="commercilas" >Commercial Areas</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="equipmentszone" >Equipment Zones</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="equipment" >Equipment</Nav.Link>
                                    </Nav.Item>
                                </Nav>                                                  
                              </div>
                            </ScrollingCarousel>
                            <Tab.Content>
                                <Tab.Pane eventKey="basic">
                                    <Basic property_slug={this.state.property_slug} />
                                </Tab.Pane>     
                                <Tab.Pane eventKey="buildings">
                                    <Buildings propertySlug={this.state.property_slug} />
                              </Tab.Pane> 
                              <Tab.Pane eventKey="floors">
                                    <Floors propertySlug={this.state.property_slug}  />
                              </Tab.Pane>
                              <Tab.Pane eventKey="rooms">
                                    <Rooms propertySlug={this.state.property_slug}  />
                            </Tab.Pane>
                            <Tab.Pane eventKey="commercilas">
                                    <Commercials propertySlug={this.state.property_slug}  />
                      </Tab.Pane>
                      <Tab.Pane eventKey="equipmentszone">
                                    <EquipmentZones propertySlug={this.state.property_slug}  />
                      </Tab.Pane>
                      <Tab.Pane eventKey="equipment">
                                    <Equipment propertySlug={this.state.property_slug}  />
                      </Tab.Pane>
                      

                      
                            </Tab.Content>
                            </Tab.Container>

                        </div>
                      </div>
                    </div>
                <div className="ht-50"></div>
            </div>
        </div>
        
      </main>
    );
  }
}

export default PropertiesView
