////////////////////////////////////////////////////////////
//  Program: AuditService.js                              //
//  Application: AuditService                             //
//  Option: Used for manage Template and AuditService     //
//  Developer: Kiran singh 					              //
//  Date: 2022-06-02                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AuditService {
  getAuditList(queryString) {
    let endPoint = "audit" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getAuditRowBySlug(slug) {
    let endPoint = "audit/" + slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

   createAudit(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "audit";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getAuditPdfBySlug(slug) {
    let endPoint = "audit-pdf/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  updateAudit(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "audit/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }
  
  deleteAudit(slug) {
    let endPoint = "audit/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
  uploadInspFile(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;
    let endPoint = "audit-fileUpload";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

}

export default new AuditService();
