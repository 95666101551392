/*
 * Program: UserView.jsx
 * Application: Customer
 * Option: to View Customer Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-10
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import UserService from "../../services/UserService";
import userLogo from "./../../assets/images/default.png";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import Alerts from "../common/Alerts";
import modaldeleteicon from "./../../assets/images/delete.svg";

export class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      showSpinner: false,
      loading: true,
      records: [],
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.opendeleteUserModal = this.opendeleteUserModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ showSpinner: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await UserService.getUser(this.state.slug);
      //console.log(res.data);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
      }
      this.setState(resData);
    }
    this.setState({ showSpinner: false });
  }

  opendeleteUserModal(slug) {
    this.setState({ deleteUserModal: true });
  }

  closedeleteUserModal() {
    this.setState({ deleteUserModal: false });
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  async deleteUser(slug) {
    this.setState({ showSpinner: true });
    let res = await UserService.deleteUser(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        records: this.state.records.filter((list) => list.slug !== slug),
      });
      setTimeout(() => this.props.history.push("/user"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <Container>
            <div className="row row-xs">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
                <div className="backpage">
                  <Link to="/user">User </Link>
                  <span> / </span>
                  <span>VIEW</span>
                </div>
              </div>

              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>

              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle d-flex flex-wrap- justify-content-between align-items-center">
                  <h3>
                    {this.state.first_name || this.state.last_name
                      ? this.state.first_name + " " + this.state.last_name
                      : ""}
                  </h3>
                  <div className="d-flex flex-wrap- bothBtn">
                    <button
                      onClick={() => {
                        this.opendeleteUserModal(this.state.slug);
                      }}
                      className="btn-danger-outline mg-r-20"
                    >
                      <img src={deleteicon} alt="" />
                      Delete
                    </button>
                    <Link
                      to={"/user/update/" + this.state.slug}
                      className="mg-l-2"
                    >
                      <button className="btn-success-outline">
                        <img src={editIcon}  alt="" />
                        Edit
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="row row-xs mg-t-30 pd-t-2">
              <div className="col-md-4 col-lg-3 mg-b-30">
                <div className="dashboardLeftcard">
                  <div className="az-profile-overview">
                    <div className="az-img-user mg-l-auto mg-r-auto align-items-center mb-2">
                      <img
                        src={
                          this.state.profile_thumb_url
                            ? this.state.profile_thumb_url
                            : userLogo
                        }
                        alt=""
                      />
                    </div>
                    {/* az-img-user */}
                    <div className="d-flex align-items-center justify-content-center text-center">
                      <b className="status-active">
                        {this.state.status_id_disp}
                      </b>
                    </div>
                    <div className="d-flex justify-content-start mg-b-20 mg-t-20">
                      <div>
                        <p className="az-profile-name-text">Name</p>
                        <p className="media-body">
                          {this.state.first_name || this.state.last_name
                            ? this.state.first_name + " " + this.state.last_name
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* az-profile-overview */}
                </div>
              </div>

              <div className="col-md-8 col-lg-9 mg-b-30">
                <div className="dashboardRightcard">
                  <div className="row col-md-12 mg-t-40 mg-md-t-0">
                    <div className="col-md-4">
                      <p className="az-profile-name-text pl-0">First Name</p>
                      <p className="media-body">
                        {this.state.first_name ? this.state.first_name : ""}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="az-profile-name-text pl-0">Last Name</p>
                      <p className="media-body">
                        {this.state.last_name ? this.state.last_name : ""}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="az-profile-name-text pl-0">Email</p>
                      <p className="media-body">
                        {this.state.email ? this.state.email : ""}
                      </p>
                    </div>
                  </div>
                  <div className="row col-md-12 mg-t-40 mg-md-t-0">
                    <div className="col-md-4">
                      <p className="az-profile-name-text pl-0">Phone</p>
                      <p className="media-body">
                        {this.state.phone ? this.state.phone : ""}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="az-profile-name-text pl-0">Role</p>
                      <p className="media-body">
                        {this.state.role_type_name
                          ? this.state.role_type_name
                          : ""}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p className="az-profile-name-text pl-0">Job Title</p>
                      <p className="media-body">
                        {this.state.job_title
                          ? this.state.job_title
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
        <Modal
          show={this.state.deleteUserModal}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete User?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-between mt-3 row">
                <button
                  type="button"
                  className="mx-auto m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteUserModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="mx-auto m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteUser(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default UserView;
