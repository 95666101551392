////////////////////////////////////////////////////////////
//  Program: EquipmentService.js                          //
//  Application: EquipmentService                         //
//  Option: Used for manage Properties                    //
//  Developer: Kiran singh 						                    //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////
import ApiService from "./ApiService";

class EquipmentService {
  getEquipment(queryString) {
    let endPoint = "equipment" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createEquipment(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;
    let endPoint = "equipment";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getEquipmentBySlug(slug) {
    let endPoint = "equipment/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateEquipment(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "equipment/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }

  deleteEquipment(slug) {
    let endPoint = "equipment/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
  deleteEquipmentImage(slug) {
    let endPoint = "equipment/delete-image/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
  getSortOrder(tableName){
    let endPoint = "equipment_get_order/" + tableName;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
 
}

export default new EquipmentService();
