/*
 * Program: MroNotesService.js
 * Application: MroCategory Service
 * Option: Used for MroCategory Service Information
 * Developer: Chandra Shekhar
 * Date: 18-04-2022
 */
import ApiService from "./ApiService";

class MroNotesService {
  getMroNotesList(queryString) {
    let endPoint = "mro-notes-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createMroNote(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "mro-notes-add";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteImage(slug) {
    let endPoint = "mro-note/delete-image/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
}

export default new MroNotesService();
