////////////////////////////////////////////////////////////
//  Program: BuildingsList.jsx                            //
//  Application:  Buildings                               //
//  Option: For add or update Tower                       //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import BuildingService from "../../services/BuildingService";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import { Modal, Form } from "react-bootstrap";
// import modaldeleteicon from "./../../assets/images/delete.svg";
export class Buildings extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      isSubmit: false,
      isDelete: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.propertySlug,
      pathname: window.location.origin,
      addModal: false,
      deleteModal: false,
      tableName: "property_building",
      building_name: "",
      sort_order: "",
      records: [],
      status_id: true,
      lastQueryString: "",
      data: [],
      config: {
        sort: {
          column: "building_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by building",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "building_name",
          text: "Building ",
          className: "building_name  hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.building_name ? record.building_name : "";
          },
        },
        // {
        //   key: "sort_order",
        // text: "Sort Order ",
        // className: "building_name  hand-cursor",
        // sortable: true,
        // cell: (record) => {
        // return record.sort_order ? record.sort_order : "";
        // },
        // },
        {
          key: "status_id",
          text: "Status",
          className: "status_id  hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">Inactive</b>
            );
          },
        },
      ],
    };
    this.closeModal = this.closeModal.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.openModal = this.openModal.bind(this);
    this.opendeleteModal = this.opendeleteModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.saveOrUpdateBuilding = this.saveOrUpdateBuilding.bind(this);
  }
  async componentDidMount() {
    this.getData();
    //this.getMaxorder();
    this.resetDate();
    if (this.state.building_slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      var totalRecords = 0;
      let res = await BuildingService.getBuildingBySlug(
        this.state.building_slug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.building_name = resDatalist.building_name
          ? resDatalist.building_name
          : "";
        resData.status_id = resDatalist.status_id === 1 ? true : false;
        resData.sort_order = resDatalist.sort_order
          ? resDatalist.sort_order
          : "";
        totalRecords = res.data.total ? res.data.total : 0;
      }
      this.setState(resData);
      this.defaultData = resData;
      this.setState({
        showSpinner: false,
        loading: false,
        total_record: totalRecords,
      });
    }
  }

  async getMaxorder() {
    let sort_order = "";
    let res = await BuildingService.getSortOrder(this.state.tableName);
    if (global.successStatus.includes(res.status)) {
      sort_order = res.data && res.data.max_order ? res.data.max_order : "";
    }
    this.setState({ sort_order: sort_order });
  }
  /* for amenity type modal */
  openModal(data) {
    // if(data.slug === undefined){
    //   this.getMaxorder();
    // }
    this.setState({
      addModal: true,
      showAlertModal: false,
      slug: data.slug,
      building_name: data.building_name,
      sort_order: data.sort_order,
      errors: {},
    });
    if (data.status_id !== undefined) {
      this.setState(
        { status_id: data.status_id === 1 ? true : false },
        () => {}
      );
    }
    setTimeout(() => {
      this.nameInput.focus();
    }, 0);
  }
  resetData() {
    this.setState({
      building_name: "",
      sort_order: "",
    });
    this.getData();
  }

  closeModal() {
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.setState({ showAlertModal: false });
    this.setState({ addModal: false });
    this.setState({
      building_name: "",
      sort_order: "",
      errors: {},
    });
  }

  opendeleteModal(slug) {
    this.closedeleteModal();
    this.setState({ addModal: false });
    this.setState({ deleteModal: true });
    this.setState({ slug: slug });
  }

  closedeleteModal() {
    this.setState({ deleteModal: false });
  }
  saveOrUpdateBuilding = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      let inputData = {
        building_name: this.state.building_name,
        property_slug: this.state.property_slug,
        status_id: this.state.status_id === true ? 1 : 2,
        sort_order: this.state.sort_order,
      };
      if (this.state.slug !== undefined) {
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        let res = await BuildingService.updateBuilding(
          this.state.slug,
          inputData
        );

        if (global.successStatus.includes(res.status)) {
          this.closeModal();
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.setState({ addModal: false }),
            global.redirect_time
          );
          setTimeout(
            () => this.getData(this.state.is_filter_apply),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      } else {
        let res = await BuildingService.createBuilding(inputData);
        if (global.successStatus.includes(res.status)) {
          this.closeModal();
          this.getData(this.state.is_filter_apply);
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      }
      this.setState({ loading: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  };

  async getData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await BuildingService.getBuilding(
      "property_slug=" + this.state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : "";
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }
  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ lastQueryString: queryString });
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ building_name: "", sort_order: "", lastQueryString: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.setState({ is_filter_apply: queryString });
      this.getData(queryString + this.state.lastQueryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.openModal(data);
  };
  async deleteBuilding(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await BuildingService.deleteBuilding(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.closeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
    } else {
      this.closedeleteModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  render() {
    return (
      <main className="p-0">
        <div className={`${global.userPermissions.checkPermission("property-update-assigned-property") ? 'btnNavigate d-flex flex-column building-btn' : 'd-none'}`}>
        {global.userPermissions.checkPermission("property-update-assigned-property") && (
        // {global.userData.role_id === 1 || global.userData.role_id === 6 || global.userData.role_id ===  3 ? <>
          <Link to={"#"} className="w-100 d-flex justify-content-end">
            <button
              type="button"
              className="gf-primary-btn-small-one "
              onClick={this.openModal}
            >
              Add Building
            </button>
          </Link>)}
         
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0">
            <div className="col-md-12">
              <div className="dashboardRightcard mt-top-7neg">
                <div className="mg-t-18 brandList">
                  <ReactDatatable
                    className="table no-wrap mg-b-30 pd-b-2"
                    config={this.state.config}
                    records={this.state.records}
                    columns={this.state.columns}
                    extraButtons={this.state.extraButtons}
                    dynamic={true}
                    loading={this.state.loading}
                    total_record={this.state.total_record}
                    onChange={this.tableChangeHandler}
                    onRowClicked={
                      global.userPermissions.checkPermission(
                        "property-update-assigned-property"
                      ) && this.rowClickedHandler
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ht-50"></div>
        </div>

        {/* add building popup modal */}
        <Modal show={this.state.addModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>

            <h3 className="text-center">
              {this.state.slug !== undefined
                ? "Update building"
                : "Add building"}
            </h3>
            <label>Building</label>
            <Form.Control
              onChange={this.changeHandler}
              ref={(input) => {
                this.nameInput = input;
              }}
              name="building_name"
              value={this.state.building_name}
              className="form-control max_width_100 "
              id="inlineFormInput"
              type="text"
              maxLength="50"
              autoComplete="off"
              autoFocus={true}
            />
            {this.state.errors.building_name ? (
              <div className="text-danger">
                {this.state.errors.building_name}
              </div>
            ) : (
              this.validator.message(
                "building",
                this.state.building_name,
                "required|max:50",
                { className: "text-danger" }
              )
            )}
            {/* <label className="form-label text-left mg-b-0 mg-t-15">Sort Order(Optional)</label>
             <Form.Control
              onChange={this.changeHandler}
              name="sort_order"
              value={this.state.sort_order}
              className="form-control max_width_100 "
              type="text"
              maxLength="50"
              /> */}

            <div className="d-flex align-items-center justify-content-between mg-b-30 pd-b-3 mg-t-15">
              {global.userPermissions.checkPermission(
                "property-update-assigned-property"
              ) && (
                <div className="d-flex">
                  <b
                    className={
                      this.state.status_id == 1 ? "active" : "inactive"
                    }
                  >
                    {this.state.status_id == 1 ? "Active" : "Inactive"}
                  </b>
                  <div
                    className={
                      this.state.status_id == 1
                        ? "az-toggle on hand-cursor"
                        : "az-toggle hand-cursor"
                    }
                    onClick={() =>
                      this.setState({ status_id: !this.state.status_id })
                    }
                  >
                    <span></span>
                  </div>
                </div>
              )}

              {global.userPermissions.checkPermission(
                "property-update-assigned-property"
              ) &&
                (this.state.slug !== undefined ? (
                  <Link
                    onClick={() => {
                      this.opendeleteModal(this.state.slug);
                    }}
                    className="delete-text"
                  >
                    Delete Building
                  </Link>
                ) : (
                  ""
                ))}
            </div>

            <div className="d-flex cnt_center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModal()}
              >
                Cancel
              </button>
              {global.userPermissions.checkPermission(
                "property-update-assigned-property"
              ) && (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.saveOrUpdateBuilding}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit
                    ? global.loader
                    : this.state.slug !== undefined
                    ? "UPDATE"
                    : "ADD"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {/* add delete modal */}
        <Modal show={this.state.deleteModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14 h-240">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Building?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this building? This process cannot
                be undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteModal()}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteBuilding(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Buildings;
