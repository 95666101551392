/*
 * Program: DailyLogService.js
 * Application: Daily log Service
 * Option: Used for Daily log Service Information
 * Developer: Chandra Shekhar
 * Date: 07-04-2022
 */
import ApiService from "./ApiService";

class DailyLogService {
  getList(queryString) {
    let endPoint = "daily-log" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  createDailyLog(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "daily-log";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getDetails(slug) {
    let endPoint = "daily-log/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // for daily log thread create
  createDailyLogThread(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "add-daily-log-thread";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteDailyLog(slug) {
    let endPoint = "daily-log/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

}

export default new DailyLogService();
