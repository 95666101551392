////////////////////////////////////////////////////////////
//     							                          //
//  Program: Landing.jsx                                  //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: NP  						                  //
//  Date: 2021-09-15                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

export class Landing extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
    };
    this.initialState = this.state;
    this.defaultData = {};
  }
  render() {
    return (
      <div className="spinnerLoader" style={{ background: "white" }}>
        <div className="toploader">
          <img src={require("../../assets/images/Loader-new.gif").default} alt="" />
          <p className="pt-2">POWERED BY NCKE</p>
        </div>
      </div>
    );
  }
}

export default Landing;
