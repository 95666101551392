////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ChangePassword.jsx                           //
//  Application: authentication                           //
//  Option: For Change Password into project              //
//  Developer: NP 						                            //
//  Date: 2021-12-29                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      input: {},
      errors: {},
      isSubmit: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordType: "password",
      passwordTypeNew: "password",
      passwordTypeConf: "password",
      showPasswordIcon: "fa fa-eye-slash",
      showPasswordIconNew: "fa fa-eye-slash",
      showPasswordConf: "fa fa-eye-slash",
    };

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changePassword = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        current_password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmPassword,
      };
      let res = await AuthenticationService.changePassword(input);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/dashboard"),
          global.redirect_time
        );
      } else {
        if (
          res.data.result.errorDetail !== null &&
          res.data.result.errorDetail !== undefined
        ) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event) => {
    var errors = this.state.errors;
    this.setState({ [event.target.name]: event.target.value });

    if (
      event.target.name === "confirmPassword" &&
      event.target.value.length >= 6
    ) {
      var pw =
        event.target.name === "newPassword"
          ? event.target.value
          : this.state.newPassword;
      var cpw =
        event.target.name === "confirmPassword"
          ? event.target.value
          : this.state.confirmPassword;

      if (pw !== cpw) {
        errors["confirmPassword"] =
          "The New Password and confirm password must be same!";
        this.setState({ errors: errors });
      } else {
        errors["confirmPassword"] = "";
        this.setState({ errors: errors });
      }
    }
    // Validate
    this.validator.showMessageFor(event.target.name);
  };

  handleClickShowPassword = (e) => {
    this.setState({
      passwordType:
        this.state.passwordType === "password" ? "text" : "password",
    });
    this.state.passwordType === "password"
      ? this.setState({ showPasswordIcon: "fa fa-eye" })
      : this.setState({ showPasswordIcon: "fa fa-eye-slash " });
  };

  handleClickShowNewPassword = (e) => {
    this.setState({
      passwordTypeNew:
        this.state.passwordTypeNew === "password" ? "text" : "password",
    });
    this.state.passwordTypeNew === "password"
      ? this.setState({ showPasswordIconNew: "fa fa-eye" })
      : this.setState({ showPasswordIconNew: "fa fa-eye-slash " });
  };

  handleClickShowConfPassword = (e) => {
    this.setState({
      passwordTypeConf:
        this.state.passwordTypeConf === "password" ? "text" : "password",
    });
    this.state.passwordTypeConf === "password"
      ? this.setState({ showPasswordConf: "fa fa-eye" })
      : this.setState({ showPasswordConf: "fa fa-eye-slash " });
  };

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Change Password</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                          <Link to="/update-profile">
                            <img src={back} alt="" />
                            Back
                          </Link>
                        </div>
                      </div>
                      <span>Change Password</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>

            <div className="col-md-12 mgt_11 p-5 dashboardRightcard ">
              <div className="tabNavigation">
                <form id="password_from">
                  <div className="m-0">
                    <div className="">
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Current Password
                              </label>
                              <div className="show_password_new">
                                <input
                                  type={this.state.passwordType}
                                  name="currentPassword"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.currentPassword}
                                  onChange={this.changeHandler}
                                  maxLength="25"
                                />
                                <span className="pass_eye_btn">
                                  <i
                                    className={this.state.showPasswordIcon}
                                    onClick={this.handleClickShowPassword}
                                  ></i>
                                </span>
                              </div>
                              {this.validator.message(
                                "currentPassword",
                                this.state.currentPassword,
                                "required|min:6",
                                { className: "valid_info" }
                              )}
                            </div>
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                New Password
                              </label>
                              <div className="show_password_new">
                                <input
                                  type={this.state.passwordTypeNew}
                                  name="newPassword"
                                  className="form-control"
                                  value={this.state.newPassword}
                                  onChange={this.changeHandler}
                                  maxLength="25"
                                  autoComplete="off"
                                />
                                <span className="pass_eye_btn">
                                  <i
                                    className={this.state.showPasswordIconNew}
                                    onClick={this.handleClickShowNewPassword}
                                  ></i>
                                </span>
                              </div>
                              {this.state.errors.new_password ? (
                                <div className="valid_info">
                                  {this.state.errors.new_password}
                                </div>
                              ) : (
                                this.validator.message(
                                  "newPassword",
                                  this.state.newPassword,
                                  "required|min:6",
                                  { className: "valid_info" }
                                )
                              )}
                            </div>
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-15 p-0 pd-b-1 mg-md-t-0">
                              <label className="form-label text-left mg-b-5">
                                Confirm new Password
                              </label>
                              <div className="show_password_new">
                                <input
                                  type={this.state.passwordTypeConf}
                                  name="confirmPassword"
                                  className="form-control"
                                  value={this.state.confirmPassword}
                                  onChange={this.changeHandler}
                                  maxLength="25"
                                  autoComplete="off"
                                />
                                <span className="pass_eye_btn">
                                  <i
                                    className={this.state.showPasswordConf}
                                    onClick={this.handleClickShowConfPassword}
                                  ></i>
                                </span>
                              </div>
                              {this.state.errors.confirmPassword ? (
                                <div className="valid_info">
                                  {this.state.errors.confirmPassword}
                                </div>
                              ) : (
                                this.validator.message(
                                  "confirmNewPassword",
                                  this.state.confirmPassword,
                                  "required|min:6",
                                  { className: "valid_info" }
                                )
                              )}
                            </div>
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-b-0 p-0 pd-b-1 mg-md-t-0 mt-5 d-flex justify-content-center">
                              <div className="d-flex align-items-center">
                                <Link to="/update-profile">
                                  <button
                                    className="gf-btn-lg-outline mg-r-7"
                                    type="button"
                                  >
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  className="gf-btn-lg-stroke mg-l-2"
                                  onClick={this.changePassword}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  {this.state.isSubmit
                                    ? global.loader
                                    : "CHANGE"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ChangePassword;
