////////////////////////////////////////////////////////////
//  Program: Audit List.jsx                               //
//  Application:  Audit  List                             //
//  Option: List of Audit                                 //
//  Developer: kiran singh                                //
//  Date: 2022-06-02                                      //
////////////////////////////////////////////////////////////
import React, { Component } from 'react';
import ReactDatatable from "@ashvin27/react-datatable"
import SimpleReactValidator from "simple-react-validator"
import AuditTemplateService from "../../services/AuditTemplateService"
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import {Modal, Button} from "react-bootstrap";
import Select from "react-select";
import brownArrow from "./../../assets/images/brown-arrow.svg";
import { Table } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state.isSelected && state.isFocused
        ? "white"
        : state.isSelected
        ? "black"
        : state.isFocused && "white",
    backgroundColor:
      state.isSelected && state.isFocused
        ? "gray"
        : state.isFocused && "gray",
    fontFamily: "Roboto-Regular",
    fontSize: "16px",
  }),
};
export class AuditTemplate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: '',
      customQueryString: '',
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: '',
      loading: false,
      total_record: 0,
      zoneTab:false,
      records: [],
      config: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by name",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "audit_name",
          text: "Name",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {

            return record.audit_name ? record.audit_name : ''
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? <b className="status-active">Active</b> :<b className="status-inactive">Inactive</b>;
          },
        },
      ],
      countryList: [],
      stateList: [],
      cityList: [],
      country_id: "",
      state_id: "",
      city_id: "",
      isFirstLoad: true,
      lastQueryString: "",
    };
    this.closeModal = this.closeModal.bind(this)
  }
  componentDidMount() {
    this.getData();
  }
  closeModal() {
    this.setState({ showChecklistModal: false })
  }
  async getData(queryString = "") {
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    var list = [];
    let res = await AuditTemplateService.getAuditTemplateList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
    }
    this.setState({
      loading: false,
      records: list,
      total_record: res.data ? res.data.total : '',
    });
  }


  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          }
          params.append(newKey, data[key]);
        }
      });
      
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
  
      this.getData(`${queryString}${this.state.lastQueryString}`);
    }
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/audit-template/update/" + data.slug);
  };

  auditFlow() {
    this.getProperty();
    this.getChecklistList();
    this.setState({ showChecklistModal: true })
  }
  async getProperty(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    var localSelectedProperty = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    if (propertyList.length > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          label: item.property_name,
          value: item.slug,
        });
      });
    }
    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
      };
      this.setState({zoneTab:true, property_slug:localStorage.getItem("singlePorpertySlug")})
    } else {
      if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
         localSelectedProperty = {
          label: localStorage.getItem("selectedPropertyName"),
          value: localStorage.getItem("outSidePorpertySlug"),
        };
        this.setState({zoneTab:true, property_slug:localStorage.getItem("outSidePorpertySlug")})
        }
    }
    this.setState({
      propertyList: propertyListData,
      selectedProperty: localSelectedProperty ?? [],
    });
  }
 /* Hndlae chnage for perform index */
 async handleChangePropertyPerform(value) {
  this.setState({ zoneTab: false });
  let property_slug = [];
  if (value != null && value.length > 0) {
    value.forEach((item, i) => {
      property_slug.push(item.value);
    });
  }
  await this.setState({
    selectedProperty: value,
    property_slug: value.value,
    template_slug: value.value,
  });
   localStorage.setItem("propertyNameAuditPerform", value.label);
  /* Location tab open when open when inspection perform */
  if (this.state.showChecklistModal === true) {
    this.setState({ zoneTab: true });
  }
}
   // Get Checklist List
   async getChecklistList() {
    var list = [];
    let res = await AuditTemplateService.getAuditTemplateList("is_dropdown=1");
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
     }  
    this.setState({
        checkList: list,
       
    });
  }
  render() {
    return (
      <div>
        <main>
          <div className='container-fluid'>
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">

                  <div className="dashbaord-pageHeadertitle ">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Audit Templates</h3>                      
                        <div className="btnNavigate d-flex justify-content-end">
                        {global.userPermissions.checkPermission("audits-add") && (
                          <div>
                          <Button
                            onClick={() => this.auditFlow()}
                            className="gf-primary-btn-small-one"
                          >
                            Perform
                          </Button></div> )}
                        {global.userPermissions.checkPermission("template-add") && (
                          <div className="btnNavigate d-flex justify-content-end">
                            <Link to={'/audit-template/add'} ><button className="gf-primary-btn-small-one mg-l-7"> Add Audit Template</button></Link>

                            </div>)}
                            </div>
                      </div>
                      <span>All Audit Templates</span>
                    </h3>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className="mg-tneg-4 brandList dashboardRightcard">
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
        <Modal className="per_checklist_modal" show={this.state.showChecklistModal} centered dialogClassName={(this.state.zoneTab === false ? "single_property_mod_dialog" : "per_checklist_modal_dialog")}>
         
         <Modal.Body className="width_402 p-4 border_radius_14">
           <span className="welcomeClose" onClick={() => this.closeModal()}>
             {global.closee}
           </span>

           <h3 className="text-center">Audit Checklists</h3>
           <div className={(this.state.zoneTab === false ? "col-md-12" : "col-md-6")}>
           <label>Property</label>
           <Select
               className="multiselect new_drop"
               menuPlacement="auto"
               value={this.state.selectedProperty}
               options={this.state.propertyList}
               isSearchable={true}
               onChange={(value) =>
                 this.handleChangePropertyPerform(value)
               }
               defaultValue={this.state.selectedProperty}
               autoComplete="off"
               getOptionValue={(option) => `${option.label}`}
               styles={customStyles}
               theme={(theme) => ({
                 ...theme,
                 colors: {
                   ...theme.colors,
                   primary75: "#808080",
                   primary50: "#808080",
                   primary25: "#808080",
                 },
               })}
             />

            </div> 
            {this.state.property_slug != undefined ? (<>
              <div className={"col-md-12 mt-4"}>
             {/* Nav Bar start */}
             <label>Checklist</label>
             <div className="mg-tneg-4 tabNavigation">
       <Table  className="mg-b-0 checklist_inspect_table">
         <thead>
         </thead>
       <tbody>
       {this.state.checkList != undefined && this.state.checkList.length > 0 ? this.state.checkList.map((el, indexes) => ( <>
           <tr >
           <th
             scope="row"
             className="az-inspection-black border-right-0"
           >
             
             <Link
                to={`/start-audit-list/${this.state.property_slug}/${el.slug}`}
               className="az-inspection-brown"
             >
             {el.audit_name ?? ""}
             </Link>
           </th>
           <td className="border-left-0 pl-5">
             {/* <i className="mdi mdi-eye-outline text-primary"></i> */}
             <Link
               to={`/start-audit-list/${this.state.property_slug}/${el.slug}`}
               className=""
             >
               <img src={brownArrow} alt="" height={10} />
             </Link>
           </td>
         </tr>  
         </>)) : <label className="form-label text-left mg-b-5"> No Template Available </label>}
                   
         </tbody>
       </Table>
               
     </div>
           </div>
            </>) :""}
         
           <div className="cnt_center d-flex mt-3">
             <button
               type="button"
               className=" m-0 btn btn-outline-light btn-block"
               onClick={() => this.closeModal("basic")}
             >
               Cancel
             </button>
            
           </div>
         </Modal.Body>
       </Modal>

      </div>
    )
  }
}

export default AuditTemplate;
