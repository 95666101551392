////////////////////////////////////////////////////////////
//  Program: EquipmentForm.jsx                            //
//  Application:  Equipment                               //
//  Option: For add or update Tower                       //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import SimpleReactValidator from "simple-react-validator";
import EquipmentService from "../../services/EquipmentService";
import back from "./../../assets/images/back.svg";
import MroService from "../../services/MroService";
import Select from "react-select";
import FileWidget from "../fileUploader/FileWidget";
import placeholderImage from "./../../assets/images/image-placeholder.png";
// import modaldeleteicon from "./../../assets/images/delete.svg";

export class EquipmentForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      deletedFileList: [],
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      roomList: [],
      zoneList: [],
      comerList: [],
      zoneTypeList: [],
      open: global.showOpen,
      tableName: "property_equipment",
      eventKey: "",
      property_slug: this.props.match.params.propertySlug,
      slug: this.props.match.params.slug,
      isSubmit: false,
      isDelete: false,
      status_id: true,
      sort_order: "",
      pathname: window.location.pathname,
      selecteLocationList: [],
      selectedZoneTypeList: [],
      mroImages: [],
      selectedFloorList: [],
      FloorHide: false,
      serial_number: "",
    };
    this.opendeleteModal = this.opendeleteModal.bind(this);
    this.deleteEquipmentImage = this.deleteEquipmentImage.bind(this);
    //this.getNumber = this.getNumber.bind(this);
  }

  componentDidMount() {
    this.getEquipmentData();
  }
  // Location Change Handler
  handleChangeLocation(value) {
    let propertySlug = "";
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    propertySlug = this.state.property_slug;
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
    });
    if (this.state.zone_type == 1) {
      setTimeout(() => {
        this.getFloor(
          "property_slug=" +
            propertySlug +
            "&room_slug=" +
            this.state.location_slug
        );
      }, 10);
      this.setState({ FloorHide: true });
    } else if (this.state.zone_type == 2) {
      setTimeout(() => {
        this.getFloor(
          "property_slug=" +
            propertySlug +
            "&commerical_slug=" +
            this.state.location_slug
        );
      }, 10);
      this.setState({ FloorHide: true });
    } else if (this.state.zone_type == 3) {
      setTimeout(() => {
        this.getFloor(
          "property_slug=" +
            propertySlug +
            "&equipment_slug=" +
            this.state.location_slug
        );
      }, 10);
      this.setState({ FloorHide: true });
    }
  }
  handleChangeZoneType(value) {
    let propertySlug = "";
    let zoneType = "";
    if (this.state.property_slug === null || this.state.property_slug === "") {
      alert("Please select property first");
      return false;
    }
    propertySlug = this.state.property_slug;
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: value,
      zone_type: value.value,
      floor_name: "",
      floor_slug: "",
      building_name: "",
      building_slug: "",
      location_slug: "",
    });

    setTimeout(() => {
      zoneType = this.state.zone_type;
      if (zoneType === "1") {
        // Get Room List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=1");
      } else if (zoneType === "2") {
        // Get Commercial Area List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=2");
      } else if (zoneType === "3") {
        // Get Equipment Zone List
        this.getLocation("property_slug=" + propertySlug + "&zone_type=3");
      }
    }, 100);
  }
  async getMaxorder() {
    let sort_order = "";
    let res = await EquipmentService.getSortOrder(this.state.tableName);
    if (global.successStatus.includes(res.status)) {
      sort_order = res.data && res.data.max_order ? res.data.max_order : "";
    }
    this.setState({ sort_order: sort_order });
  }
  async getEquipmentData() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      var equipmentImage = [];
      let res = await EquipmentService.getEquipmentBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.equipment_name = resDatalist.equipment_name
          ? resDatalist.equipment_name
          : "";
        resData.zone_type = resDatalist.zone_type
          ? resDatalist.zone_type.zone_type_id
          : "";
        resData.status_id = resDatalist.status_id === 1 ? true : false;
        resData.serial_number =
          resDatalist.serial_number !== undefined
            ? resDatalist.serial_number
            : this.state.serial_number;
        resData.sort_order = resDatalist.sort_order
          ? resDatalist.sort_order
          : "";
        resData.floor_name =
          resDatalist.floor && resDatalist.floor.floor_name
            ? resDatalist.floor.floor_name
            : "";
        resData.floor_slug =
          resDatalist.floor && resDatalist.floor.slug
            ? resDatalist.floor.slug
            : "";
        resData.building_name =
          resDatalist.building && resDatalist.building.building_name
            ? resDatalist.building.building_name
            : "";
        resData.building_slug =
          resDatalist.building && resDatalist.building.slug
            ? resDatalist.building.slug
            : "";
        equipmentImage =
          res.data && res.data.equipment_image ? res.data.equipment_image : [];
      }
      this.setState({ mroImages: equipmentImage });
      this.setState(resData);
      this.defaultData = resData;
      var selectedZoneType = [];
      var selectedLocation = [];
      let zoneType = parseInt(res.data.data.zone_type);
      global.zoneTypeListproperty.forEach((item, i) => {
        if (parseInt(item.value) === zoneType) {
          selectedZoneType = [
            {
              value: item.value,
              label: item.label,
            },
          ];
          if (zoneType === 1) {
            this.getLocation(
              "property_slug=" + this.state.property_slug + "&zone_type=1"
            );
            selectedLocation = [
              {
                value:
                  res.data.data && res.data.data.room
                    ? res.data.data.room.slug
                    : "",
                label:
                  res.data.data && res.data.data.room
                    ? res.data.data.room.room_name
                    : "",
              },
            ];
            resData.location_slug =
              res.data.data && res.data.data.room
                ? res.data.data.room.slug
                : "";
          } else if (zoneType === 2) {
            this.getLocation(
              "property_slug=" + this.state.property_slug + "&zone_type=2"
            );
            selectedLocation = [
              {
                value: res.data.data.commercial.slug,
                label: res.data.data.commercial.commercials_zone_name,
              },
            ];
            resData.location_slug = res.data.data.commercial.slug;
          } else if (zoneType === 3) {
            this.getLocation(
              "property_slug=" + this.state.property_slug + "&zone_type=3"
            );
            selectedLocation = [
              {
                value: res.data.data.zone.slug,
                label: res.data.data.zone.equipment_zone_name,
              },
            ];
            resData.location_slug = res.data.data.zone.slug;
          }
          return false;
        }
      });
      if (zoneType == 1 || zoneType == 2 || zoneType == 3) {
        this.setState({ FloorHide: true });
      } else {
        this.setState({ FloorHide: false });
      }
      this.setState({
        location_slug: resData.location_slug,
        zone_type: zoneType,
        // mroImages :resData.equipment_image,
      });
      this.setState({ loading: false });
      this.setState({ selecteLocationList: selectedLocation });
      this.setState({ selectedZoneTypeList: selectedZoneType });
    }

    this.setState({ zoneTypeList: global.zoneTypeListproperty });
  }
  async deleteEquipmentImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await EquipmentService.deleteEquipmentImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getEquipmentData();
      // setTimeout(() => this.props.history.push("/equipment"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  //get room no. wise
  async getFloor(queryString) {
    this.setState({ loading: true });
    var floor_name = "";
    var floor_slug = "";
    var building_name = "";
    var building_slug = "";
    var floor = [];
    var building = [];
    let res = await MroService.getFloor(queryString);
    if (global.successStatus.includes(res.status)) {
      floor = res.data && res.data.floor ? res.data.floor : [];
      building = res.data && res.data.building ? res.data.building : [];
      floor_name = floor.floor_name ? floor.floor_name : "";
      floor_slug = floor.slug ? floor.slug : "";
      building_name = building.building_name ? building.building_name : "";
      building_slug = building.building_slug ? building.building_slug : "";
    }
    this.setState({
      floor_name: floor_name,
      floor_slug: floor_slug,
      building_name: building_name,
      building_slug: building_slug,
    });
    this.setState({ loading: false });
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    this.setState({ loading: true });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocations(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList = res.data ? res.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
      });
    });
    this.setState({ locationList: locationListData });
    this.setState({ loading: false });
  }

  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    var equipmentImage = [];
    var totalRecords = 0;
    let res = await EquipmentService.getEquipment(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = list.length;
      equipmentImage = res.data.data.equipment_image;
    }
    this.setState({
      loading: false,
      total_record: totalRecords,
      records: list,
      mroImages: equipmentImage,
    });
  }
  async deleteEquipment(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await EquipmentService.deleteEquipment(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            "/properties/view/" + this.state.property_slug + "/basic/equipment"
          ),
        global.redirect_time
      );
    } else {
      this.closedeleteModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  saveOrUpdateEquipment = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });
    //Image Section Start
    const FileWidgetObj = new FileWidget();
    this.setState({ loading: true, isSubmit: true });
    const output = await FileWidgetObj.fileUploadFun("equipment_form");
    let inputFilesData = [];
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          inputFilesData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };
        }
      }
      if (this.validator.allValid() && this.customValidate()) {
        this.setState({ loading: true, isSubmit: true });
        let inputData = {
          floor_slug: this.state.floor_slug ? this.state.floor_slug : "",
          sort_order: this.state.sort_order ? this.state.sort_order : "",
          equipment_name: this.state.equipment_name
            ? this.state.equipment_name
            : "",
          zone_type_id: this.state.zone_type ? this.state.zone_type : "",
          serial_number: this.state.serial_number
            ? this.state.serial_number
            : "",
          location_slug: this.state.location_slug,
          property_slug: this.state.property_slug
            ? this.state.property_slug
            : "",
          building_slug: this.state.building_slug
            ? this.state.building_slug
            : "",
          status_id: this.state.status_id === true ? 1 : 2,
          imageData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
        };

        if (this.state.slug !== undefined) {
          this.setState({ loading: true, isSubmit: true });
          let res = await EquipmentService.updateEquipment(
            this.state.slug,
            inputData
          );
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () =>
                this.props.history.push(
                  "/properties/view/" +
                    this.state.property_slug +
                    "/basic/equipment"
                ),
              global.redirect_time
            );
            this.getData();
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        } else {
          this.setState({ loading: true, isSubmit: true });
          let res = await EquipmentService.createEquipment(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () =>
                this.props.history.push(
                  "/properties/view/" +
                    this.state.property_slug +
                    "/basic/equipment"
                ),
              global.redirect_time
            );
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        }
        this.setState({ loading: false, isSubmit: false });
        setTimeout(
          () => this.setState({ showAlertModal: false }),
          global.alert_close_time
        );
      } else {
        this.setState({ loading: false, isSubmit: false });
        this.validator.showMessages();
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
        loading: false,
        isSubmit: false,
      });
      setTimeout(
        () =>
          this.setState({
            showAlertModal: false,
            loading: false,
            isSubmit: false,
          }),
        global.alert_close_time
      );
    }
    //Image Section End
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  handleCheckboxChange = (event) => {
    let newArray = [...this.state.slugList, event.target.id];
    if (this.state.slugList.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    this.setState({ slugList: newArray });
  };

  handleUnCheckboxChange = (event) => {
    let newArray = [...this.state.slugUncheckList, event.target.id];
    if (this.state.slugUncheckList.includes(event.target.id)) {
      newArray = newArray.filter((slug) => slug !== event.target.id);
    }
    this.setState({ slugUncheckList: newArray });
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("equipment_name");
    }
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update  Equipment";
    } else {
      return "Add  Equipment";
    }
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }
  closedeleteModal() {
    this.setState({ deleteModal: false });
  }
  opendeleteModal(slug) {
    this.setState({ deleteModal: true });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <main>
        <div className="container-fluid">
          <div className="container-fluid p-0">
            <div className="col-md-12 p-0">
              <div className="dashbaord-pageHeadertitle">
                <h3>
                  {this.state.slug !== undefined
                    ? this.state.customer_name
                      ? this.state.customer_name
                      : "Edit Equipment"
                    : "Add Equipment"}

                  <Link
                    to={
                      "/properties/view/" + this.state.property_slug + "/basic"
                    }
                  >
                    <img src={back} alt="" />
                    Back
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            <div className="col-md-12 mg-t-31 p-5 dashboardRightcard">
              <div className="tabNavigation">
                <form
                  id="equipment_form"
                  onSubmit={this.onSubmitFun}
                  method="post"
                  encType="multipart/form-data"
                >
                  <div className="mg-t-0 mg-b-0">
                    <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                      <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                        <label className="form-label text-left mg-b-5">
                          Equipment
                        </label>
                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          autoComplete="off"
                          name="equipment_name"
                          value={this.state.equipment_name}
                          maxLength="50"
                        />
                        {this.state.errors.equipment_name ? (
                          <div className="text-danger">
                            {this.state.errors.equipment_name}
                          </div>
                        ) : (
                          this.validator.message(
                            "equipment",
                            this.state.equipment_name,
                            "required|max:50",
                            { className: "text-danger" }
                          )
                        )}
                      </div>
                      {/* <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                      <label className="form-label text-left mg-b-5">Sort Order(Optional)</label>
                      <Form.Control
                      onChange={this.changeHandler}
                      name="sort_order"
                      value={this.state.sort_order}
                      className="form-control max_width_100 "
                      type="text"
                      maxLength="50"
                      />
                    </div> */}
                      <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                        <label className="form-label text-left mg-b-5">
                          Serial Number (Optional)
                        </label>
                        <Form.Control
                          type="text"
                          onChange={this.changeHandler}
                          autoComplete="off"
                          name="serial_number"
                          value={this.state.serial_number}
                          maxLength="50"
                        />
                      </div>
                      <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                        <label className="form-label text-left mg-b-5">
                          Zone
                        </label>
                        <Select
                          className="multiselect new_drop"
                          menuPlacement="auto"
                          value={this.state.selectedZoneTypeList}
                          options={this.state.zoneTypeList}
                          isSearchable={true}
                          onChange={(value) => this.handleChangeZoneType(value)}
                          defaultValue={this.state.selectedZoneTypeList}
                          autoComplete="off"
                          getOptionValue={(option) => `${option.label}`}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary75: "#808080",
                              primary50: "#808080",
                              primary25: "#808080",
                            },
                          })}
                        />
                        {this.state.errors.zone_type ? (
                          <div className="text-danger">
                            {this.state.errors.zone_type}
                          </div>
                        ) : (
                          this.validator.message(
                            "zone",
                            this.state.zone_type,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>

                      <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                        <label className="form-label text-left mg-b-5">
                          Location
                        </label>
                        <Select
                          className=""
                          menuPlacement="auto"
                          value={this.state.selecteLocationList}
                          options={this.state.locationList}
                          onChange={(value) => this.handleChangeLocation(value)}
                          defaultValue={this.state.selecteLocationList}
                          autoComplete="off"
                          getOptionValue={(option) => `${option.label}`}
                          name="Location"
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary75: "#808080",
                              primary50: "#808080",
                              primary25: "#808080",
                            },
                          })}
                        />
                        {this.state.errors.location_slug ? (
                          <div className="text-danger">
                            {this.state.errors.location_slug}
                          </div>
                        ) : (
                          this.validator.message(
                            "Location",
                            this.state.location_slug,
                            "required",
                            { className: "text-danger" }
                          )
                        )}
                      </div>

                      {this.state.FloorHide === true ? (
                        <>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Building
                            </label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              name="building_id"
                              value={this.state.building_name}
                              maxLength="50"
                              disabled
                            />
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Floor
                            </label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              name="floor_id"
                              value={this.state.floor_name}
                              maxLength="50"
                              disabled
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-md-12 col-lg-12 mg-t-15 mg-b-15 pd-b-1 pd-l-20 pd-r-70">
                        <div className="row m-0">
                          <FileWidget
                            fieldName="File"
                            fieldTitle=""
                            fieldLabel=""
                            checkValidation="true"
                            allowedType="jpeg,jpg,png"
                            maxFileSize={1000}
                            maxFileSizeType="mb"
                            maxFileAllowed={5}
                            uploadOn="s3"
                            refTableId="1"
                            displayFileName=""
                            documentTypeSlug=""
                            mediaType={1}
                            sortOrder=""
                            statusId=""
                            helpText="To download sample file."
                            sampleFileUrl="/sample.jpg"
                            drag="false"
                            dragText=""
                            inputClassName="form-control"
                            labelClassName="form-label"
                            formID="equipment_form"
                            uploadType="multiple"
                            resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:300,height:300,maintainAspectRatio:false,resizeDestinationFolder:thumb22"
                            destinationFolder="mros"
                            uploadedFiles={this.state.profile_image2}
                            deletedFiles={this.state.deletedFileList}
                            acceptOnly="image/*"
                            displayPreviewStyle="vertical"
                            newRelatedFilesDescChange={
                              this.newRelatedFilesDescChange
                            }
                            currentFile={this.state.mroImages.length}
                          />
                          {/* Image Preview */}

                          {this.state.mroImages
                            ? this.state.mroImages.map((record, i) => {
                                return (
                                  <div key={i}>
                                    <div className="img_close">
                                      <a
                                        href={
                                          record.uri && record.uri.uri
                                            ? record.uri.uri
                                            : ""
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        <img
                                          src={
                                            record.uri && record.uri.thumb_uri
                                              ? record.uri.thumb_uri
                                              : record.uri && record.uri.uri
                                              ? record.uri.uri
                                              : ""
                                          }
                                          className="brand_list_image"
                                          alt={record.slug}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              placeholderImage;
                                          }}
                                        />
                                      </a>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn_remove"
                                        onClick={() =>
                                          this.deleteEquipmentImage(record.slug)
                                        }
                                      >
                                        <span className="fa fa-times"></span>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                        {/* Image Preview end*/}
                      </div>
                      {global.userPermissions.checkPermission(
                        "property-update-assigned-property"
                      ) && (
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70 ">
                          <label className="form-label mg-b-0">
                            {this.state.status_id ? "Active" : "Inactive"}{" "}
                          </label>
                          <div
                            className={
                              this.state.status_id
                                ? "az-toggle on m-0"
                                : "az-toggle m-0"
                            }
                            onClick={() =>
                              this.setState({
                                status_id: !this.state.status_id,
                              })
                            }
                          >
                            <span></span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                      <div className="col-md-12 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                        {global.userPermissions.checkPermission(
                          "property-update-assigned-property"
                        ) && (
                          <div className="d-flex align-items-center">
                            <Link
                              to={
                                "/properties/view/" +
                                this.state.property_slug +
                                "/basic/equipment"
                              }
                            >
                              <button
                                className="gf-btn-lg-outline mg-r-7"
                                type="button"
                              >
                                Cancel
                              </button>
                            </Link>
                            <button
                              className="gf-btn-lg-stroke mg-l-2"
                              type="button"
                              onClick={this.saveOrUpdateEquipment}
                              disabled={this.state.isSubmit ? true : false}
                            >
                              {/* <img src={pluswIcon} /> */}
                              {this.state.isSubmit
                                ? global.loader
                                : this.state.slug !== undefined
                                ? "Update"
                                : "ADD"}
                            </button>
                          </div>
                        )}
                        &emsp;
                        {global.userPermissions.checkPermission(
                          "property-update-assigned-property"
                        ) &&
                          (this.state.slug !== undefined ? (
                            <Link
                              onClick={() => {
                                this.opendeleteModal(this.state.slug);
                              }}
                              className="detlet-text mg-r-20"
                            >
                              Delete Equipment
                            </Link>
                          ) : (
                            ""
                          ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="ht-50"></div>
        </div>

        <Modal show={this.state.deleteModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Equipment?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Are you sure you want to delete this equipment? This process
                cannot be reversed
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteEquipment(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default EquipmentForm;
